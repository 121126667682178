import React from 'react';
import ReviewEditVideo from '../../../Assets/images/Omni/ClinicalNote/review-edit.webm';
import ReviewEditVideoMp from '../../../Assets/images/Omni/ClinicalNote/review-edit.mp4';

const ReviewEdit = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>STEP 3</h6>
                        <h3 className='mb-3'>Review & Edit</h3>
                        <p className='mb-0'>Our hands-free navigation allows for corrections & enhancements to be made in any field, seamlessly. Inbuilt Clinical Documentation Integrity (CDI) and Automated ICD-10, SNOMED or SBS Coding prompt you to improve the quality, accuracy, and completeness of your medical note.</p>
                    </div>
                </div>
                <div className='offset-md-1 col-12 col-md-10 col-xl-4 col-lg-5'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoActivate' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                <source src={ReviewEditVideo} type="video/webm" />
                                <source src={ReviewEditVideoMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewEdit;
