import React from 'react';
import {ReactComponent as OfficeMumbai} from '../../../Assets/images/ContactUs/our-offices/mumbai.svg';

const Mumbai = () => {
    return (
        <div className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeMumbai/>
            </div>
            <h3 className='aug-our-offices__name'>Mumbai, India</h3>
            <p className='mb-3'>Meher House, 1st Floor, #15 Cawasji Patel Road, Fort, Mumbai, 400001.</p>
            <a href="tel:+918447745585">+91 8447-74-5585</a>
        </div>
    );
};

export default Mumbai;