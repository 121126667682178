import React from 'react';
import './EfficientDocumentation.scss';
import ActivateSpectraAr from './ActivateSpectraAr';
import BeginDictationAr from './BeginDictationAr';
import EditPersonalizeAr from './EditPersonalizeAr';

const EfficientDocumentationAr = () => {
    return (
        <section dir='rtl' className='aug-efficient-documentation pt-0'>
            <div className='container'>
                <h2 className='aug-efficient-documentation__title'>سير عمل مبسط؛ توثيق فعال</h2>
                <ActivateSpectraAr/>
                <BeginDictationAr/>
                <EditPersonalizeAr/>
            </div>
        </section>
    );
};

export default EfficientDocumentationAr;