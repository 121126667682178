import React from 'react';
import { ReactComponent as VoiceIcone } from '../../../Assets/images/icons/voice-check.svg';

const VoiceCommandsContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <VoiceIcone /> Intuitive Voice Commands
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">             
                Hands-free navigation with simple spoken instructions. Control your system and data effortlessly using natural language, allowing you to multitask and work more efficiently without touching a keyboard or mouse. 
            </div>
        </div>
    );
};

export default VoiceCommandsContent;