import React from 'react';
import OfficesImg from '../../../Assets/images/ContactUs/our-offices/our-offices.webp';
import './OurOffices.scss'
import MumbaiAr from './MumbaiAr';
import BengaluruAr from './BengaluruAr';
import LondonAr from './LondonAr';
import USAAr from './USAAr';
import DubaiAr from './DubaiAr';
import RiyadhAr from './RiyadhAr';

const OurOfficesAr = () => {
    return (
        <section dir='rtl' className='aug-our-offices'>
            <div className='container'>
                <h2 className='aug-our-offices__title'>مكاتبنا حول العالم</h2>
                <div className='row justify-content-center'>
                    <div className='col-lg-11 col-md-12'>
                        <img src={OfficesImg} alt="World map showing locations of Augnito offices around the globe" className='w-100 img-fluid' />
                        <div className="row gx-80">
                            <div className="col-12 col-md-6">
                                <MumbaiAr />
                            </div>
                            <div className="col-12 col-md-6">
                                <BengaluruAr />
                            </div>
                            <div className="col-12 col-md-6">
                                <LondonAr />
                            </div>
                            <div className="col-12 col-md-6">
                                <USAAr />
                            </div>
                            <div className="col-12 col-md-6">
                                <DubaiAr />
                            </div>
                            <div className="col-12 col-md-6">
                                <RiyadhAr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurOfficesAr;