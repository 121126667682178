import React from 'react';

const AugnitoPricingAr = () => {
    return (
        <div dir='rtl' className="accordion-item">
            <h3 className="accordion-header" id="AugnitoPricing">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAugnitoPricing" aria-expanded="false" aria-controls="collapseAugnitoPricing">
                كيف تساعد Augnito في نشر حلول الذكاء الاصطناعي الصوتي الطبي؟
                </button>
            </h3>
            <div id="collapseAugnitoPricing" className="accordion-collapse collapse" aria-labelledby="AugnitoPricing" data-bs-parent="#faq">
                <div className="accordion-body">
                يتخصص فريق الخدمات الاحترافية في Augnito في عمليات التنفيذ والتكامل والتأهيل ونجاح العملاء المستمر، مما يضمن الانتقال السلس والاستخدام الأمثل لحلول الذكاء الاصطناعي الصوتي الطبي داخل مؤسستك. يمكن الاستفادة من مجموعة خدماتنا بالكامل لتلبية احتياجاتك، إذا كنت بحاجة إلى المساعدة أثناء النشر. نحن نعمل بشكل وثيق مع فرق تكنولوجيا المعلومات والفرق السريرية لديك لتخصيص حلولنا ونشرها وفقًا لمتطلباتك المحددة.
                </div>
            </div>
        </div>
    );
};

export default AugnitoPricingAr;