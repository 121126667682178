import React from 'react';
import {ReactComponent as FlexibleTimeIcone} from '../../../Assets/images/icons/flexible-time.svg';

const FlexibleTimeAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <FlexibleTimeIcone/>
            </div>
            <h3 className='mb-2'>إجازات مرنة</h3>
            <p className='mb-0'>استمتع بإجازات مدفوعة الأجر غير محدودة، مما يسمح لك بأخذ الوقت الذي تحتاجه لإعادة شحن طاقتك دون قيود السياسات التقليدية.</p>
        </div>
    );
};

export default FlexibleTimeAr;