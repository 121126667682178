import React from 'react';
import queriesImg from '../../../Assets/images/ContactUs/HelpYou/genral-queries.webp';

const ContactSales = () => {

    return (
        <div className='aug-card'>
            <img src={queriesImg} alt="Hands typing on laptop with futuristic holographic interface displaying 'Contact us' and chatbot assistance options" className="w-100 img-fluid"/>
            <div className='aug-card-body'>
                <h3 className='mb-0 aug-card-header'>General Queries</h3>
                <p className='mb-0'>For anything else, contact us at </p>
                <a href="mailto:sales@augnito.ai">  sales@augnito.ai</a>
            </div>
        </div>
    );
};

export default ContactSales;