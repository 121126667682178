import React from 'react';
import Slider from "react-slick";

const OurPartnerLogo = () => {
    const settings = {
        dots: false,
        autoplay:true,
        autoplaySpeed: 0,
        variableWidth: true,
        centerMode: true,
        infinite: true,
        slidesToShow: 6,
        pauseOnHover: false,
        slidesToScroll: 1,
        speed: 4000,
        arrows:false,
        cssEase: 'linear',
        className:'aug-our-partner-slider',
        responsive: [
            {
              breakpoint: 991,
               settings: {
                 slidesToShow: 3
              }
            },
            {
               breakpoint: 575,
               settings: {
                slidesToShow: 2
              }
            }
        ]
    };

    return (
        <Slider {...settings}>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/max.webp')} alt="max logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/nhs.webp')} alt="nhs logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/fujifilm.webp')} alt="fujifilm logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/intelerad.webp')} alt="intelerad logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/kings.webp')} alt="kings logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/prime.webp')} alt="primehealth logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/physician-one.webp')} alt="physician logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/magentus.webp')} alt="magentus logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/apollo.webp')} alt="apollo logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ramsoft.webp')} alt="ramsoft logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/bahrain.webp')} alt="bahrain logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/rak.webp')} alt="rak logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/cloud-solutions.webp')} alt="cloudsolutions logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/chartnote.webp')} alt="chartnote logo" className="img-fluid"/>
            </div>
            
        </Slider>
    );
};

export default OurPartnerLogo;