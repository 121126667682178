import React from 'react';

const AugnitoSubscription = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="switchPlan">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSwitchPlan" aria-expanded="false" aria-controls="collapseSwitchPlan">
                How does Augnito help clinicians derive value for their personal practice?
                </button>
            </h3>
            <div id="collapseSwitchPlan" className="accordion-collapse collapse" aria-labelledby="switchPlan" data-bs-parent="#faq">
                <div className="accordion-body">
                Augnito's Medical Voice AI solutions enhance personal practice by streamlining documentation processes, reducing administrative burdens, and improving accuracy in medical records. Clinicians can focus more on patient care while ensuring that their documentation is precise and up to date. Our solutions are designed to integrate seamlessly into your workflow. For more information, please contact our team by filling out the form above or emailing the addresses listed on our Contact Us page.
                </div>
            </div>
        </div>
    );
};

export default AugnitoSubscription;