import React from 'react';
import './HelpYou.scss';
import BaseComponent from '../../../Common/BaseComponent';
import BookDemoAr from './BookDemoAr';
import ContactSalesAr from './ContactSalesAr';
import GeneralQueriesAr from './GeneralQueriesAr';

export default class HelpYouAr extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section dir='rtl' className='aug-help-you'>
                <div className='container'>
                    <h2 className='aug-help-you__title'>كيف يمكننا المساعدة؟</h2>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <BookDemoAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <ContactSalesAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'> 
                            <GeneralQueriesAr/>                                                       
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};