import React from 'react';
import {ReactComponent as RobustCompatibilityIcone} from '../../../Assets/images/icons/connect-collaborate.svg';

const RobustCompatibilityAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <RobustCompatibilityIcone/>
            </div>
            <h3 className='mb-2'>التوافق القوي</h3>
            <p className='mb-0'>يدعم Spectra الإملاء المباشر في أي نظام معلومات مستشفى أو سجلات طبية إلكترونية أو نظام معلومات مختبر أو نظام معلومات أشعة مثل Epic وCernerوالأنظمة المتكاملة، والحلول السحابية، والمزيد، دون الحاجة إلى الدمج الأصلي.</p>
        </div>
    );
};

export default RobustCompatibilityAr;