import React from 'react';
import customerSuccess from '../../../Assets/images/home/EmpoweringClinicians/customer-success.webp';

const CustomerSuccessTabAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={customerSuccess} alt="Smiling female doctor with stethoscope holding tablet, giving thumbs up, with colleagues in background" className="img-fluid" />
        </div>
    );
};

export default CustomerSuccessTabAr;