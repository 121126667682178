import React from 'react';
import './ThankYou.scss';
import CustomerSay from '../Home/CustomerSay/CustomerSay';
import CustomerSayME from '../Home/CustomerSay/CustomerSayME';
import CustomerSayIN from '../Home/CustomerSay/CustomerSayIN';
import BaseComponent from '../../Common/BaseComponent'
import ThankYouMeta from './Meta/ThankYouMeta';
import ExpressInterest from './ThankYouBanner/ExpressInterest';
import OurPartner from '../Home/OurPartner/OurPartner';

export default class ThankYou extends BaseComponent {
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ThankYouMeta />
                <ExpressInterest/>
                {
                    regionData === "ME" ? <CustomerSayME/> :
                    regionData === "IN" ? <CustomerSayIN/> :
                    <CustomerSay />
                }  
                <OurPartner userRegionData={regionData}/>
            </main>
        )
    }
}