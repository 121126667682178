import React from 'react';
import IntegrationsVideoAr from '../../../Assets/images/Spectra/ClinicianBuilt/easy-integrations.webm';
import IntegrationsVideoArMp from '../../../Assets/images/Spectra/ClinicianBuilt/easy-integrations.mp4';

const EasyIntegrationsTabAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <div className="">
                <video width="100%" id='videoIntegrations' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                    <source src={IntegrationsVideoAr} type="video/webm" />
                    <source src={IntegrationsVideoArMp} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default EasyIntegrationsTabAr;