import React from 'react';

const OurStoryAr = () => {
    return (
        <section dir='rtl' className='aug-our-story'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <h2 className='aug-our-story__title'>قصتنا</h2>
                    </div>
                    <div className='col-lg-7'>
                        <div className=''>
                            <p className='mb-4'>تم تأسيس شركة Augnito على يد رستم لوير وشيراز أوستن، وهي متجذرة في عدة سنوات من الخبرة العملية في سير العمل السريري وتكامل الرعاية الصحية المعقدة. على مدى 20 عامًا، جمع رواد الأعمال المتسلسلون رؤى فريدة حول تعقيدات عمليات الرعاية الصحية وإدارتها - مما أدى إلى تطوير فهم عميق وشامل لأنظمة الرعاية الصحية العالمية والحاجة إلى إعادة تصورها.</p>
                            <p className='mb-4'>عد Augnito أحد الحلول الرائدة في الذكاء الاصطناعي الصوتي للتوثيق السريري والذكاء السريري المحيطي، حيث تخدم أكثر من 500 مستشفى و15000 طبيب حول العالم. تساعد حلول البرمجيات كخدمة الخاصة بها على أتمتة سير العمل، وضمان الجودة والدقة عبر المهام الإدارية، وتزويد الأطباء بأحدث التوصيات والرؤى القائمة على الأدلة في الوقت الفعلي.</p>
                            <p className='mb-4'> إن منتجات الشركة سهلة الاستخدام للغاية هي نتيجة للتعاون المكثف مع العقول اللامعة من معهد ماساتشوستس للتكنولوجيا، ومعهد آي آي تي بومباي، وجامعة شيفيلد. تم تصميم حلول Augnito بشكل مشترك من قبل الأطباء والعلماء، وهي تمزج بعناية مبادئ التصميم التي تتمحور حول الإنسان مع التكنولوجيا المتطورة.</p>
                            <p className='mb-0'> من خلال إنشاء مجموعات معلوماتية للأطباء عبر سلسلة الرعاية والتأكد من أنها آمنة للغاية مع سهولة الوصول إليها، تسعى Augnito جاهدة إلى تعزيز اللمسة الإنسانية في الرعاية الصحية - مما يجعلها أكثر كفاءة ودقة وتركيزًا على المريض من أي وقت مضى.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurStoryAr;