import React from 'react';
import Pattrens from '../../../Assets/images/Pattrens/contact-sales.webp';
import './ContactSalesBanner.scss';

const ContactSalesBanner = () => {
    return (
        <section className='aug-contact-sales'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className='aug-contact-sales-contant'>
                            <h6 className='mb-2'>Contact sales</h6>
                            <h1 className="mb-3 aug-bookdemo-banner-title">Ready to discover Augnito first-hand?</h1>
                            <p className='mb-0'>Fill out the form to connect with our team for a personalized Augnito quote <span className='d-lg-block'>and learn about our customized pricing options.</span></p>
                        </div>
                    </div>
                    <div className="offset-lg-0 offset-md-8 col-md-4 col-lg-3">
                        <div className='aug-contact-sales__img'>
                            <img src={Pattrens} alt="pattrens" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSalesBanner;