import React from 'react';
import './LeadershipTeam.scss';
import { ReactComponent as Linkedin } from '../../../Assets/images/icons/linkedin.svg';

const LeadershipTeam = () => {
    return (
        <section className='aug-leadership-team'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-11 col-12'>
                        <h2 className="aug-leadership-team__title">Leadership team</h2>
                        <div className='row gx-80'>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/rustom.webp')} alt="Rustom Lawyer, CEO at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Rustom Lawyer</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Co-founder & CEO
                                    </div>
                                    <a href='https://www.linkedin.com/in/rustom-lawyer/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'> 
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/shiraz.webp')} alt="Shiraz Austin, Co-founder at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Shiraz Austin</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Co-founder
                                    </div>
                                    <a href='https://www.linkedin.com/in/shiraz-austin-89760511/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/piyush.webp')} alt="Piyush Arya, Chief Growth Officer at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Piyush Arya</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Chief Growth Officer 
                                    </div>
                                    <a href='https://www.linkedin.com/in/piyusharya/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/iyaz.webp')} alt="Mohamed Iyaz, Chief Operating Officer at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Mohamed Iyaz</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Chief Operating Officer
                                    </div>
                                    <a href='https://www.linkedin.com/in/iyaz1974/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/aravind.webp')} alt="Aravind Gaddala, Head of Product at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Aravind Gaddala</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Chief Product Officer
                                    </div>
                                    <a href='https://www.linkedin.com/in/agaddala/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/hammond.webp')} alt="Hammond Pereira, Head of Engineering at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Hammond Pereira</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Head of Engineering 
                                    </div>
                                    <a href='https://www.linkedin.com/in/hammond-pereira/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-md-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/verushka.webp')} alt="Verushka Patel, Director – Global Partnerships & Retail Sales at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Verushka Patel</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Director, Global Partnerships & Strategy
                                    </div>
                                    <a href='https://www.linkedin.com/in/verushka-patel-15352b98/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-md-0'>
                                    <div className='aug-leadership-team-img'> 
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/chethan.webp')} alt="Chethan Kumar, Director - Customer Success" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Chethan Kumar S</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Head of Customer Success
                                    </div>
                                    <a href='https://www.linkedin.com/in/chetan-k-cs/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/hamza.webp')} alt="Rustom Lawyer" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>Hamza Moftah</h3>
                                    <div className='aug-leadership-team-designation'>
                                        Hamza Moftah, AVP - Sales at Augnito AI
                                    </div>
                                    <a href='https://www.linkedin.com/in/hamzamoftah/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LeadershipTeam;