import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';

export default class SoftwareServiceUS extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>1.</span>Definitions </h3>
                    <p className='mb-4'>In these Terms, unless otherwise stated in the Agreement and/or the Order Form or unless the context otherwise requires, the words and expressions beginning with capital letters (other than clause headings) shall have the meaning set out below:</p>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.1. </strong>
                        <p className='mb-0'>“<strong>Affiliate</strong>”  means any entity that directly or indirectly Controls, is Controlled by, or is under common Control of the subject entity.  “<strong>Control</strong>” for purposes hereof, means direct or indirect ownership or control of more than 50% (Fifty Percent) of the voting interests of the subject entity.</p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.2. </strong>
                        <p className='mb-0'>“<strong>Aggregated Statistics</strong>” means data and information related to Customer’s use of Services, which is used by AIPL in an aggregate and anonymized manner, including complying statistical and performance information related to the provision and operation of the Services.</p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.3. </strong>
                        <p className='mb-0'>“<strong>Authorized Contractors</strong>”  means independent contractors, licensors or subcontractors.</p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.4. </strong>
                        <p className='mb-0'>“<strong>AIPL Technology</strong>”  means AIPL proprietary software, technology, frameworks, platforms, methodologies, facilitation guides, techniques, general purpose consulting and related know-how, logic, coherence and methods of operation of systems, user interfaces, screen designs, presentation materials, and best-practices documentation, including any enhancements, modifications or derivatives thereof, which are provided to Customer.</p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.5. </strong>
                        <p className='mb-0'>“<strong>Customer Data</strong>”  means all the data, records, files, images, graphics, audio, video, photographs, reports, forms and other content and material, in any format, that are submitted, stored, posted, displayed, transmitted or otherwise used with the AIPL Technology. For avoidance of doubt, Customer Data does not include Resultant Data or any other information reflecting the access or use of Services by or on behalf of Customer or any Authorized User.  </p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.6. </strong>
                        <p className='mb-0'>“<strong>Documentation</strong>”  means AIPL’s product guides and other end user documentation for the Services made available to the Customer, as may be updated by AIPL from time to time to reflect the then-current Services.  </p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.7. </strong>
                        <p className='mb-0'>“<strong>Order</strong>”  or “<strong>Order Form</strong>”  means an online order specifying the Services to be provided hereunder that is entered into between AIPL and Customer from time to time, including any addenda and supplements thereto. Affiliates of the Customer may purchase Services subject to the Agreement by executing Orders hereunder. </p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.8. </strong>
                        <p className='mb-0'>“<strong>Services</strong>”  shall mean the services being offered to the Customer on a subscription basis as more particularly specified in the Order Form. </p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.9. </strong>
                        <p className='mb-0'>“<strong>Resultant Data</strong>” means data and information related to Customer’s use of Services that is used by AIPL: (a) to train its proprietary AI technology to learn and produce sophisticated results, and (b) in an aggregate and anonymized manner, including to compile Aggregated Statistics.  </p>
                    </div>
                    <div className='d-flex mb-3'>
                        <strong className='me-2'>1.10. </strong>
                        <p className='mb-0'>“<strong>Subscription Term</strong>”  means the term of Services purchased by Customer which shall commence on the start date specified in the applicable Order and continue for the subscription term specified therein and any renewals thereto.</p>
                    </div>
                    <div className='d-flex'>
                        <strong className='me-2'>1.11. </strong>
                        <p className='mb-0'>“<strong>User</strong>”  means an individual authorized by Customer to use the Services including, but not limited to, employees, consultants, contractors and third parties whom the Customer has supplied with a specific user identification.</p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>2. </span> 	Services</h3>
                    <div className='mb-4'>
                        <div className='d-flex mb-3'>
                            <strong className='me-2'>2.1. </strong>
                            <strong>Provision of Services  </strong>
                        </div>
                        <ul className='mb-0 list-unstyled'>
                            <li>
                                <strong>2.1.1. </strong>
                                <p className='mb-0'>AIPL will make the Services available to Customer pursuant to the Agreement and the Order Form, and grants to the Customer a limited, non-exclusive, revocable, non-transferable license to use and access the Services and the Documentation during the Subscription Term, solely for Customer’s internal business purposes. Affiliates and Authorized Contractors of AIPL may perform certain aspects of the Services however, AIPL and shall be responsible for ensuring that all the obligations of AIPL are performed by its Affiliates and its Authorized Contractors per the terms specified in the Agreement.</p>
                            </li>
                            <li>
                                <strong>2.1.2. </strong>
                                <p className='mb-0'> Customer’s Affiliates and its Authorized Contractors may access certain aspects of the Services and Customer Data hosted or delivered through such Services, provided that Customer shall remain fully liable for the same and shall be responsible for ensuring that any of Customer’s obligations under the Agreement performed by its Affiliates and its Authorized Contractors are carried out in accordance with the Agreement. Customer’s use of the Services includes the right to access all functionality available in the Services during the Subscription Term. AIPL may modify the systems and environment used to provide the Services and update the Documentation, provided these changes do not materially degrade the functionality described in the Documentation. Updates, upgrades, and enhancements made generally available to all subscribing customers will be provided at no additional charge. New features, functionality, or enhancements may require additional fees, at AIPL's discretion. All updates and enhancements shall be subject to the terms of the Agreement. </p>
                            </li>
                        </ul>
                    </div>

                    <div className='mb-4'>
                        <div className='d-flex mb-3'>
                            <strong className='me-2'>2.2. </strong>
                            <strong>Usage Limit</strong>
                        </div>
                        <p className='mb-0'>Services are subject to usage limits specified in the Order(s) and/ or Documentation. Unless otherwise approved in writing by AIPL: (a) the Services may not be accessed by more than the number of Users specified in the Order(s); (b) a User’s password must not be shared with another individual; (c) except as set forth in an Order, a User’s identification may only be reassigned to a new individual replacing one that is no longer using the Service;(d) Customer shall not disassemble, decompile, reverse engineer, copy, distribute, modify or sell the AIPL Technology except as expressly and unambiguously permitted by the Agreement. AIPL shall have the right to immediately suspend Services if the Customer violates/breaches the provisions of this Clause.</p>
                    </div>

                    <div className='mb-4'>
                        <div className='d-flex mb-3'>
                            <strong className='me-2'>2.3. </strong>
                            <strong>Service Levels</strong>
                        </div>
                        <p className='mb-0'>Services shall be provided at such levels as specified in the Service Level Agreement.</p>
                    </div>

                    <div className='mb-4'>
                        <div className='d-flex mb-3'>
                            <strong className='me-2'>2.4. </strong>
                            <strong>Other Service Terms:</strong>
                        </div>
                        <div className='d-flex mb-3 ps-4 ms-2'>
                            <strong className='me-2'>2.4.1. </strong>
                            <strong>Use of Augnito Ambient:  </strong>
                        </div>
                        <p className='mb-3 ps-4 ms-2'>If you are a user of Augnito Ambient, the following terms and conditions shall apply in addition to the terms set forth herein:</p>
                        <ul className='mb-0 list-unstyled'>
                            <li>
                                <strong>(a)</strong>
                                <p className='mb-0'>The Customer acknowledge that Augnito Ambient records conversations between doctors and patients and agrees to comply with relevant data protection regulations. The Customer will inform patients about the recording, its purpose, and will obtain and accurately document the patient's consent for the purpose of compliance.  </p>
                            </li>
                            <li>
                                <strong>(b)</strong>
                                <p className='mb-0'>The Customer agrees to guarantee prompt communication to AIPL with respect to data deletion requests received to enable AIPL to uphold the rights of data owners in accordance with applicable data protection laws.</p>
                            </li>
                        </ul>
                    </div>

                    <div className='mb-4'>
                        <div className='d-flex mb-3'>
                            <strong className='me-2'>2.5. </strong>
                            <strong>Customer’s Obligations and Covenants:</strong>
                        </div>
                        <p className='mb-0'>Customer agrees and undertakes to: (a) access and use the Services in accordance with the Agreement, applicable laws and government regulations; (b) use commercially reasonable efforts to prevent unauthorized access to AIPL Technology or use of the Services and notify AIPL promptly of any such unauthorized access or use. The Customer shall not and shall ensure that the Services and AIPL Technology are not used for any acts that are illegal and/or unlawful and/or malicious and/or any other activities whatsoever that AIPL deems to be improper in its sole judgment, including but not limited to the following, and any defiance in compliance will entitle AIPL to take such technical and/or legal remedies as it may deem necessary or fit, including but not limited to termination of the Agreement and suspending usage of the Services: a. impersonating any person and/or entity or misrepresenting himself/herself/itself; b. providing any information that is inaccurate, misleading and/or fraudulent; c. preventing AIPL from authenticating the information provided by the User; d. access and/or use (and/or attempt to access or use) the account and/or login credentials of any other User; e. transmit any software and/or any other media that contain any viruses, worms, trojan horses, defects, or other such destructive / malicious software; f. infringe upon the intellectual property in any manner whatsoever, including but not limited to modifying and/or adapting and/or decompiling any portion of the AIPL Technology; g. act in a manner that is fraudulent and/or otherwise illegal; and/or h. facilitate and/or encourage any of the aforesaid acts.</p>
                    </div>

                    <div className='d-flex mb-4'>
                        <strong className='me-2'>2.6. </strong>
                        <p className='mb-0'><strong>Transmission of Customer Data :  </strong>Customer has and shall maintain all rights as are required to allow AIPL to provide the Services to Customer as set forth in the Agreement, including without limitation to send the Customer Data to AIPL pursuant to the Agreement and to allow AIPL to access, use, and store Customer Data to provide the Services to perform its intended function pursuant to the Agreement. Customer is responsible for its legal and regulatory compliance in its use of any Services and shall make AIPL aware of any Customer Data processed, stored or transmitted through the Services for which regulations other than those applicable in India may apply. If, in the course of providing Services, AIPL agrees in writing to process such Customer Data and Customer has subscribed to any applicable Services, AIPL shall process it only as permitted under the Agreement, its Privacy Policy [https://augnito.ai/legal/augnito-privacy-policy] and in compliance with data protection legislation to which AIPL is subject as a service provider.</p>
                    </div>

                    <div className='d-flex mb-0'>
                        <strong className='me-2'>2.7. </strong>
                        <p className='mb-0'><strong>Restrictions:  </strong> Customer shall not: (a) license, sublicense, sell, resell,  rent, lease, transfer, distribute or otherwise similarly exploit the Services and/ or AIPL Technology; (b) use or permit others to use any security testing tools in order to probe, scan or attempt to penetrate or ascertain the security of the AIPL Technology; (c) copy, create a derivative work of, reverse engineer, reverse assemble, disassemble, or decompile the AIPL Technology or any part thereof or otherwise attempt to discover any source code of the AIPL Technology or modify the Services; or (d) disclose any benchmark or performance tests of the AIPL Technology.</p>
                    </div>

                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>3.</span> Fees and Payment</h3>

                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>3.1. </strong>
                        <p className='mb-0'> <strong> Fees: </strong>Customer shall pay all fees specified in each Order and any applicable additional fees if Customer exceeds the allotted capacity or other applicable limits specified in the Order Form. Except as otherwise specified herein or in an Order: (a) fees are payable in United States Dollars; (b) fees are based on Services, regardless of usage; (c) payment obligations are non-cancelable and fees paid is non-refundable; (d) all Services shall be deemed accepted upon delivery; (e) Services purchased cannot be decreased during the relevant Subscription Term; and (f) subscription by Customer is not dependent on the delivery of any future functionality. Customer shall reimburse AIPL for out-of-pocket expenses incurred by AIPL in connection with the Services. All amounts payable under the Agreement will be made without setoff or counterclaim.</p>
                    </div>

                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>3.2. </strong>
                        <p className='mb-0'><strong> Invoicing and Payment: </strong> Unless otherwise specified in an Order, fees will be invoiced annually in advance. Any fees for overages will be calculated and invoiced monthly in arrears. Except as otherwise stated in the applicable Order, Customer shall pay all invoiced amounts within (7) seven days of invoice date. If Customer fails to pay any amounts due under the Agreement by the due date, in addition to any other rights or remedies it may have under the Agreement or by matter of law: (a) AIPL reserves the right to suspend the Services until such amounts are paid in full; and (b) AIPL will have the right to charge a late payment fee at a rate equal to the lesser of 1.5% (one point five percent) per month until Customer pays all amounts due.</p>
                    </div>

                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>3.3. </strong>
                        <p className='mb-0'><strong> Taxes: </strong> All fees for Services exclude applicable taxes, including sales tax, value-added tax, goods and services tax, and other indirect taxes or duties (collectively “Taxes”) related to the sale, delivery, or use of any product or Services. Unless Customer provides a valid, signed certificate or letter of exemption for each respective jurisdiction of its tax-exempt status, Customer is responsible for paying all applicable taxes related to AIPL's provision of the Services, except for taxes on AIPL’s net income. If AIPL pays or collects taxes on behalf of the Customer, the Customer shall reimburse AIPL promptly. If the Customer withholds taxes at source, it shall remit such taxes to the government as required and provide AIPL with the appropriate tax deduction certificate within the legally prescribed timelines.</p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>4.</span>	Proprietary Rights</h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>4.1. </strong>
                        <p className='mb-0'><strong> Ownership of Rights: </strong> Except for the rights expressly granted under the Agreement, AIPL and its licensors retain all right, title and interest in and to the Services, AIPL Technology and Documentation, including all related intellectual property rights therein. AIPL reserves all rights in and to the Services, AIPL Technology and Documentation not expressly granted to Customer under this Agreement. Customer acknowledges that, as between Customer and AIPL, AIPL owns all rights, title, and interest, including all intellectual property rights, in and to AIPL Technology.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>4.2. </strong>
                        <p className='mb-0'><strong> Ownership of Customer Data:  </strong> As between Customer and AIPL, Customer is and will remain the sole and exclusive owner of all rights, title and interest to all Customer Data, including any intellectual property rights therein. Customer grants AIPL and its Affiliates the rights to host, use, process, store, display, and transmit Customer Data as necessary to provide the Services, in accordance with the Agreement and AIPL’s Privacy Policy  [https://augnito.ai/legal/augnito-privacy-policy]. Customer warrants that it has all necessary rights to permit AIPL to compile, use, and transfer aggregated Customer Data for internal and marketing purposes, provided such use does not identify Customer or its customers.  If Customer, its employees, and/or any Authorized Users send or transmit any communications or material to AIPL by mail, email, telephone, or otherwise, suggesting or recommending changes to the AIPL Technology, including new features or functionality relating thereto, or any comments, questions, suggestions, or the like (“Feedback”), with prior approval, AIPL may use such Feedback.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>4.3. </strong>
                        <p className='mb-0'><strong> Publicity Rights: </strong> We may identify you as Augnito’s User in our promotional materials. We will promptly stop doing so upon your request sent to  <a rel="noreferrer" target="_blank" href="mailto: marketing@augnito.ai">marketing@augnito.ai</a></p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>5.</span> Confidentiality </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>5.1. </strong>
                        <p className='mb-0'><strong> Definition of Confidential Information: </strong> “Confidential Information” means all confidential or proprietary information of a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”), whether orally or in writing, that is designated as confidential or reasonably should be understood to be confidential given the nature of information and the circumstances of disclosure. Without limiting the coverage of these confidentiality obligations, the Parties acknowledge and agree that Confidential Information of each party shall include the terms and conditions of the Agreement (including pricing and other terms set forth in all Order Forms issued hereunder), related benchmark or similar test results, other technology and technical information, security information, security audit reports, and business and marketing plans, except that AIPL may reference and use Customer’s name, logos and the nature of the Services provided hereunder in AIPL’s business development and marketing efforts.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>5.2. </strong>
                        <p className='mb-0'><strong> Exceptions and Lawful Disclosure:    </strong> Confidential Information shall not include information that: (a) is or becomes publicly available without a breach of any obligation owed to the Disclosing Party, (b) is already known to the Receiving Party at the time of its disclosure by the Disclosing Party, without a breach of any obligation (c) following its disclosure to the Receiving Party, is received by the Receiving Party from a third party without breach of any obligation owed to Disclosing Party, or (d) is independently developed by Receiving Party without reference to or use of the Disclosing Party’s Confidential Information. The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent required by applicable law, regulation or legal process, provided that the Receiving Party: (a) provides prompt written notice to the extent legally permitted; (b) provides reasonable assistance, at Disclosing Party’s cost, in the event the Disclosing Party wishes to oppose the disclosure; & (c) limits disclosure to that required by the applicable laws or regulations.</p>
                    </div>
                    <div className='mb-0 d-flex'>
                        <strong className='me-2'>5.3. </strong>
                        <p className='mb-0'><strong> Protection of Confidential Information:   </strong> The Receiving Party shall use the same degree of care used to protect the confidentiality of its own Confidential Information of like kind (but in no event less than reasonable care), and, except with Disclosing Party’s written consent, shall: (a) not use any Confidential Information of Disclosing Party for any purpose outside the scope of the Agreement and (b) limit access to Confidential Information of Disclosing Party to those of its, its Authorized Contractors, and Affiliates’ employees, contractors and agents who need such access for purposes consistent with the Agreement and who have a duty or obligation of confidentiality no less stringent than those set forth herein.</p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>6.</span> Privacy and Information Security </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>6.1. </strong>
                        <p className='mb-0'><strong> Customer  Representations:    </strong> The Customer represents and warrants that: (a) it owns all Customer Data or has collected it in compliance with Laws, including obtaining necessary consents; and (b) it will comply with all applicable laws and regulations to protect Customer Data, including Personal Information of end users.   </p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>6.2. </strong>
                        <p className='mb-0'><strong>	Customer – Privacy and Security:    </strong> Customer shall comply with all applicable laws that apply to its use of Services (collectively, “Privacy Laws”). The Customer is responsible for the security of Customer Data provided to AIPL. Customer shall employ all physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all access credentials and protect against any unauthorized access to or use of Services; and (b) control the use of Customer Data.  </p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>6.3. </strong>
                        <p className='mb-0'><strong> AIPL – Privacy and Security:   </strong> AIPL shall maintain commercially reasonable administrative, physical, and technical safeguards to help protect the security, confidentiality, and integrity of Customer Data. AIPL has no responsibility or liability for the accuracy of Customer Data. AIPL further represents and warrants that AIPL shall not: (a) sell Personal Information, or (b) retain, use or disclose Personal Information except as necessary to fulfill this Agreement, within the direct business relationship with the Customer, or for AI training purposes subject to anonymization and applicable laws. AIPL will process data in accordance with applicable laws and shall not retain, store, or transfer any data beyond 90 days from receipt, except as required to provide services. AIPL will promptly delete or modify Customer Data upon request and take necessary measures to protect privacy when disclosing data, ensuring minimal disclosure of Personal Data. Both parties will notify the relevant authority of any data breaches, as required by applicable law.</p>
                    </div>
                    <div className='mb-0 d-flex'>
                        <strong className='me-2'>6.4. </strong>
                        <p className='mb-0'><strong> Security Program:   </strong> Notwithstanding the provisions in Sections 6.1 and 6.2, each Party shall maintain a security program in line with industry standards and applicable law to: (a) ensure the security and integrity of Customer Data; (b) protect against security threats; and (c) prevent unauthorized access. Each Party will regularly test its security systems, address vulnerabilities promptly, and notify the other Party of any unauthorized access to Customer Data. Both Parties will make reasonable efforts to remediate security vulnerabilities.  </p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>7. </span> Representations, Warranties and Disclaimers </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>7.1. </strong>
                        <p className='mb-0'><strong> AIPL Representations & Warranties:   </strong> AIPL represents and warrants that: (a) AIPL has the legal authority to enter into the Agreement; (b) the Services conform with the relevant Documentation in all material respects, and (c) the functionality and security of the Services will not be materially decreased during a Subscription Term.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>7.2. </strong>
                        <p className='mb-0'><strong>Remedies:    </strong> For any failure of Services, as applicable, to conform to their respective warranties, AIPL’s liability and Customer’s sole and exclusive remedy shall be to require AIPL to use commercially reasonable efforts to correct such failure. If the foregoing remedies are not commercially practicable, AIPL may, in its sole discretion, terminate the applicable Order upon providing Customer with written notice thereof, and, as Customer’s sole and exclusive remedy, will be to refund to Customer any Services fees paid by Customer with respect to the unexpired portion of the current Subscription Term for the non-conforming Services.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>7.3. </strong>
                        <p className='mb-0'><strong> Customer Representations & Warranties:   </strong> Customer represents and warrants that: (a) it has the legal authority and capacity to enter into the Agreement, and (b) it will use the Services and Software (if applicable) in accordance with the terms and conditions set forth in the Agreement and in compliance with all applicable laws, rules and regulations.</p>
                    </div>
                    <div className='mb-0 d-flex'>
                        <strong className='me-2'>7.4. </strong>
                        <p className='mb-0'><strong> Disclaimer:  </strong> EXCEPT AS EXPRESSLY PROVIDED HEREIN, AIPL MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, ORAL OR WRITTEN, STATUTORY OR OTHERWISE, AND AIPL HEREBY DISCLAIMS ALL IMPLIED WARRANTIES AND CONDITIONS, INCLUDING, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, OR ANY WARRANTY WITH RESPECT TO THE QUALITY, PERFORMANCE, ACCURACY OR FUNCTIONALITY OF THE SERVICES, OR THAT THE SAME ARE OR WILL BE ERROR FREE OR WILL ACCOMPLISH ANY PARTICULAR RESULT.</p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>8. </span> Indemnification </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>8.1. </strong>
                        <p className='mb-0'><strong> Indemnification by AIPL:    </strong> AIPL shall defend Customer against any claim, demand, suit, or proceeding made or brought against Customer by a third party alleging that the use of Services infringes or misappropriates the intellectual property rights of a third party (a “Claim Against Customer”); provided that Customer: (a) promptly gives AIPL written notice of the Claim Against Customer; (b) gives AIPL sole control of the defense and settlement of the Claim Against Customer (provided that AIPL may not settle any Claim Against Customer unless the settlement unconditionally releases Customer of all liability); and (c) provides to AIPL all reasonable assistance, at AIPL’s expense. In the event of a Claim Against Customer, or if AIPL reasonably believes the Services may infringe or misappropriate the intellectual property rights of a third party, AIPL may in AIPL’s sole discretion and at no cost to Customer: (a) modify the Services so that they no longer infringe or misappropriate, without breaching AIPL’s warranties hereunder, (b) obtain a license for Customer’s continued use of Services in accordance with the Agreement, or (c) terminate Customer’s subscriptions for such Services and refund to Customer any prepaid fees covering the remainder of the Subscription Term after the effective date of termination. Notwithstanding the foregoing, AIPL shall have no obligation to indemnify, defend, or hold Customer harmless from any Claim Against Customer to the extent it arises from: (a) Customer Data, (b) use by Customer after notice by AIPL to discontinue use of all or a portion of the Services, (c) use of Services by Customer in combination with equipment or software not supplied by AIPL where the Service itself would not be infringing, or (d) Customer’s breach of the Agreement.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>8.2. </strong>
                        <p className='mb-0'><strong>Indemnification by Customer:   </strong> Customer shall indemnify, defend and hold AIPL harmless from and against any judgments, settlements, costs and fees reasonably incurred (including reasonable attorney’s fees) resulting from any claim, demand, suit or proceeding made or brought against AIPL by a third party alleging that Customer Data or Customer’s use of the Services is in violation of the Agreement, infringes or misappropriates the intellectual property rights of a third party or violates applicable law.</p>
                    </div>
                    <div className='mb-0 d-flex'>
                        <strong className='me-2'>8.3. </strong>
                        <p className='mb-0'><strong> Exclusive Remedy:   </strong> This Clause 8 states the indemnifying party’s sole liability to, and the indemnified party’s exclusive remedy against, the other party for any type of claim described in this Clause. If Customer’s use of Services is, or in AIPL’s reasonable opinion is likely to become, enjoined or materially diminished as a result of a Claim Against Customer, then AIPL will, at its sole option, either: (a) procure the continuing right of Customer to use the Services; (b) replace or modify Services in a functionally equivalent manner so that it no longer infringes; or (c) terminate the Agreement and refund to Customer all unused subscription fees paid by Customer with respect to such Services. This Clause states AIPL’s sole and exclusive liability, and Customer’s sole and exclusive remedy, for the actual or alleged infringement or misappropriation of any third-party intellectual property right by any Services.</p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>9. </span>	Limitation of Liability </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>9.1. </strong>
                        <p className='mb-0'><strong> Limitation of Liability:  </strong> OTHER THAN THE OBLIGATIONS SET FORTH IN CLAUSE 10 (INDEMNIFICATION), NEITHER PARTY’S TOTAL AGGREGATE LIABILITY RELATING TO THE AGREEMENT (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY) SHALL EXCEED THE AMOUNT PAID OR PAYABLE BY CUSTOMER FOR THOSE SERVICES GIVING RISE TO SUCH CLAIM UNDER THE APPLICABLE ORDER FORM, ATTRIBUTABLE TO IMMEDIATELY (THREE) 3 PRECEDING MONTHS OF THE APPLICABLE INCIDENT.  </p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>9.2. </strong>
                        <p className='mb-0'><strong>Exclusion of Consequential & Related Damages:  </strong> NEITHER PARTY SHALL HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY LOST PROFITS OR REVENUES OR GOODWILL OR REPUTATION, OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>9.3. </strong>
                        <p className='mb-0'><strong> Other Limitations:  </strong> In no event shall AIPL be liable to the Customer to the extent that the alleged infringement of a third party intellectual property is based on or connected with: (a) a modification of the Services or Documentation by anyone other than AIPL; (b) the Customer’s use of the Services or Documentation in a manner contrary to the instructions given to the Customer by AIPL; or (c) the Customer’s use of the Services or Documentation after notice of the alleged or actual infringement from AIPL or any appropriate authority. Notwithstanding anything contained in the Agreement, Customer assumes sole responsibility for results obtained from the use of the Services and the Documentation by Customer, and for conclusions drawn from such use and AIPL shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts, Customer Data provided to AIPL by the Customer in connection with the utilization of the Services, or any actions taken by AIPL at the Customer’s direction.</p>
                    </div>
                    <ul className='mb-0 list-unstyled'>
                        <li>
                            <strong>9.3.1.</strong>
                            <p className='mb-0'>With respect to the above, AIPL (including its directors and/or employees and/or affiliates and/or agents and/or representatives and/or subcontractors) shall not be liable for any loss and/or liability, from the Agreement, delays or interruptions due to electronic or mechanical equipment failures and for reasons of force majeure, which are out of AIPL ‘s control, as may include but is not limited to health pandemics, weather conditions, earthquakes, floods, fire and other acts of God. AIPL shall have no responsibility to provide the Customer with access to the Services while such interruption is ongoing.</p>
                        </li>
                        <li>
                            <strong>9.3.2.</strong>
                            <p className='mb-0'>The Services may include external and/or third-party links and it is the responsibility of the Customer to acquaint himself with the privacy policies and terms and conditions of such third-party websites. The Customer acknowledges that AIPL shall not in any manner be held responsible for the information set out therein.</p>
                        </li>
                        <li>
                            <strong>9.3.3.</strong>
                            <p className='mb-0'>The Customer expressly agrees that AIPL is not a licensed health care provider and that the Services are not a substitute for independent medical decision making by a qualified health care practitioner. The Customer is solely responsible for its own conduct with respect to patient care and any reliance upon the Service shall not diminish User’s responsibility for patient care.</p>
                        </li>
                    </ul>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>10. </span>	Term and Termination </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>10.1. </strong>
                        <p className='mb-0'> This Agreement shall be effective for the term specified in the Order Form ("Term") unless terminated earlier as per this Agreement. The Customer shall not terminate the Services during the Lock-In Period specified in the Order Form. If the Customer terminates the Services during the Lock-In Period, they shall pay the Service Fee for the remaining Lock-In Period. Unless specified otherwise in the Order Form, the Services will automatically renew for successive one-year terms or as otherwise agreed by the parties, unless either party provides written notice of non-renewal at least sixty (60) days before the end of the current term. AIPL shall be entitled to increase the pricing applicable to the renewal of any then-current Subscription Term by providing Customer with a written notice thereof days prior to the end of such Subscription Term.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>10.2. </strong>
                        <p className='mb-0'>Without prejudice to other terms and conditions hereof, a party may terminate the Agreement for cause if: (a) the other party does not cure its material breach within 30 (thirty) days of receiving written notice from the non-breaching party; or (b) the other party becomes the subject of an insolvency petition which has been admitted. AIPL may terminate the Agreement for cause: (a) within 10 (ten) days written notice of Customer’s failure to timely pay undisputed amounts due under the Agreement; or (b) immediately upon Customer’s breach of Clause 3. If the Agreement is terminated by AIPL for cause, Customer will pay any unpaid fees covering the remainder of all Subscription Terms. Upon termination of an Order for a cause by Customer and upon Customer’s written request, AIPL shall refund on a pro rata basis, any fees paid thereunder that cover the remainder of the applicable Term after the effective date of termination. Upon termination of an Order for cause by AIPL, all amounts owed by Customer thereunder shall become due and payable. In no event shall any termination relieve Customer of the obligation to pay all fees payable to AIPL for the period prior to the effective date of termination. Immediately on the termination or expiration of the Agreement, the Customer shall and shall ensure that the Users export any data they may need, cease using the Software and uninstall all instance of the same from its systems.</p>
                    </div>
                    <div className='mb-0 d-flex'>
                        <strong className='me-2'>10.3. </strong>
                        <p className='mb-0'> This Clause, Clauses 5 (<i>Proprietary Rights</i>), 6 (<i>Confidentiality</i>), 8.4 (<i>Disclaimer</i>), 9 (<i>Indemnification</i>), 10 (<i>Limitation of Liability </i>), 12 (<i>Notices, Dispute Resolution, Governing Law and Jurisdiction </i>) and 13 (<i>General Provisions </i>) and any other rights and obligations of the parties hereunder that by their nature are reasonably intended to survive termination or expiration, shall survive any termination or expiration of the Agreement.</p>
                    </div>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>11. </span>	NOTICES, DISPUTE RESOLUTION, GOVERNING LAW AND JURISDICTION </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>11.1. </strong>
                        <p className='mb-0'> <strong>Notices:  </strong> Except as otherwise provided herein, all notices to the parties shall be sent to the addresses listed on the Order Form. All notices must be made either via email, conventional mail, or overnight courier. Notice sent via conventional mail, using registered mail, is deemed received four business days after mailing. Notice sent via email or overnight courier is deemed received the second day after having been sent. AIPL may broadcast notices or messages through the Services or by posting notices or messages on AIPL’s web site to inform Customer of changes to the Services, or other matters of importance. Billing-related notices to Customer will be addressed to the relevant billing contact designated by Customer on the applicable Order.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>11.2. </strong>
                        <strong>Dispute Resolution, Governing Law and Jurisdiction </strong>
                    </div>
                    <ul className='mb-0 list-unstyled'>
                        <li>
                            <strong className='me-2'>11.2.1. </strong>
                            <p className='mb-0'> If you are a customer based in, or operating out of the United States of America, the following clause shall apply: The Agreement and any dispute arising out of or relating to the Agreement, including its interpretation, performance, or breach, shall be governed by and construed in accordance with the laws of the State of California, the United States of America, without regard to its conflict of laws principles. In the event of a dispute, Parties undertake to hold such consultation and negotiations in good faith and shall secure that the same is attended by persons with decision making authority regarding the dispute. If no settlement can be reached through friendly consultation and negotiation within 30 (thirty) days of one Party delivering a notice of the dispute to the other Party, then such dispute will be finally settled by arbitration in accordance with the provision of this Clause. Any dispute, controversy, or claim arising out of or in connection with the Agreement, including its validity, interpretation, enforceability, or termination, shall be settled by arbitration in accordance with the rules of American Arbitration Association in force at the time of the dispute. The arbitration shall be conducted in California and shall be conducted in the English language. The decision of the arbitral tribunal shall be final and binding upon the Parties.  </p>
                        </li>
                    </ul>
                </div>

                <div className='mb-5'>
                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>12. </span>	General Provisions </h3>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.1. </strong>
                        <p className='mb-0'> <strong>Service Analyses: </strong> AIPL may: (a) compile statistical and other information related to the performance, operation and use of the Services, and (b) use, and share data from the Services environment in aggregated form for security and operations management, to create statistical analyses, and for research and development purposes (Sub-clauses (a) and (b) above are collectively referred to as “Service Analyses”). Service Analyses will not incorporate any information, including Customer Data, in a form that could serve to identify Customer or an individual. AIPL retains all intellectual property rights in Service Analyses.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.2. </strong>
                        <p className='mb-0'><strong>Relationship of the Parties:  </strong> The parties are independent contractors. The Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.3. </strong>
                        <p className='mb-0'><strong>Non-Solicitation:   </strong> Customer agrees that during the term of the Agreement, and for 12 (twelve) months thereafter, it will not recruit or otherwise solicit for employment any person employed by AIPL. Nothing in this clause shall be construed to prohibit individual AIPL employees from responding to public employment advertisements, postings or job fairs of Customer, provided such response is not prompted by Customer intentionally circumventing the restrictions of this Clause.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.4. </strong>
                        <p className='mb-0'><strong>Public Relations:    </strong> Customer agrees that AIPL may identify Customer as a AIPL customer in advertising, media relations, trade shows, the website, and other similar promotional activities, using Customer’s name and trademarks in accordance with Customer’s trademark guidelines. Customer shall also assist AIPL in preparing a press release announcing Customer as a new AIPL customer, with the view to publishing and in preparing a case study for external use that details Customer’s use of the Services. </p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.5. </strong>
                        <p className='mb-0'><strong>Waiver:  </strong> No failure or delay by either party in exercising any right under the Agreement shall constitute a waiver of that right. </p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.6. </strong>
                        <p className='mb-0'><strong>Severability:  </strong> If any provision of the Agreement is held by a court of competent jurisdiction to be contrary to law, the provision shall be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Agreement shall remain in effect.</p>
                    </div>
                    <div className='mb-3 d-flex'>
                        <strong className='me-2'>12.7. </strong>
                        <p className='mb-0'><strong>Assignment:  </strong> Neither party may assign its rights and obligations hereunder, either in whole or in part, whether by operation of law or otherwise, without the prior written consent of the other party. Notwithstanding the foregoing, either party may assign the Agreement in its entirety (including all Order Forms), without consent of the other party, to its Affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other party.  </p>
                    </div>
                    <div className='mb-0 d-flex'>
                        <strong className='me-2'>12.8. </strong>
                        <p className='mb-0'><strong>Entire Agreement:  </strong> The Agreement, along with the Order Form and these Terms constitutes the entire agreement between the parties as it relates to the subject matter and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning or relating to the same. To the extent of any conflict or inconsistency between the provisions of this Agreement, the Documentation, any Order Form, the terms of such Order Form shall prevail.  </p>
                    </div>
                </div>
            </>
        );
    }
}