import React from 'react';

const AugnitoPricing = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="AugnitoPricing">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAugnitoPricing" aria-expanded="false" aria-controls="collapseAugnitoPricing">
                How does Augnito assist in the deployment of its Medical Voice AI solutions?
                </button>
            </h3>
            <div id="collapseAugnitoPricing" className="accordion-collapse collapse" aria-labelledby="AugnitoPricing" data-bs-parent="#faq">
                <div className="accordion-body">
                Augnito's professional services team specializes in implementations, integrations, onboarding and ongoing customer success, ensuring a smooth transition and optimal use of our Medical Voice AI solutions within your organization. The entire suite of our services can be unlocked for your needs, if you require assistance during deployment. We work closely with your IT and clinical teams to customize and deploy our solutions according to your specific requirements. 
                </div>
            </div>
        </div>
    );
};

export default AugnitoPricing;