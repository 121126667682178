import React from 'react';
import './EmpoweringClinicians.scss';
import BaseComponent from '../../../Common/BaseComponent';
import { ReactComponent as BurnoutIcone } from '../../../Assets/images/icons/physician-burnout.svg';
import physicianBurnout from '../../../Assets/images/home/EmpoweringClinicians/physician-burnout.webp';
import { ReactComponent as PatientIcone } from '../../../Assets/images/icons/patient-engagement.svg';
import { ReactComponent as ClaimIcone } from '../../../Assets/images/icons/claim-denials.svg';
import { ReactComponent as CustomerIcone } from '../../../Assets/images/icons/customer-success.svg';
import { ReactComponent as DeploymentIcone } from '../../../Assets/images/icons/simplified-deployment.svg';
import simplifiedDeployment from '../../../Assets/images/home/EmpoweringClinicians/simplified-deployment.webp';
import patientEngagement from '../../../Assets/images/home/EmpoweringClinicians/patient-engagement.webp';
import customerSuccess from '../../../Assets/images/home/EmpoweringClinicians/customer-success.webp';
import claimDenial from '../../../Assets/images/home/EmpoweringClinicians/reduced-claim.webp';


export default class EmpoweringCliniciansMobileAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <div dir='rtl' className="accordion" id="accordionEmpowering">
                <div className="accordion-item">
                    <div className='accordion-item-content' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <BurnoutIcone /> تقليل إرهاق الأطباء
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            تبسيط سير العمل السريري وإنشاء وثائق منظمة بسلاسة من خلال واجهة صوتية سهلة الاستخدام.

                            . باستخدام الذكاء الاصطناعي الصوتي الطبي، يصبح المستخدمون أكثر إنتاجية، ويشعرون بإرهاق أقل، ويقضون وقتًا أطول مع المرضى.
                        </div>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={physicianBurnout} alt="Physician using Augnito medical speech recognition software on desktop" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <PatientIcone /> زيادة تفاعل المرضى
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            تجربة موعد أكثر فائدةً بفضل الأطباء المجهزين بالذكاء الاصطناعي المتقدم للتدوين والتفريغ الطبيين، مما يؤدي إلى تحسين الرعاية والعلاج بشكل عام.
                        </div>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={patientEngagement} alt="Smiling African American patient with short hair in gray sweater, receiving primary care from a healthcare professional" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <ClaimIcone /> تقليل رفض المطالبات
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            زيادة الأرباح من خلال تقديم ملاحظات سريرية أكثر شمولًا لمعالجة المطالبات.تحسين جودة التوثيق السريري في المصدر باستخدام CAPD وقدرات الترميز السريري الآلي (SNOMED، ICD-10، CPT، SBS).
                        </div>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={claimDenial} alt="Healthcare professionals reviewing medical charts and claim documents with pens, collaborating on patient data management" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <DeploymentIcone /> نشر مبسط
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            حلول آمنة وبسيطة وجاهزة للاستخدام مباشرة، وسهلة النشر والإدارة على أي نطاق. عدم وجود تكاليف بنية تحتية أو أجهزة. واجهات برمجة تطبيقات ومجموعات تطوير برامج جاهزة للتشغيل الفوري لتسهيل عمليات التكامل.
                        </div>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={simplifiedDeployment} alt="Hands typing on laptop with futuristic holographic interface displaying data analytics and cloud storage icons" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item border-0">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                            <CustomerIcone /> تعزيز نجاح العملاء
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            المدفوع بالتحليلات العميقة والنتائج المُقاسة، لضمان فوائد ملموسة للعملاء على مستوى المؤسسات. تيسير التكيف والانتقال والتدريب والرعاية دون عناء - لتحويل الأطباء إلى أكبر داعمينا.
                        </div>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={customerSuccess} alt="Smiling female doctor with stethoscope holding tablet, giving thumbs up, with colleagues in background" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};