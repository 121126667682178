import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import RefundPolicyMeta from './RefundPolicyMeta';

export default class RefundPolicy extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <RefundPolicyMeta />
                <section className='aug-legal-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 offset-lg-1 col-lg-10'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Refund and Cancellation Policy</h1>
                                    <p className='mb-0'>Effective as of 29th June 2023. These Terms replace and supersede all prior versions</p>
                                </div>
                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Cancellation Policy</h3>
                                    <p className='mb-0'>A User may cancel subscription at any time through the Product. A cancellation means that no future payments are charged to the User’s account.</p>
                                </div>
                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Cancellation of</h3>
                                    <ul className='mb-0 list-unstyled list-style-disc'>
                                        <li>Augnito Spectra (Professional Plan)</li>
                                        <li>Augnito Spectra (Only App)</li>
                                    </ul>
                                </div>
                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Augnito Spectra (Professional Plan)</h3>
                                    <p className='mb-3'><strong>When can you cancel:</strong></p>
                                    <ul className='mb-4 list-unstyled list-style-disc'>
                                        <li>You can cancel the subscription anytime through the product.</li>
                                        <li>Cancellation is recommended at the end of billing cycle to avoid further renewal.</li>
                                    </ul>
                                    <p className='mb-3'><strong>When the subscription is cancelled:</strong></p>
                                    <ul className='mb-0 list-unstyled list-style-disc'>
                                        <li>The balance Subscription fee on raising cancellation request is non-refundable.</li>
                                        <li>The services will continue until the end of the billing period.</li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Augnito Spectra (Only app)</h3>
                                    <p className='mb-3'><strong>When can you cancel:</strong></p>
                                    <ul className='mb-4 list-unstyled list-style-disc'>
                                        <li>The subscription can be cancelled anytime.</li>
                                        <li>Is subject to auto-renewal, unless cancelled</li>
                                        <li>During renewal, an option to cancel the subscription will pop up (only when there is change in any subscription rates).</li>
                                    </ul>
                                    <p className='mb-3'><strong>When the subscription is cancelled:</strong></p>
                                    <ul className='mb-3 list-unstyled list-style-disc'>
                                        <li>The balance Subscription fee on raising cancellation request is non-refundable.</li>
                                        <li>The services will continue until the end of the billing period.</li>
                                    </ul>
                                    <div className='d-flex'>
                                        <strong className='me-2'>Note: </strong>
                                        <p className='mb-0'> The subscription is auto renewed only for mobile app as they are subject to in-app purchase terms and conditions of Google PlayStore or iOS Appstore. The cancellation will not lead to refund of subscription fee for the remaining billing period– it will only stop any future payments.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Refund Policy</h3>
                                    <div className='mb-3'>
                                        <p className='mb-3'>A refund on subscription fee can be handed back to the USER if the Company is notified within a reasonable period (subject to the company’s diligence). Moreover, the company looks forward to promote customers satisfaction and provide quality service allows refunds including but not limited to the following:</p>
                                        <ul className='mb-0 list-unstyled list-style-disc'>
                                            <li><p className='mb-0'>If the customer provides a valid reason for requesting a refund in accordance to this document;</p></li>
                                            <li><p className='mb-0'>If the customer provides a valid report that the charge was fraudulent or unauthorized;</p></li>
                                            <li><p className='mb-0'>In order to comply applicable law and regulations, and requests from verified judiciary or law enforcement agents; or</p></li>
                                            <li><p className='mb-0'>For any other reason Augnito deems appropriate</p></li>
                                        </ul>
                                    </div>
                                    <div className='d-flex'>
                                        <strong className='me-2'>Note: </strong>
                                        <p className='mb-0'> In case of Cancellation for Augnito Spectra (App or Professional Plan), Subscription fee paid for the remaining billing cycle is not subject to refund.</p>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='aug-legal-page-subtitle'>Abuse of the Refund Policy</h3>
                                    <p className='mb-0'>Customers requesting serial or repeated refunds may be blocked from accessing further services. Customers that violate Augnito’s Terms of Service may have their right of refund revoked.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}