import React from 'react';
import './OurSolution.scss';
import {ReactComponent as Star} from '../../../Assets/images/icons/star-single.svg';
import {ReactComponent as ProductIcone} from '../../../Assets/images/icons/product-icon.svg';
import { NavLink } from 'react-router-dom';

const OurSolution = () => {

    return (
        <section className='aug-ou-solution'>
            <div className='container'>
                <h3 className='aug-ou-solution__title'>Our Solutions</h3>
                <div className='row gx-80'>
                    <div className='col-lg-6 col-12'>
                       <div className='aug-ou-solution__item'>
                            <div className='d-flex align-items-center mb-4'>
                                <ProductIcone/>
                                <h3 className='aug-ou-solution__item-title mb-0'>Omni</h3>
                            </div>
                            <p className='mb-md-5 mb-4 pb-2 pb-md-0'>Ambient Clinical Intelligence with multi-lingual conversation capture & data structuring for seamless integration into EMRs. Engage with patients efficiently and create medical documents from natural dialogue.</p>
                            <h4 className='aug-ou-solution__item-list-title'>Standout features:</h4>
                            <ul className='list-unstyled aug-ou-solution__item-list'>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' /> 
                                    Real-time clinical conversation capture
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Easy data transfer to EMR systems
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Customizable documentation templates
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Global language & accent support
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Easy integrations via APIs & SDKs
                                </li>
                                <li className='d-flex align-items-center'>
                                    <Star height="16" width="16" className='me-2' />
                                    Enterprise-grade security & compliance
                                </li>
                            </ul>
                            <NavLink to="/omni" className="btn btn-primary w-100">Learn more</NavLink>
                       </div> 
                    </div>
                    <div className='col-lg-6 col-12'>
                        <div className='aug-ou-solution__item mb-0'>
                            <div className='d-flex align-items-center mb-4'>
                                <ProductIcone/>
                                <h3 className='aug-ou-solution__item-title mb-0'>Spectra</h3>
                            </div>
                            <p className='mb-5'>Industry-leading clinical dictation solution. Leveraging ASR & NLP to streamline clinical workflows, our intuitive tool captures patient notes in seconds, increases productivity, and improves patient care.  </p>
                            <h4 className='aug-ou-solution__item-list-title'>Standout features:</h4>
                            <ul className='list-unstyled aug-ou-solution__item-list'>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' /> 
                                    99% out-of-box accuracy
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Optimized for global accents
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Increased productivity with Macros
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Personalized reporting with Custom Vocabulary
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='me-2' />
                                    Easy integrations via APIs & SDKs
                                </li>
                                <li className='d-flex align-items-center'>
                                    <Star height="16" width="16" className='me-2' />
                                    Enterprise-grade security & compliance
                                </li>
                            </ul>
                            <NavLink to="/spectra" className="btn btn-primary w-100">Learn more</NavLink>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    );
};


export default OurSolution;