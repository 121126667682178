import React from 'react';
import { ReactComponent as ClaimIcone } from '../../../Assets/images/icons/claim-denials.svg';

const ClaimDenialContentAr = () => {

    return (

        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <ClaimIcone /> تقليل رفض المطالبات
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            زيادة الأرباح من خلال تقديم ملاحظات سريرية أكثر شمولًا لمعالجة المطالبات.تحسين جودة التوثيق السريري في المصدر باستخدام CAPD وقدرات الترميز السريري الآلي (SNOMED، ICD-10، CPT، SBS).
            </div>
        </div>

    );
};

export default ClaimDenialContentAr;