import React from 'react';
import ActivateVideo from '../../../Assets/images/Omni/ClinicalNote/activate-omni.webm';
import ActivateVideoMp from '../../../Assets/images/Omni/ClinicalNote/activate-omni.mp4';

const ActivateOmni = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>STEP 1</h6>
                        <h3 className='mb-3'>Activate Omni</h3>
                        <p className='mb-0'>Interact with patients naturally, while our ambient clinical intelligence software records in real time, with enterprise-grade accuracy and support for over 37 languages.</p>
                    </div>
                </div>
                <div className='offset-md-1 col-12 col-md-10 col-lg-5 col-xl-4'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoActivate' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                                <source src={ActivateVideo} type="video/webm" />
                                <source src={ActivateVideoMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivateOmni;