import React from 'react';
import {ReactComponent as IntegrityIcone} from '../../../Assets/images/icons/integrity.svg';

const Integrity = () => {
    return (
        <div className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <IntegrityIcone/>
            </div>
            <h3 className='mb-0'>Integrity</h3>
            <p className='mb-0'>We prioritize honesty, transparency, and accountability, building trust and fostering an environment where everyone thrives.</p>
        </div>
    );
};

export default Integrity;