import React from 'react';
import {ReactComponent as IntuitivenessIcone} from '../../../Assets/images/icons/intuitiveness.svg';

const Intuitiveness = () => {
    return (
        <div className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <IntuitivenessIcone/>
            </div>
            <h3 className='mb-0'>Intuitiveness</h3>
            <p className='mb-0'>We strive for simplicity and ease of use in all aspects of the business. Our solutions are designed to be naturally understood and effortlessly integrated.</p>
        </div>
    );
};

export default Intuitiveness;