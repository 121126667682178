import React from 'react';
import PersonalizeVideoAr from '../../../Assets/images/Spectra/EfficientDocumentation/edit-personalize.webm';
import PersonalizeVideoArMp from '../../../Assets/images/Spectra/EfficientDocumentation/edit-personalize.mp4';

const EditPersonalizeAr = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='offset-md-1 col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 3</h6>
                        <h3 className='mb-3'>حرّر وخصص</h3>
                        <p className='mb-0'>استخدم الأوامر الصوتية للتنقل وإجراء التصحيحات أو التحسينات على مستندك السريري.استخدم أيضًا الميزات المضمنة مثل وحدات الماكرو والقوالب والمفردات المخصصة،وتفضيلات التنسيق، والمزيد، لإنشاء سير عمل مميز.</p>
                    </div>
                </div>
                <div className='col-12 col-md-10 col-lg-5'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="">
                            <video width="100%" id='videoPersonalize' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                                <source src={PersonalizeVideoAr} type="video/webm" />
                                <source src={PersonalizeVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPersonalizeAr;