import React from 'react';
import HeroLeft from '../../../Assets/images/Pattrens/hero-left.webp';
import HeroRight from '../../../Assets/images/Pattrens/hero-right.webp';
import './Banner.scss';
import { ReactComponent as Star } from '../../../Assets/images/icons/stars.svg';
import { NavLink } from 'react-router-dom';

const BannerAr = () => {

    return (

        <section dir='rtl' className='aug-banner'>
            <div className='aug-banner-img'>
                <img src={HeroRight} alt="banner pattrens" className='img-fluid' />
            </div>
            
            <div className="aug-banner-content text-center">
                <h1 className='mb-3'>
                    مساعد الذكاء الاصطناعي الأمثل لمجال الرعاية الصحية
                    <Star className='me-2' />
                </h1>
                <p>حلول توثيق المعلومات السريرية على مستوى المؤسسات، التي يثق بها الأطباء في جميع التخصصات. </p>
                <NavLink to="/ar/contact-sales" className='btn btn-primary'>اطلب عرضًا توضيحيًا</NavLink>
            </div>
            
            <div className='aug-banner-img'>
                <img src={HeroLeft} alt="banner pattrens" className='img-fluid' />
            </div>
        </section>

    );
};


export default BannerAr;