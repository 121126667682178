import React from 'react';
import {ReactComponent as ConnectCollaborateIcone} from '../../../Assets/images/icons/connect-collaborate.svg';

const ConnectCollaborateAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ConnectCollaborateIcone/>
            </div>
            <h3 className='mb-2'>التواصل والتعاون</h3>
            <p className='mb-0'>تواصل مع الزملاء في اللقاءات والفعاليات ربع السنوية في Augnito، ما يعمل على خلق روح قوية من الترابط والحفاظ عليها.</p>
        </div>
    );
};

export default ConnectCollaborateAr;