import React from 'react';
import { ReactComponent as DashboardIcone } from '../../../Assets/images/icons/dashboard-setting.svg';

const EasyIntegrationsContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                <DashboardIcone /> Easy Integrations via APIs & SDKs
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">  
                Seamlessly incorporate medical speech recognition into your existing clinical applications and workflows. Our robust APIs and SDKs enable developers to easily add voice AI capabilities to any platform or software, enhancing user experience and functionality. 
            </div>
        </div>
    );
};

export default EasyIntegrationsContent;