import React from 'react';
import { ReactComponent as IntegrationIcone } from '../../../Assets/images/icons/dashboard-setting.svg';

const EasyIntegrationsContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                <IntegrationIcone /> Easy Integrations via APIs & SDKs
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Omni empowers developers to enhance their existing clinical software applications via low-code and no-code deployment options.
            </div>
        </div>
    );
};

export default EasyIntegrationsContent;