import React from 'react';
import {ReactComponent as OfficeLondon} from '../../../Assets/images/ContactUs/our-offices/london.svg';

const LondonAr = () => {
    return (
        <div dir='rtl' className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeLondon/>
            </div>
            <h3 className='aug-our-offices__name'>لندن، المملكة المتحدة</h3>
            <p className='mb-3'>Penhurst House, 352-356 Battersea Park Road, London, SW11, 3BY, United Kingdom.</p>
            <a dir='ltr' className='text-end' href="tel:+4402086590800">+44 020-865-90800</a>
        </div>
    );
};

export default LondonAr;