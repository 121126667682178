import React from 'react';
import { ReactComponent as CodeIcone } from '../../../Assets/images/icons/connect-collaborate.svg';

const CodeRetrievalContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <CodeIcone /> Automatic Code Retrieval
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Omni streamlines clinical coding processes via intelligent analysis of documentation and automatic assignment of relevant ICD 10, SNOMED, or SBS codes – minimizing risk of error and ensuring regulatory compliance.
            </div>
        </div>
    );
};

export default CodeRetrievalContent;