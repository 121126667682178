import React from 'react';
import {ReactComponent as DocumentationIcone} from '../../../Assets/images/icons/voice-check.svg';


const EMRDocumentationAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <DocumentationIcone/>
            </div>
            <h3 className='mb-2'>توثيق السجلات الطبية الإلكترونية بسهولة</h3>
            <p className='mb-0'>تلتقط تقنية الذكاء الاصطناعي المحيطي للرعاية الصحية من Omni محادثات المريض والطبيب بسلاسة بشكل فوري، مع تمكين التوليد التلقائي للملاحظات السريرية الشاملة مباشرة داخل واجهة السجلات الطبية الإلكترونية.</p>
        </div>
    );
};

export default EMRDocumentationAr;