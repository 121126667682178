import React from 'react';
import './WhySpectra.scss';
import EnhancedDictationAr from './EnhancedDictationAr';
import RobustCompatibilityAr from './RobustCompatibilityAr';
import ClinicalSpecialtiesAr from './ClinicalSpecialtiesAr';
import ZeroVoiceAr from './ZeroVoiceAr';
import ProvenROIAr from './ProvenROIAr';
import FlexibleDeploymentsAr from './FlexibleDeploymentsAr';


const WhySpectraAr = () => {
    return (
        <section dir='rtl' className='aug-why-spectra aug-augnito-difference'>
            <div className='container'>
                <h2 className='aug-career__title'>لماذا Spectra؟</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <EnhancedDictationAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <RobustCompatibilityAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ClinicalSpecialtiesAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ZeroVoiceAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ProvenROIAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <FlexibleDeploymentsAr/>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default WhySpectraAr;
