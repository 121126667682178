import React from 'react';

const ContactBannerAr = () => {
    return (
        <section dir='rtl' className='aug-legal-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h6 className="mb-2">اتصل بنا</h6>
                        <h1 className='mb-3'>تعرف على المزيد حول حلول Augnito</h1>
                        <p className='mb-0'>سيكون فريقنا سعيدًا بمساعدتك.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactBannerAr;