import React from 'react';
import BookDemo from './BookDemo';
import ContactSales from './ContactSales';
import GeneralQueries from './GeneralQueries';
import './HelpYou.scss';
import BaseComponent from '../../../Common/BaseComponent';

export default class HelpYou extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-help-you'>
                <div className='container'>
                    <h2 className='aug-help-you__title'>How can we help you?</h2>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <BookDemo/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <ContactSales/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <GeneralQueries/>                                                         
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};