import React from 'react';
import { ReactComponent as PatientIcone } from '../../../Assets/images/icons/patient-engagement.svg';

const PatientEngagementContentAr = () => {

    return (

        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <PatientIcone /> زيادة تفاعل المرضى
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            تجربة موعد أكثر فائدةً بفضل الأطباء المجهزين بالذكاء الاصطناعي المتقدم للتدوين والتفريغ الطبيين، مما يؤدي إلى تحسين الرعاية والعلاج بشكل عام.
            </div>
        </div>

    );
};

export default PatientEngagementContentAr;