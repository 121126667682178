import React from 'react';
import {ReactComponent as Facebook} from '../../../Assets/images/icons/facebook.svg';
import {ReactComponent as Twitter} from '../../../Assets/images/icons/twitter.svg';
import {ReactComponent as Youtube} from '../../../Assets/images/icons/youtube.svg';
import {ReactComponent as Linkedin} from '../../../Assets/images/icons/linkedin.svg';
import {ReactComponent as Instagram} from '../../../Assets/images/icons/instagram.svg';
import {ReactComponent as Globe} from '../../../Assets/images/icons/globe.svg';
import { GetCountryName } from '../../../Common/Utils';
import * as Constants from '../../../Common/Constants';

const FooterSPR = () => {
    var countryName = GetCountryName();
    return (
        <div className='aug-social-copyright-region'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12'>
                    <div className='aug-copyright'>
                        <span>© {new Date().getFullYear()} Augnito India Pvt. Ltd. The Augnito name and logo are trademarks of Augnito India Pvt. Ltd.</span>
                    </div>
                </div>
                <div className='col-lg-2 col-md-4 col-5'>
                    <div className='d-flex'>
                        <div className='aug-region d-flex align-items-center'>
                            <Globe/> 
                            <span className='ms-1'>{countryName}</span>
                        </div>
                        <div className='aug-legel ms-4 ms-sm-5'>
                            <a href={Constants.SITE_URL + "legal"} target="_blank" rel="noreferrer" className="nav-link">Legal</a> 
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-8 col-7'>
                    <div className='aug-social'>
                        <ul className='list-unstyled mb-0 d-flex justify-content-end  align-items-center'>
                            <li>
                                <a href='https://www.facebook.com/augnito' rel="noreferrer" target="_blank" className='aug-social-badge d-flex align-items-center justify-content-center'>
                                    <Facebook/>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/augnito?lang=en" rel="noreferrer" target="_blank" className='aug-social-badge d-flex align-items-center justify-content-center'>
                                    <Twitter/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.youtube.com/channel/UCC_EF4bjgaFQTgdETYXUD9w' target="_blank" rel="noreferrer" className='aug-social-badge d-flex align-items-center justify-content-center'>
                                    <Youtube/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.linkedin.com/company/augnito' target="_blank" rel="noreferrer" className='aug-social-badge d-flex align-items-center justify-content-center'>
                                    <Linkedin/>
                                </a>
                            </li>
                            <li>
                                <a href='https://www.instagram.com/augnito/' target="_blank" rel="noreferrer" className='aug-social-badge d-flex align-items-center justify-content-center'>
                                    <Instagram/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default FooterSPR;