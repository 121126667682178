import React from 'react';
import './OurEthos.scss';

const OurEthos = () => {
    return (
        <section className='aug-our-ethos aug-leadership-team'>
            <div className='container'>
                <h2 className="aug-leadership-team__title">Our Ethos</h2>
                <div className='row gx-0'>
                    <div className='col-md-12 col-lg-4'>
                        <div className='aug-our-ethos-centricity'>
                            Human-Centricity
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-2'>
                        <div className='aug-our-ethos-line'>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>Experienced & Accessible</h3>
                            <p className='mb-0'>Our goal is to transform over two decades of medical expertise into accessible AI products through deep research and advanced technology development. Our multidisciplinary team ensures solutions are optimized for clinicians of every kind. </p>
                        </div>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>Creative & Intuitive </h3>
                            <p className='mb-0'>Our products are engineered to keep user preference and experience at the forefront, at every stage. We encourage real-time feedback from clinicians to address pain points effectively and evaluate new concepts by experimenting early and often. </p>
                        </div>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>Attentive & Responsive </h3>
                            <p className='mb-0'>Our Customer Success professionals are available to lend the human touch at any stage, helping you make the most of our technologies. We understand the significance of building strong associations—and provide analytics, testing and reporting on request.</p>
                        </div>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>Conscientious & Precise</h3>
                            <p className='mb-0'>Our working principles are diligence, alacrity, purpose and power. Revolutionizing healthcare systems is an ambitious undertaking that we strive to carry out with thoroughness, accuracy and sharpness – born from experience and ability.</p>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    );
};

export default OurEthos;