import React from 'react';
import {ReactComponent as OfficeBengaluru} from '../../../Assets/images/ContactUs/our-offices/bengaluru.svg';

const Bengaluru = () => {
    return (
        <div className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeBengaluru/>
            </div>
            <h3 className='aug-our-offices__name'>Bengaluru, India</h3>
            <p className='mb-3'>
Honeykomb By Bhive,
25, Primrose Rd, Craig Park
Layout, Sivanchetti Gardens,
Bengaluru, Karnataka 560025</p>
            <a href="tel:+918447745585">+ 91 8447-74-5585</a>
        </div>
    );
};

export default Bengaluru;



