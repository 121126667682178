import React from 'react';
import {ReactComponent as ConnectCollaborateIcone} from '../../../Assets/images/icons/connect-collaborate.svg';

const ConnectCollaborate = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ConnectCollaborateIcone/>
            </div>
            <h3 className='mb-2'>Connect & Collaborate</h3>
            <p className='mb-0'>Engage with colleagues at Augnito’s quarterly meetups and events, creating and maintaining a strong spirit of togetherness.</p>
        </div>
    );
};

export default ConnectCollaborate;