import React from 'react';
import {ReactComponent as ZeroVoiceIcone} from '../../../Assets/images/icons/voice-check.svg';

const ZeroVoiceAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ZeroVoiceIcone/>
            </div>
            <h3 className='mb-2'>تدريب ملف تعريف الصوت الصفري</h3>
            <p className='mb-0'>Spectra هو حل سريع وسهل لتحويل الكلام إلى نص يفهم ويأخذملاحظات سريرية دقيقة عبر جميع اللهجات.إنه دقيق بنسبة 99% وجاهز للاستخدام، لذا يمكنك بدء استخدامه بمجرد اشتراكك.</p>
        </div>
    );
};

export default ZeroVoiceAr;