import React from 'react';
import BaseComponent from '../../Common/BaseComponent';
import OmniMeta from './Meta/OmniMeta';
import OmniBanner from './OmniBanner/OmniBanner';
import DiscoverOmni from './DiscoverOmni/DiscoverOmni';
// import CustomerSay from '../Home/CustomerSay/CustomerSay';
// import CustomerSayIN from '../Home/CustomerSay/CustomerSayIN';
// import CustomerSayME from '../Home/CustomerSay/CustomerSayME';
import WhyOmni from './WhyOmni/WhyOmni';
import ClinicalNote from './ClinicalNote/ClinicalNote';
// import TrustedBy from '../Spectra/TrustedBy/TrustedBy';
// import ClinicalPlatforms from '../Spectra/ClinicalPlatforms/ClinicalPlatforms';
import ClinicianDesigned from './ClinicianDesigned/ClinicianDesigned';

export default class Omni extends BaseComponent {  
        
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
               <OmniMeta/>
               <OmniBanner/>
               {/* <TrustedBy userRegionData={regionData} />  */}
               <ClinicalNote/>
               {/* <ClinicalPlatforms userRegionData={regionData}/> */}
               <ClinicianDesigned/>
               <WhyOmni/>
                {/* {
                    regionData === "IN" ? <CustomerSayIN/> :
                    regionData === "ME" ? <CustomerSayME/> :
                    <CustomerSay/>
                } */}
               <DiscoverOmni/>
            </main>
        );
    }
}