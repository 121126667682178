import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const CookiePolicyMeta = () => {

    return (
        <Helmet>
            <title> Cookie Policy | Augnito Medical Voice AI Platform </title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Understand how Augnito uses cookies to enhance your experience with our medical speech recognition technology. Learn about your options and preferences."/>
            <link rel="canonical" href={Constants.SITE_URL + "/legal/augnito-cookie-policy"} />
            <meta property="og:url" content={Constants.SITE_URL + "/legal/augnito-cookie-policy"} />
            <meta property="og:title" content="Cookie Policy | Augnito Medical Voice AI Platform" />
            <meta property="og:description" content="Understand how Augnito uses cookies to enhance your experience with our medical speech recognition technology. Learn about your options and preferences." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/legal/augnito-cookie-policy"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Cookie Policy | Augnito Medical Voice AI Platform" />
            <meta name="twitter:description" content="Understand how Augnito uses cookies to enhance your experience with our medical speech recognition technology. Learn about your options and preferences." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default CookiePolicyMeta;