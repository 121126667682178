import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const HomeMetaAr = () => {

    return (
        <Helmet>
            <title>Augnito | تحويل الكلام الطبي إلى نص باستخدام الذكاء الاصطناعي للأطباء العرب</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="قم بإملاء الملاحظات بسهولة مباشرة في نظام السجلات الطبية الإلكترونية الخاص بك. يتكامل الذكاء الاصطناعي الصوتي من Augnito بسلاسة مع منصات السجلات الطبية الإلكترونية الخليجية، ويدعم أكثر من 50 تخصصًا بما في ذلك المجالات الطبية الخاصة بالمنطقة، ويتكيف مع سير عملك الفريد. وبذلك تتمكن من إعطاء الأولوية لرعاية المرضى في بيئات الرعاية الصحية العربية." />
            <meta charset="UTF-8" />
            <meta name="language" content="ar"/>
            <link rel="canonical" href={Constants.SITE_URL + "/ar/"} />
            <meta property="og:url" content={Constants.SITE_URL + "/ar/"} />
            <meta property="og:title" content="Augnito | تحويل الكلام الطبي إلى نص باستخدام الذكاء الاصطناعي للأطباء العرب" />
            <meta property="og:description" content="قم بإملاء الملاحظات بسهولة مباشرة في نظام السجلات الطبية الإلكترونية الخاص بك. يتكامل الذكاء الاصطناعي الصوتي من Augnito بسلاسة مع منصات السجلات الطبية الإلكترونية الخليجية، ويدعم أكثر من 50 تخصصًا بما في ذلك المجالات الطبية الخاصة بالمنطقة، ويتكيف مع سير عملك الفريد. وبذلك تتمكن من إعطاء الأولوية لرعاية المرضى في بيئات الرعاية الصحية العربية." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/ar/"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Augnito | تحويل الكلام الطبي إلى نص باستخدام الذكاء الاصطناعي للأطباء العرب" />
            <meta name="twitter:description" content="قم بإملاء الملاحظات بسهولة مباشرة في نظام السجلات الطبية الإلكترونية الخاص بك. يتكامل الذكاء الاصطناعي الصوتي من Augnito بسلاسة مع منصات السجلات الطبية الإلكترونية الخليجية، ويدعم أكثر من 50 تخصصًا بما في ذلك المجالات الطبية الخاصة بالمنطقة، ويتكيف مع سير عملك الفريد. وبذلك تتمكن من إعطاء الأولوية لرعاية المرضى في بيئات الرعاية الصحية العربية." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <link rel="alternate" hreflang="ar" href={Constants.SITE_URL + "/ar/"} />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default HomeMetaAr;