import React from 'react';
import './OurPartner.scss';
import BaseComponent from '../../../Common/BaseComponent';
import OurPartnerLogoMEAr from './OurPartnerLogoMEAr';

export default class OurPartnerAr extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section dir="rtl" className='container aug-our-partner'>
                <h2>موثوق به من أكثر من <br/><span>500+ مستشفى</span> في أكثر من <br/> <span>25 دولة </span></h2>
                <OurPartnerLogoMEAr/>
            </section>
        );
    };
};
