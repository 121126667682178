import React from 'react';
import CommandsVideoAr from '../../../Assets/images/Spectra/ClinicianBuilt/voice-commands.webm';
import CommandsVideoArMp from '../../../Assets/images/Spectra/ClinicianBuilt/voice-commands.mp4';

const VoiceCommandsTabAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <div className="">
                <video width="100%" id='videoCommands' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                    <source src={CommandsVideoAr} type="video/webm" />
                    <source src={CommandsVideoArMp} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default VoiceCommandsTabAr;