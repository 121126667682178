import React from 'react';
import {ReactComponent as IntuitivenessIcone} from '../../../Assets/images/icons/intuitiveness.svg';

const IntuitivenessAr = () => {
    return (
        <div dir='rtl' className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <IntuitivenessIcone/>
            </div>
            <h3 className='mb-0'>سهولة الاستخدام</h3>
            <p className='mb-0'>نسعى جاهدين لتحقيق البساطة وسهولة الاستخدام في جميع جوانب العمل.تم تصميم حلولنا بحيث يمكن فهمها بشكل طبيعي ودمجها دون عناء.</p>
        </div>
    );
};

export default IntuitivenessAr;