import React from 'react';
import HeroLeft from '../../../Assets/images/Pattrens/hero-left.webp';
import './AboutBanner.scss';

const AboutBannerAr = () => {
    return (
        <section dir='rtl' className='aug-about-banner'>
            <div className='container'>
                <div className='aug-about-banner-name'>نبذة عنا</div>
                <div className='row gx-40'>
                    <div className='col-md-12 col-lg-7'>
                        <h1 className='aug-about-banner-title'>الذكاء الاصطناعي الأمثل للأطباء</h1>
                        <p className='mb-0 aug-about-banner-text'>تؤمن Augnito بتعزيز وتمكين المتخصصين في الرعاية الصحية لتمكينهم من تقديم إخلاص وإنسانية لا مثيل لهما في رعاية المرضى.</p>
                        <div className='d-none d-lg-block aug-about-banner-img'>
                            <img src={HeroLeft} alt="Banner pattren" className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-5'>
                        <ul className='aug-about-banner-list pe-0 list-unstyled mb-0'>
                            <li>
                                <h4 className='mb-0'>2004</h4>
                                <p className='mb-0'>إصدار أول خدمة نسخ طبي خارجية للأعمال التجارية في المملكة المتحدة</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2007</h4>
                                <p className='mb-0'>تم تصنيفها في المرتبة الأولى من قبل هيئة الخدمات الصحية الوطنية للعقود الإطارية الوطنية</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2010</h4>
                                <p className='mb-0'>إصدار منتج لسير عمل الإملاء الرقمي</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2012</h4>
                                <p className='mb-0'>العمل مع أساتذة من معهد ماساتشوستس للتكنولوجيا لتطوير تقنية التعرف على الكلام السريري وتحسين الخدمة</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2015</h4>
                                <p className='mb-0'>البحث والتطوير مع أساتذة من جامعة شيفيلد ومعهد بومباي للتكنولوجيا لتطوير التكنولوجيا والأتمتة</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2016</h4>
                                <p className='mb-0'>إصدار خدمات إدارة دورة الإيرادات في دولة الإمارات العربية المتحدة كخط خدمة جديد</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2020</h4>
                                <p className='mb-0'>التحول من النسخ إلى شركة ناشئة في مجال الذكاء الاصطناعي الصوتي، Augnito</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2024</h4>
                                <p className='mb-0'>أصبحت شركة رائدة في مجال الذكاء الاصطناعي الصوتي والذكاء السريري المحيطي عبر سلسلة الرعاية المستمرة، مع أكثر من 500 مستشفى و15000 طبيب على المنصة</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutBannerAr;