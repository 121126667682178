import React from 'react';
import './EfficientDocumentation.scss';
import ActivateSpectra from './ActivateSpectra';
import BeginDictation from './BeginDictation';
import EditPersonalize from './EditPersonalize';

const EfficientDocumentation = () => {
    return (
        <section className='aug-efficient-documentation pt-0'>
            <div className='container'>
                <h2 className='aug-efficient-documentation__title'>Streamlined Workflows; Efficient Documentation</h2>
                <ActivateSpectra/>
                <BeginDictation/>
                <EditPersonalize/>
            </div>
        </section>
    );
};

export default EfficientDocumentation;