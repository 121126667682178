import React from 'react';
import './SecureDesign.scss';

const SecureDesign = () => {

    return (
       <section className='aug-secure-design'>
            <div className='container'>
                <div className='aug-secure-design-wrap border-top'>
                    <div className='row gx-40'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-secure-design-box'>
                                <h2 className='mb-3'>Secure, By Design</h2>
                                <p className='mb-0'>At Augnito, data security is embedded in our product development process. Our governance structures are built on two decades of healthcare experience. We are committed to safeguarding information through industry-standard encryption technologies, as well as stringent audits of security features within our systems and policies.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-secure-design__img'>
                                <ul className='list-unstyled mb-0'>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/iso-27001.webp')} className="img-fluid" height={80} width={80} alt="iso27001 logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/aicpa.webp')} className="img-fluid" height={80} width={80} alt="aicpa soc2 logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/gdpr.webp')} className="img-fluid" height={80} width={80} alt="gdpr logo"/>
                                    </li>
                                    <li>
                                        <img src={require('../../../Assets/images/Compliance/hipaa.webp')} className="img-fluid" height={80} width={132} alt="hipaa logo"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default SecureDesign;