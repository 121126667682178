import React from 'react';
import HeroLeft from '../../../Assets/images/Pattrens/hero-left.webp';
import './AboutBanner.scss';

const AboutBanner = () => {
    return (
        <section className='aug-about-banner'>
            <div className='container'>
                <div className='aug-about-banner-name'>About us</div>
                <div className='row gx-40'>
                    <div className='col-md-12 col-lg-7'>
                        <h1 className='aug-about-banner-title'>The Optimal AI for Clinicians</h1>
                        <p className='mb-0 aug-about-banner-text'>Augnito believes in augmenting & empowering healthcare professionals to enable unparalleled fidelity & humanity in patient care. </p>
                        <div className='d-none d-lg-block aug-about-banner-img'>
                            <img src={HeroLeft} alt="Banner pattren" className='img-fluid'/>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-5'>
                        <ul className='aug-about-banner-list list-unstyled mb-0'>
                            <li>
                                <h4 className='mb-0'>2004</h4>
                                <p className='mb-0'>Launched the UK’s first Medical Transcription BPO</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2007</h4>
                                <p className='mb-0'>Ranked No.1 by the NHS for National Framework Contracts</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2010</h4>
                                <p className='mb-0'>Launched a product for Digital Dictation Workflow </p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2012</h4>
                                <p className='mb-0'>Worked with professors from MIT to develop Clinical Speech Recognition technology and optimize the service </p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2015</h4>
                                <p className='mb-0'>R&D  with professors from Sheffield University & IIT Bombay to develop technology and automate </p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2016</h4>
                                <p className='mb-0'>Launched Revenue Cycle Management services in the UAE as a new service line</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2020</h4>
                                <p className='mb-0'>Pivoted from transcription into a Voice AI startup, Augnito</p>
                            </li>
                            <li>
                                <h4 className='mb-0'>2024</h4>
                                <p className='mb-0'>Leader in the field of Voice AI & Ambient Clinical Intelligence across the continuum of care, with over 500 hospitals and 15,000 clinicians on the platform </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutBanner;