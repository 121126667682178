import React from 'react';
import Slider from "react-slick";
import './CollageGallery.scss'

const CollageGallery = () => {

    const settings = {
        dots: false,
        autoplay:true,
        infinite: true,
        slidesToShow: 2,
        pauseOnHover: false,
        slidesToScroll: 1,
        arrows:false,
        className:'aug-collage-gallery-slider',
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              }
            }
        ]
    };

    return (
       <section className='aug-collage-gallery'>
            <Slider {...settings}>
            <div className='aug-collage-gallery__items'>                    
                <img src={require('../../../Assets/images/Careers/Collage/1.webp')} alt="Team meeting in modern office, professionals discussing strategy on whiteboard with diagrams and flowcharts" className="img-fluid"/>
            </div>
            <div className='aug-collage-gallery__items'>                    
                <img src={require('../../../Assets/images/Careers/Collage/2.webp')} alt="Team Augnito posing in front of historic building with ornate architecture and gardens; employees attending company presentation in Augnito office, attentively listening to speaker" className="img-fluid"/>
            </div>
            <div className='aug-collage-gallery__items'>                    
                <img src={require('../../../Assets/images/Careers/Collage/3.webp')} alt="Three team members in camouflage gear for paintball, one raising hand victoriously, showcasing team-building activities" className="img-fluid"/>
            </div>
            <div className='aug-collage-gallery__items'>                    
                <img src={require('../../../Assets/images/Careers/Collage/4.webp')} alt="Diverse team of professionals at Augnito standing in front of historic building with ornate windows and coat of arms" className="img-fluid"/>
            </div>
            <div className='aug-collage-gallery__items'>                    
                <img src={require('../../../Assets/images/Careers/Collage/5.webp')} alt="Team Augnito posing in front of historic building with ornate architecture and gardens; employees attending company presentation in Augnito office, attentively listening to speaker" className="img-fluid"/>
            </div>
            <div className='aug-collage-gallery__items'>                    
                <img src={require('../../../Assets/images/Careers/Collage/6.webp')} alt="Augnito team at office decorated with blue and white balloons, celebrating company milestone" className="img-fluid"/>
            </div>
        </Slider>
       </section>
    );
};

export default CollageGallery;