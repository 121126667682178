import React from 'react';
import './LeadingPlatforms.scss';
import BaseComponent from '../../../Common/BaseComponent';
import LeadingPlatformsLogo from './LeadingPlatformsLogo';
import LeadingPlatformsLogoIN from './LeadingPlatformsLogoIN';
import LeadingPlatformsLogoME from './LeadingPlatformsLogoME';
import {RedirectToDeveloper} from '../../../Common/Utils';

export default class LeadingPlatforms extends BaseComponent {

    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-leading-platforms'>
                <div className='container'>
                    <div className='aug-leading-platforms-wrap border-top'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-12'>
                                <div className='aug-leading-platforms-box'>
                                    <h2 className='mb-3'>Compatible with Leading Clinical Platforms</h2>
                                    <p className='mb-0'> Deep integrations with leading global clinical platforms, as well as low-code and no-code options for direct dictation and data transfer capabilities in any HIS/EMR/PACS/RIS. </p>
                                    <button type='button' className='btn btn-primary' onClick={RedirectToDeveloper}>
                                        Learn more
                                    </button>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-12'>
                                <div className='aug-leading-platforms__img'>
                                    {
                                        regionData === "IN" ? <LeadingPlatformsLogoIN/> :
                                        regionData === "ME" ? <LeadingPlatformsLogoME/>:
                                        <LeadingPlatformsLogo/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};