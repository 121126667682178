import React from 'react';
import './Footer.scss';
import BaseComponent from '../../../Common/BaseComponent'
import FooterSPRAr from './FooterSPRAr';
import FooterInfoAr from './FooterInfoAr';

export function AddLibrary(url) {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    script.setAttribute("data-cookieyes", "cookieyes-analytics")
    document.body.appendChild(script);
}

export default class FooterAr extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <footer dir='rtl' className='aug-footer'>
                {/* {AddLibrary("//js.hs-scripts.com/9455047.js")} */}
                <div className='aug-footer-wrap'>
                    <div className='container'>
                        <div className='aug-footer-wrap-content'>
                            <FooterInfoAr />
                        </div>
                    </div>
                </div>
                <div className='aug-footer-copyright'>
                    <div className='container'>
                        <FooterSPRAr />
                    </div>
                </div>
            </footer>
        );
    }
}