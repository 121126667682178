import React from 'react';
import './Header.scss'
import Navbar from '../Navbar/Navbar';
import BaseComponent from '../../../Common/BaseComponent'
import DetectingLocation from '../DetectingLocation/DetectingLocation';
import DetectingLocationError from '../DetectingLocation/DetectingLocationError';
import NavbarAr from '../Navbar/NavbarAr';

export class Header extends BaseComponent {

    render() {
        var regionData = this.props.userRegionData;
        var pathname = window.location.pathname;
        var className = "";
        var isArabicURL = false;

        if (pathname.toLowerCase().includes("/ar/")) {
            isArabicURL = true;
        }
        if (!this.props.isGeoRequestSuccess) {
            return <div className="App">
                <DetectingLocationError />
            </div>;
        } else if (this.props.isGeoLoading) {
            return <div className="App">
                <DetectingLocation />
            </div>;
        }
        return (
            <header className={`aug-sticky-header sticky-top ${className}`} id="augnito-main-header">
                {isArabicURL ? <NavbarAr /> : <Navbar userRegionData={regionData} />}
            </header>
        );
    }
}

export default Header;