import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import VoiceAgreementMeta from './VoiceAgreementMeta';

export default class VoiceAgreement extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <VoiceAgreementMeta />
                <section className='aug-legal-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 offset-lg-1 col-lg-10'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Augnito API And SDK License Agreement</h1>
                                    <p className='mb-0'>Last updated: 29th June, 2023</p>
                                </div>
                                <p className='mb-3'>
                                    This Augnito API And SDK License Agreement (this “<strong>Agreement</strong>”), effective as of <strong>Date  of first Login to developer portal or Date of Subscription to Augnito Voice Services Product in case of an offline purchase</strong> (“<strong>Effective Date</strong>”), and is executed by and between <strong>Augnito (India) Private Ltd</strong>., having its registered office at 31B, Floor – 1, Plot – 15, Meher House, Cawasji Patel Road, Horniman Circle Fort Mumbai, Mumbai City, MH 400001) (hereinafter referred to as “<strong>AIPL</strong>”, which expression shall, unless it be repugnant to the context or meaning thereof, be deemed to mean and include its successors-in-interest and assigns) and <strong>the Customer</strong> (defined below, hereinafter referred to as “<strong>Customer</strong>” which expression shall, unless it be repugnant to the context or meaning thereof, be deemed to mean and include its successors-in-interest and permitted assigns). AIPL and Customer may be referred to herein collectively as the “<strong>Parties</strong>” or individually as a “<strong>Party</strong>.”
                                </p>

                                <div className='mb-5'>
                                    <div className='aug-legal-page-subtitle'>
                                        <strong>Whereas:</strong>
                                    </div>
                                    <ul className='list-unstyled ps-0 mb-3'>
                                        <li>
                                            <strong>(a)</strong>
                                            <p className='mb-0'>AIPL has developed API (defined below) and SDK (defined below) for an application that converts voice to text based upon Speech recognition and natural language processing(“<strong>Augnito</strong>”); and  </p>
                                        </li>
                                        <li>
                                            <strong>(b)</strong>
                                            <p className='mb-0'>Customer desires to obtain API and SDK license from AIPL to access  Augnito, subject to the terms and conditions of this Agreement in addition to the Augnito General Terms of Use  and Subscription Terms  (as available on the Website).The Customer acknowledges that the terms of this Agreement shall apply irrespective of the mode obtaining license (online/offline)</p>
                                        </li>
                                    </ul>
                                    <p className='mb-0'>NOW, THEREFORE, in consideration of the mutual covenants, terms, and conditions set forth herein, and for other good and valuable considerations, the receipt and sufficiency of which are hereby acknowledged, the Parties agree as follows:</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>1.</span> Definitions</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.1. </strong>
                                        <p className='mb-0'> <strong>“Affiliate” </strong>	means any company or entity that directly or indirectly, controls, is controlled by, or is under common control with such Party, where “control” means the possession of the power to direct or cause the direction of the management or policies of that company or entity, whether through ownership of voting securities, by agreement, or otherwise.  </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.2. </strong>
                                        <p className='mb-0'><strong>“Aggregated Statistics” </strong>	means data and information related to Customer’s use of Augnito, which is used by Augnito in an aggregate and anonymized manner, including complying statistical and performance information related to the provision and operation of the Augnito.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.3. </strong>
                                        <p className='mb-0'><strong>“API” </strong> means AIPL’s proprietary application programming interface that allows the Customer and its Authorized Users to connect to and use Augnito in accordance with this Agreement.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.4. </strong>
                                        <p className='mb-0'><strong>“Applications” </strong> means any applications developed by Customer and other Authorized Users, using the SDK to interact with the API to access Augnito.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.5. </strong>
                                        <p className="mb-0"><strong>“Authorized User Terms” </strong> means such terms, which include terms listed in <strong>Exhibit B</strong>, that Authorized Users agree to before accessing Application.  </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.6. </strong>
                                        <p className="mb-0"><strong>“Authorized User” </strong> means an employee or customer of Customer who Customer permits to access and use Augnito pursuant to Customer’s license hereunder. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.7. </strong>
                                        <p className='mb-0'><strong>“Customer” </strong> means any Individual or Body Corporate or Company or Partnership Firm or any other Entity as recognised by law who obtains API and SDK license from AIPL to access the Augnito via its Website</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.8. </strong>
                                        <p className='mb-0'><strong>“Confidential Information” </strong> means any information, unless previously identified in a non-disclosure agreement such agreement to be integrated into this Agreement, that is clearly identified in writing as confidential at the time of disclosure, and any written or oral information that, based on the circumstances under which it was disclosed, a reasonable person would believe to be confidential. Such Confidential Information includes, but is not limited to, terms of this Agreement, Customer Data, product proposals, technological processes, product forecasts, trade secrets, pre-publication patent applications, product designs, license key, pricing information, software and system designs, functionalities, know-how, technology specifications, source code, graphic designs, proprietary financial, and personnel and sales information. Confidential Information also includes all copies, summaries and extracts of any Confidential Information.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.9. </strong>
                                        <p className='mb-0'><strong>“Customer Data” </strong> means information, data, and other content, in any form or medium that is submitted, posted, or otherwise transmitted by or on behalf of Customer or an Authorized User through Augnito. For avoidance of doubt, Customer Data does not include Resultant Data or any other information reflecting the access or use of Augnito by or on behalf of Customer or any Authorized User. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.10. </strong>
                                        <p className='mb-0'><strong>“Date of first Login”  </strong> means the date on which the Customer obtain API and SDK license from AIPL to access the Augnito from AIPL website. Date of first Login shall be as recorded on AIPL Website.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.11. </strong>
                                        <p className='mb-0'><strong>“Date of Subscription”  </strong> means the date on which the Customer obtain API and SDK license from AIPL to access the Augnito which maybe online or offline.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.12. </strong>
                                        <p className='mb-0'><strong>“Including”  </strong> means “including, without limitation”.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.13. </strong>
                                        <p className='mb-0'><strong>“Personal Information”  </strong> means and has the meaning ascribed to the said term under the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, as modified from time to time, or superseded by a special legislation in this regard.</p>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>1.14. </strong>
                                            <p className='mb-0'><strong>“Resultant Data”  </strong> means data and information related to Customer’s use of Augnito that is used by AIPL:</p>
                                        </div>
                                        <ul className='list-unstyled mb-0'>
                                            <li>
                                                <strong>(a)  </strong>
                                                <p className='mb-0'> to train its proprietary AI technology to learn and produce sophisticated results, and</p>
                                            </li>
                                            <li>
                                                <strong>(b)  </strong>
                                                <p className='mb-0'> in an aggregate and anonymized manner, including to compile Aggregated Statistics.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.15. </strong>
                                        <p className='mb-0'><strong> “AIPL IP” </strong> means the SDK, API, Augnito, AIPL Marks, the documentations, and any and all intellectual property provided to Customer in connection with the SDK. For the avoidance of doubt, AIPL IP includes Aggregated Statistics and Resultant Data, but does not include Customer Data.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.16. </strong>
                                        <p className='mb-0'><strong>“AIPL Marks” </strong> means AIPL’s proprietary trademarks, including, but not limited to, AUGNITO mark, trade names, branding, or logos.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.17. </strong>
                                        <p className='mb-0'><strong>“SDK” </strong> means software development kit for Augnito related materials, which includes API and documentation.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.18. </strong>
                                        <p className='mb-0'><strong>“Updates” </strong>  means any updates, bug fixes, patches, or other error corrections to the API and SDK.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>2. License</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.1.</strong>
                                        <p className='mb-0'><strong> License Grants to API and SDK: </strong>	Subject to and conditioned on Customer’s payment of Fees and compliance with all the terms and conditions set forth in this Agreement, AIPL hereby grants Customer a limited, revocable, non-exclusive, transferable (only in accordance with this Agreement), sublicensable (only in accordance with this Agreement), and worldwide license during the term of the Agreement to: (a) use by the Customer and authorize the Authorized Users to use the API solely for the purposes of developing the Applications that will communicate and interoperate with Augnito; (b) install and use SDK in object code only form to develop Application that can function with Augnito on Customer’s systems and/or its Authorized User’s systems; and (c) display and authorize Authorized Users to display, the ownership of AIPL of the API and SDK and certain AIPL Marks in compliance with usage guidelines that AIPL may specify from time to time solely in connection with the use of the API, SDK, related documentation (if any), and the Applications.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.2.</strong>
                                        <p className='mb-0'><strong>Provision of Access to Augnito: </strong>	Subject to this Agreement’s terms and conditions, AIPL hereby grants Customer a non-exclusive, sublicensable (only in accordance with this Agreement), non-transferable (except in compliance with Section 13.6 [Assignment]) right to access and use Augnito during the Term, solely for use by Customer. Customer may grant access to Augnito to its Authorized Users only in accordance with the terms and conditions herein.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.3.</strong>
                                        <div className='mb-0'>
                                            <p className='mb-3'><strong>Trademark License to Use Augnito Mark: </strong>	Subject to this Agreement’s terms and conditions, AIPL hereby grants Customer a non-exclusive, sublicensable (only in accordance with this Agreement), non-transferable (except in compliance with Section 13.6 [Assignment]) right and limited license to use and authorize its Authorized Users to use Augnito mark as follows:</p>
                                            <p className='mb-3'>“<strong>powered by Augnito</strong>”, solely in connection with the Application. Failure by Customer to add “<strong>powered by Augnito</strong>”, during the Term of this Agreement, </p>
                                            <ul className='mb-0 list-unstyled ps-0'>
                                                <li>
                                                    <strong>(a) </strong>
                                                    <p className='mb-0'> on its website and/or the Application that is easily visible to Customer's users, clients, and other third-parties; and/ or </p>
                                                </li>
                                                <li>
                                                    <strong>(b) </strong>
                                                    <p className='mb-0'> to Customer’s marketing and sales materials wherever Application is mentioned, shall constitute a material breach of this Agreement. Customer shall comply and cause its Authorized Users to comply with Customer’s trademark guidelines concerning the use of the AUGNITO mark. Customer and its Authorized Users shall </p>
                                                </li>
                                                <li>
                                                    <strong>(c) </strong>
                                                    <p className='mb-0'> permit AIPL on reasonable notice and during normal business hours, and subject to reasonable confidentiality obligations, to inspect all records related to the use of AUGNITO mark, and </p>
                                                </li>
                                                <li>
                                                    <strong>(d) </strong>
                                                    <p className='mb-3'> prior to each initial use of AUGNITO mark or any material modification or variation of the authorized use, submit to AIPL a representative sample of each such use for AIPL’s review and approval. In accordance with Section 3.4, Customer further acknowledges and agrees and shall cause its Authorized Users to acknowledge and agree that: </p>
                                                </li>
                                                <li>
                                                    <ul className='mb-0 list-unstyled'>
                                                        <li>
                                                            <strong>(i) </strong>
                                                            <p className='mb-0'> AIPL owns and will retain all right, title, and interest in and to the AUGNITO Mark; and </p>
                                                        </li>
                                                        <li>
                                                            <strong>(ii) </strong>
                                                            <p className='mb-0'> all use by Customer or any Authorised User of the AUGNITO mark under this Agreement, and all goodwill accruing therefrom, shall inure solely to the benefit of AIPL. </p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.4.</strong>
                                        <div>
                                            <p className='mb-3'><strong> Use Restrictions:</strong> Customer shall not use the API, the SDK, Augnito, or any AIPL Mark for any purposes beyond the scope of the license granted in this Agreement. Without limiting the foregoing and except as expressly set forth in this Agreement, Customer shall not at any time, and shall not permit others to: </p>
                                            <ul className='mb-0 list-unstyled ps-0'>
                                                <li>
                                                    <strong>(a) </strong>
                                                    <p className='mb-0'> copy, modify, or create derivative works of the SDK, in whole or in part; </p>
                                                </li>
                                                <li>
                                                    <strong>(b) </strong>
                                                    <p className='mb-0'>rent, transfer, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the SDK (except as explicitly granted under this Agreement); </p>
                                                </li>
                                                <li>
                                                    <strong>(c) </strong>
                                                    <p className='mb-0'> reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software component of the SDK, in whole or in part; </p>
                                                </li>
                                                <li>
                                                    <strong>(d) </strong>
                                                    <p className='mb-0'> remove any proprietary notices from the SDK; </p>
                                                </li>
                                                <li>
                                                    <strong>(e) </strong>
                                                    <p className='mb-0'>use the SDK in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law; </p>
                                                </li>
                                                <li>
                                                    <strong>(f) </strong>
                                                    <p className='mb-0'>combine or integrate the SDK with any software, technology, services, or materials not authorized by AIPL; </p>
                                                </li>
                                                <li>
                                                    <strong>(g) </strong>
                                                    <p className='mb-0'> design or permit the Applications to disable, override, or otherwise interfere with any AIPL-implemented communications to end users, consent screens, user settings, alerts, warning, or the like; </p>
                                                </li>
                                                <li>
                                                    <strong>(h) </strong>
                                                    <p className='mb-3'> use the SDK in any of the Applications to replicate or attempt to replace the user experience of Augnito; </p>
                                                </li>
                                                <li>
                                                    <strong>(i) </strong>
                                                    <p className='mb-0'> attempt to cloak or conceal Customer’s identity or the identity of the Applications when requesting authorization to use the SDK; </p>
                                                </li>
                                                <li>
                                                    <strong>(j) </strong>
                                                    <p className='mb-0'>publish SDK for others to copy; or </p>
                                                </li>
                                                <li>
                                                    <strong>(k) </strong>
                                                    <p className='mb-0'> use the SDK to develop applications for other platforms or to develop another SDK. Customer shall immediately notify AIPL in writing if, to Customer’s knowledge, any of Customer’s former, current or future directors, officers, employees, subcontractors, agents, consultants or customers, or any third person or party engages in any of the activities described in this section. </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.5.</strong>
                                        <p className='mb-0'><strong> Reservation of Rights: </strong> AIPL reserves all rights not expressly granted to Customer in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party any intellectual property rights or other right, title, or interest in or to the SDK. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.6.</strong>
                                        <div>
                                            <p className='mb-0'><strong>Aggregated Statistics: </strong> Notwithstanding anything to the contrary in this Agreement, AIPL may monitor Customer’s and Authorized User’s use of the SDK and Augnito and collect and compile Aggregated Statistics. As between the Parties, all right, title, and interest in Aggregated Statistics, and all intellectual property rights therein, belong to and are retained solely by AIPL. Customer acknowledges that AIPL may compile Aggregated Statistics and: </p>
                                            <ul className='mb-0 list-unstyled ps-0'>
                                                <li>
                                                    <strong>(i) </strong>
                                                    <p className='mb-0'> use to make Aggregated Statistics publicly available in compliance with applicable law; and </p>
                                                </li>
                                                <li>
                                                    <strong>(ii) </strong>
                                                    <p className='mb-0'>to the extent and in the manner permitted under applicable law; provided that such Aggregated Statistics do not identify Customer, Authorized User’s or Customer’s Confidential Information. </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <strong className='me-2'>2.7 </strong>
                                        <p className='mb-0'><strong>Open Source Software: </strong>  Notwithstanding anything contained herein, the Customer may integrate or use the SDK with any Open Source Software, provided however that the Customer shall not, and shall not allow the Authorized Users to integrate or use such Open Source Software that would require disclosure, distribution, or licensing of all or any part of the SDK in source code form, for the purpose of making derivative works, or at no charge. For the purposes of this Section, “Open Source Software” shall mean software licensed under the GNU General Public License, the GNU Lesser General Public License, or any other license terms that could require, or condition the use, modification, or distribution by the Customer of such software on, the disclosure, distribution, or licensing of any other software in source code form, for the purpose of making derivative works, or at no charge. Any violation of this Section shall constitute a material breach of this Agreement, entitling AIPL to immediately terminate the licenses and other rights to the SDK granted under this Agreement. </p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>3.</span> Customer Responsibilities</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.1. </strong>
                                        <p className='mb-0'>Customer is responsible and liable for all uses of the SDK and Augnito resulting from access provided by Customer, directly or indirectly, whether such access or use is permitted by or in violation of this Agreement. Without limiting the generality of the foregoing, Customer is responsible for all acts and omissions of Customer’s Authorized Users in connection with the Application and their use of the SDK and Augnito. Any act or omission by Customer’s Authorized Users that would constitute a breach of this Agreement if taken by Customer will be deemed a breach of this Agreement by Customer. Customer shall take reasonable efforts to make all of Customer’s Authorized Users aware of Authorized User Terms and shall cause end users to comply with such provisions. AIPL shall promptly inform Customer of any suspected violation of the Authorized Users Terms, and Customer shall be liable to AIPL for any known or permitted violation of Authorized User Terms. AIPL shall have no obligation to provide support or other remedies to Authorized Users, except as are provided to or through Customer.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.2. </strong>
                                        <p className='mb-0'>Customer shall comply with all terms and conditions of this Agreement, all applicable laws, rules, and regulations. Customer shall monitor the use of the Applications for any activity that violates applicable laws, rules, and regulations or any terms and conditions of this Agreement, including any fraudulent, inappropriate, or potentially harmful behaviour, and promptly restrict any offending users of the Applications from further use of the Applications. Customer is solely responsible for posting any privacy notices and obtaining any consents from Authorized Users required under applicable laws, rules, and regulations for their use of the Applications.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.3. </strong>
                                        <p className='mb-0'>Customer will use commercially reasonable efforts to safeguard the SDK, Augnito, and AIPL Marks (including all copies thereof) from infringement, misappropriation, theft, misuse, or unauthorized access. Customer will promptly notify AIPL if Customer becomes aware of any infringement of any intellectual property rights in the SDK or AIPL Marks and will fully cooperate with AIPL in any legal action taken by AIPL to enforce AIPL’s intellectual property rights.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.4. </strong>
                                        <p className='mb-0'>All use by Customer and its Authorized Users of the AIPL Marks, if any, shall comply with any usage guidelines that AIPL may specify from time to time. Customer agrees and shall cause its Authorized Users to agree that the use of the AIPL Marks in connection with this Agreement will not create any right, title, or interest in or to the AIPL Marks in favor of Customer and all goodwill associated with the use of the AIPL Marks will inure to the benefit of AIPL.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.5. </strong>
                                        <p className='mb-0'>To use and access the API, Customer must obtain API credentials (a “Token”) by becoming a Subscriber. Customer may not share its Token with any third party, shall keep such Token and all Login information secure, and shall use the Token as Customer’s sole means of accessing the API.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.6. </strong>
                                        <p className='mb-0'>Customer’s Applications shall not substantially replicate products or services offered by AIPL, including, without limitation, functions or clients on platforms (such as iOS or Android) where AIPL offers its own client or function. Subject to the preceding sentence and the parties’ other rights and obligations under this Agreement, each party agrees that the other party may develop and publish applications that are similar to or otherwise compete with such party’s applications. Applications may not use or access the API or a Service to monitor the availability, performance, or functionality of any of the API or a Service or for any similar benchmarking purposes.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.7. </strong>
                                        <div>
                                            <p className='mb-3'>Customer acknowledges that Customer is solely responsible, and that AIPL has no responsibility or liability of any kind, for the content, development, operation, support or maintenance of Applications. Without limiting the foregoing, Customer will be solely responsible for</p>
                                            <ul className='mb-0 list-unstyled ps-0'>
                                                <li>
                                                    <strong>(a) </strong>
                                                    <p className='mb-0'>the technical installation and operation of its Applications;</p>
                                                </li>
                                                <li>
                                                    <strong>(b) </strong>
                                                    <p className='mb-0'>creating and displaying information and content on, through or within its Applications;</p>
                                                </li>
                                                <li>
                                                    <strong>(c) </strong>
                                                    <p className='mb-0'>ensuring that its Applications do not violate or infringe the Intellectual Property Rights of any third party;</p>
                                                </li>
                                                <li>
                                                    <strong>(d) </strong>
                                                    <p className='mb-0'>ensuring that Applications are not offensive, profane, obscene, libellous or otherwise illegal;</p>
                                                </li>
                                                <li>
                                                    <strong>(e) </strong>
                                                    <p className='mb-0'>ensuring that its Applications do not contain or introduce Malicious Software into a Service, an API, any Service Data or other data stored or transmitted using the Service;</p>
                                                </li>
                                                <li>
                                                    <strong>(f) </strong>
                                                    <p className='mb-0'>ensuring that its Applications are not designed to or utilized for the purpose of spamming any AIPL subscribers, Agents or End-Users; and</p>
                                                </li>
                                                <li>
                                                    <strong>(g) </strong>
                                                    <p className='mb-0'>ensuring that its Applications do not violate any applicable law or third party right.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>4.</span> Support and Updates</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>4.1. </strong>
                                        <p className='mb-0'><strong>Support: </strong> AIPL shall provide Customer with the support services AIPL provides support for SDK to Customer via email twenty-four (24) (the “<strong>Support Hours</strong>”). Customer may initiate a helpdesk ticket during Support Hours by emailing <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a>. AIPL will use commercially reasonable efforts to respond to all Helpdesk tickets within one (1) business day. The Support will be available during the Term as governed by Subscription Terms.</p>
                                    </div>
                                    <div className='d-flex'>
                                        <strong className='me-2'>4.2. </strong>
                                        <p className='mb-0'><strong>Updates: </strong> During the Term, AIPL shall provide Customer, at no additional charge, all Updates, each of which are a part of the SDK and are subject to the terms and conditions of this Agreement. Customer acknowledges that AIPL may require Customer to obtain and use the most recent version of the API. Updates may adversely affect how the Applications communicate with the AIPL Offering. Customer is required to make any changes to the Applications that are required for integration as a result of such Update at Customer’s sole cost and expense.</p>
                                    </div>


                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>5.</span> Fees and Payment</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>5.1. </strong>
                                        <p className='mb-0'><strong>Fees: </strong> Customer shall pay AIPL the fees (“<strong>Fees</strong> set forth in the Subscription Terms .</p>
                                    </div>
                                    <div className='d-flex'>
                                        <strong className='me-2'>5.2. </strong>
                                        <p className='mb-0'><strong> Taxes: </strong> All Fees and other amounts payable by Customer under this Agreement are exclusive of taxes and similar assessments. Customer is responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any federal, state, or local governmental or regulatory authority on any amounts payable by Customer hereunder, other than any taxes imposed on AIPL’s income. Where pursuant to requirement under the applicable law, the Customer deducts any taxes at source from any the payment made to AIPL, the Customer agrees to promptly deposit such taxes within the timelines prescribed under the applicable laws, with the tax authorities and furnish the requisite tax deduction certificate to AIPL, within the prescribed timelines under the applicable law for the taxes that have been withheld.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>6.</span> Confidential Information</h3>
                                    <p className='mb-3'>From time to time during the Term, either Party may disclose or make available to the other Party Confidential Information. Confidential Information does not include information that, at the time of disclosure is:</p>
                                    <ul className='mb-0 list-unstyled ps-0'>
                                        <li>
                                            <strong>(a) </strong>
                                            <p className='mb-0'>in the public domain;</p>
                                        </li>
                                        <li>
                                            <strong>(b) </strong>
                                            <p className='mb-0'>known to the receiving Party at the time of disclosure;</p>
                                        </li>
                                        <li>
                                            <strong>(c) </strong>
                                            <p className='mb-0'>rightfully obtained by the receiving Party on a non-confidential basis from a third party; or</p>
                                        </li>
                                        <li>
                                            <strong>(d) </strong>
                                            <p className='mb-0'>independently developed by the receiving Party. The receiving Party shall not disclose the disclosing Party's Confidential Information to any person or entity, except to the receiving Party's employees who have a need to know the Confidential Information for the receiving Party to exercise its rights or perform its obligations hereunder. Notwithstanding the foregoing, each Party may disclose Confidential Information to the limited extent required</p>
                                        </li>
                                        <li>
                                            <ul className='mb-0 list-unstyled mt-3'>
                                                <li>
                                                    <strong>(i) </strong>
                                                    <p className='mb-0'>in order to comply with the order of a court or other governmental body, or as otherwise necessary to comply with applicable law, provided that the Party making the disclosure pursuant to the order shall first have given written notice to the other Party and made a reasonable effort to obtain a protective order; or</p>
                                                </li>
                                                <li>
                                                    <strong>(ii) </strong>
                                                    <p className='mb-0'> to establish a Party’s rights under this Agreement, including to make required court filings. On the expiration or termination of the Agreement, the receiving Party shall promptly return to the disclosing Party all copies, whether in written, electronic, or other form or media, of the disclosing Party's Confidential Information, or destroy all such copies and certify in writing to the disclosing Party that such Confidential Information has been destroyed. Each Party's obligations of non-disclosure with regard to Confidential Information are effective as of the Effective Date and will expire five (5) years from the date first disclosed to the receiving Party; provided, however, with respect to any Confidential Information that constitutes a trade secret (as determined under applicable law), such obligations of non-disclosure will survive the termination or expiration of this Agreement for as long as such Confidential Information remains subject to trade secret protection under applicable law.</p>
                                                </li>
                                            </ul>

                                        </li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>7.</span> Privacy and Information Security</h3>
                                    <div className='mb-3'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>7.1. </strong>
                                            <p className='mb-0'><strong>Customer – Representations: </strong>Customer represents and warrants that:</p>
                                        </div>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>(a) </strong>
                                                <p className='mb-0'> all Customer Data is either the sole property of Customer or has been collected by Customer in accordance with applicable Privacy Laws under a valid legal and enforceable contract,</p>
                                            </li>
                                            <li>
                                                <strong>(b) </strong>
                                                <p className='mb-0'> Customer shall comply with all applicable laws and regulations to protect Customer Data, which may contain Personal Information of end users.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>7.2. </strong>
                                            <p className='mb-0'><strong> Customer – Privacy and Security: </strong> Customer shall comply with all applicable laws that apply to its use of the SDK and Augnito (collectively, “<strong>Privacy Laws</strong>”). Customer is responsible for the security of Customer Data provided to AIPL. Customer shall employ all physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to:</p>
                                        </div>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>(a) </strong>
                                                <p className='mb-0'> securely administer the distribution and use of all access credentials and protect against any unauthorized access to or use of the SDK; and</p>
                                            </li>
                                            <li>
                                                <strong>(b) </strong>
                                                <p className='mb-0'> control the use of Customer Data.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>7.3. </strong>
                                        <p className='mb-0'><strong>AIPL – Privacy and Security: </strong>AIPL shall maintain commercially reasonable administrative, physical, and technical safeguards to help protect the security, confidentiality, and integrity of Customer Data. AIPL has no responsibility or liability for the accuracy of Customer Data. AIPL further represents and warrants that AIPL shall not:</p>
                                    </div>
                                    <ul className='mb-3 list-unstyled'>
                                        <li>
                                            <strong>(a) </strong>
                                            <p className='mb-0'> sell Personal Information, or</p>
                                        </li>
                                        <li>
                                            <strong>(b) </strong>
                                            <p className='mb-0'> retain, use or disclose Personal Information:</p>
                                        </li>
                                        <li>
                                            <ul className='mb-0 mt-3 list-unstyled'>
                                                <li>
                                                    <strong>(i) </strong>
                                                    <p className='mb-0'> for any purpose other than for the specific purpose of performing and delivering this Agreement, or</p>
                                                </li>
                                                <li>
                                                    <strong>(ii) </strong>
                                                    <p className='mb-0'> outside of the direct business relationship between AIPL and Customer</p>
                                                </li>
                                                <li>
                                                    <strong>(iii) </strong>
                                                    <p className='mb-0'> train its AI, subject to anonymization and applicable laws. AIPL further confirms that it shall process all data subject to anonymization and applicable laws and not retain/store any data beyond the period of 90 (Ninety) days from receipt for the purposes of providing services under this Agreement or training its AI.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>7.4.</strong>
                                        <p className='mb-0'><strong>Security Program: </strong>Notwithstanding the provisions in Sections 7.1 and 7.2, each Party shall maintain a formal security program in accordance with generally acceptable industry standards and applicable law that is designed to:</p>
                                    </div>
                                    <ul className='mb-0 list-unstyled'>
                                        <li>
                                            <strong>(a) </strong>
                                            <p className='mb-0'> ensure the security and integrity of Customer Data;</p>
                                        </li>
                                        <li>
                                            <strong>(b) </strong>
                                            <p className='mb-0'> protect against threats or hazards to the security or integrity of Customer Data; and</p>
                                        </li>
                                        <li>
                                            <strong>(c) </strong>
                                            <p className='mb-0'> prevent unauthorized access to Customer Data. Each Party will regularly conduct security testing of its systems and applications and promptly remediate findings in accordance with their severity levels. Each Party agrees to provide the other Party notice of any unauthorized third-party access to Customer Data in the other Party’s possession or control of which it becomes aware and to provide reasonable efforts to remediate identified security vulnerabilities.</p>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>8.</span> Intellectual Property Ownership; Feedback</h3>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>8.1. </strong>
                                        <p className='mb-0'><strong> AIPL IP: </strong> Customer acknowledges that, as between Customer and AIPL, AIPL owns all right, title, and interest, including all intellectual property rights, in and to the AIPL IP.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>8.2. </strong>
                                        <p className='mb-0'><strong>Customer Data: </strong> AIPL acknowledges that, as between AIPL and Customer, Customer owns all right, title, and interest, including all intellectual property rights, in and to the Customer Data. Customer hereby grants to AIPL a non-exclusive, royalty-free, worldwide license to reproduce, distribute, and use and display the Customer Data solely to the extent necessary for AIPL to provide the API access and SDK to Customer.</p>
                                    </div>
                                    <div className='d-flex mb-0'>
                                        <strong className='me-2'>8.3. </strong>
                                        <p className='mb-0'><strong>Feedback: </strong> If Customer, its employees, and/or any Authorized Users send or transmit any communications or material to AIPL by mail, email, telephone, or otherwise, suggesting or recommending changes to the AIPL IP, including new features or functionality relating thereto, or any comments, questions, suggestions, or the like (“<strong>Feedback</strong>”), AIPL is free to use such Feedback irrespective of any other obligation or limitation between the Parties governing such Feedback. Customer hereby assigns to AIPL on Customer’s behalf, and on behalf of its employees, contractors and/or agents, all right, title, and interest in, and AIPL is free to use, without any attribution or compensation to Customer or any third party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in the Feedback, for any purpose whatsoever, although AIPL is not required to use any Feedback.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>9.</span> Disclaimer of Warranties</h3>
                                    <p className='mb-3'>The API, SDK, augnito, and AIPL marks are provided “as is” and AIPL specifically disclaims all warranties, whether express, implied, statutory, or otherwise. AIPL specifically disclaims all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and all warranties arising from course of dealing, usage, or trade practice. AIPL makes no warranty of any kind that the API, SDK, Augnito, and AIPL marks, or any products or results of the use thereof, will meet customer’s or any other person’s requirements, operate without interruption, achieve any intended result, be compatible or work with any of customer’s or any third party's software, system, or other services, or be secure, accurate, complete, free of harmful code, or error-free, or that any errors or defects can or will be corrected.</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>10.</span> Indemnification</h3>
                                    <p className='mb-3'>Customer agrees to indemnify, defend, and hold harmless AIPL and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees, arising from or relating to:</p>
                                    <ul className='mb-0 list-unstyled ps-0'>
                                        <li>
                                            <strong>(a)</strong>
                                            <p className='mb-0'> Customer’s use or misuse of the SDK, Augnito, or AIPL Marks,</p>
                                        </li>
                                        <li>
                                            <strong>(b)</strong>
                                            <p className='mb-0'> Customer’s breach of this Agreement,</p>
                                        </li>
                                        <li>
                                            <strong>(c)</strong>
                                            <p className='mb-0'> Customer providing personal health information; and</p>
                                        </li>
                                        <li>
                                            <strong>(d)</strong>
                                            <p className='mb-0'> the Applications, including any end users or Authorized Users thereof. In the event AIPL seeks indemnification or defence from Customer under this provision, AIPL will promptly notify Customer in writing of the claim(s) brought against AIPL for which AIPL seeks indemnification or defence. AIPL reserves the right, at AIPL’s option and in AIPL’s sole discretion, to assume full control of the defence of claims with legal counsel of AIPL’s choice at Customer’s expense. Customer may not enter into any third-party agreement that would, in any manner whatsoever, constitute an admission of fault by AIPL or bind AIPL in any manner, without AIPL’s prior written consent.</p>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>11.</span> Limitations of Liability</h3>
                                    <p className='mb-0'>In no event will aipl or its employees, agents, affiliates or contractorsbe liable under or in connection with this agreement under any law or equity, including breach of contract, tort (including negligence), strict liability, and otherwise, for any consequential, incidental, indirect, exemplary, special, enhanced, or punitive damages, loss of revenue, profit or goodwill, regardless of whether the other party was advised of the possibility of such losses or damages or such losses or damages were otherwise foreseeable. in no event will the aggregate liability of aipl arising out of or related to this agreement under any law or equity, including breach of contract, tort (including negligence), strict liability, and otherwise exceed one time the total amounts paid and amounts accrued but not yet paid to aipl under this agreement in the three (3) month period preceding the event giving rise to the claim.</p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>12.</span> Term and Termination</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>12.1. </strong>
                                        <p className='mb-0'><strong>Term and Termination:</strong> To be governed by Augnito General Terms of Use</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>12.2. </strong>
                                        <p className='mb-0'><strong>Effect of Expiration or Termination:</strong> Upon expiration or termination of this Agreement for any reason all licenses and rights granted to Customer under this Agreement will also terminate and Customer must cease using, destroy, and permanently erase from all devices and systems Customer directly or indirectly controls all copies of the API, SDK, and AIPL Marks. The Customer will export the data it requires prior to termination. AIPL will delete all data as on date of termination and communicate the same via email.</p>
                                    </div>
                                    <div className='d-flex mb-0'>
                                        <strong className='me-2'>12.3. </strong>
                                        <p className='mb-0'><strong>Survival: </strong> Clauses 1, 2.4, 2.5, 2.6, 3 (including subsections), 5, 6, 8, 9, 10, 11, 12.3, 12.3(including subsections) shall survive any termination or expiration of this Agreement. No other provisions of this Agreement survive the expiration or earlier termination of this Agreement.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Authorized user Terms</h3>
                                    <p className='mb-3'>Customer shall ensure that Authorized Users abide by the following Authorized Users Terms.</p>
                                    <p className='mb-3'><strong>Authorized Users shall be required to:</strong></p>
                                    <ul className='mb-4 list-unstyled ps-0'>
                                        <li>
                                            <strong>(a) </strong>
                                            <p className='mb-0'> Not use Augnito and Application in knowing violation any applicable federal, state, local, or international law or regulation, or third-party rights – including but not limited to licenses, copyrights, trademark rights, or other third-party rights.</p>
                                        </li>
                                        <li>
                                            <strong>(b) </strong>
                                            <p className='mb-0'>  Adhere to all licenses, copyright laws, contracts, and other restricted or proprietary information.</p>
                                        </li>
                                        <li>
                                            <strong>(c) </strong>
                                            <p className='mb-0'> Safeguard Authorized User IDs, and passwords.</p>
                                        </li>
                                        <li>
                                            <strong>(d) </strong>
                                            <p className='mb-0'>  Avoid the introduction of harmful files/data that may contain spy-ware, viruses, etc. into Augnito.</p>
                                        </li>
                                        <li>
                                            <strong>(e) </strong>
                                            <p className='mb-0'>  Not knowingly transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.</p>
                                        </li>
                                        <li>
                                            <strong>(f) </strong>
                                            <p className='mb-0'>  Not knowingly impersonate or attempt to impersonate AIPL, a AIPL employee, another Authorized User, or any other person or entity (including by using email addresses associated with any of the foregoing).</p>
                                        </li>
                                    </ul>
                                    <p className='mb-3'><strong>Authorized User also shall not:</strong></p>
                                    <ul className='list-unstyled ps-0 mb-0'>
                                        <li>
                                            <strong>(a) </strong>
                                            <p className='mb-0'> reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, mine data from Augnito and Application;</p>
                                        </li>
                                        <li>
                                            <strong>(b) </strong>
                                            <p className='mb-0'> modify, translate, or create derivative works based on Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(c) </strong>
                                            <p className='mb-0'> rent, lease, or otherwise permit third-parties to use Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(d) </strong>
                                            <p className='mb-0'> circumvent or disable any security or other technological features or measures of Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(e) </strong>
                                            <p className='mb-0'> remove any proprietary notices or labels;</p>
                                        </li>
                                        <li>
                                            <strong>(f) </strong>
                                            <p className='mb-0'> use Augnito in any manner that could disable, overburden, damage, impair, or interfere with the use of Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(g) </strong>
                                            <p className='mb-0'> use any robot, spider, or other automatic device, process, or means to access Augnito for any purpose, including monitoring or copying any of the material on Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(h) </strong>
                                            <p className='mb-0'> use any manual process to monitor or copy any of the material on Augnito, or for any other purpose not expressly authorized in Agreement;</p>
                                        </li>
                                        <li>
                                            <strong>(i) </strong>
                                            <p className='mb-0'> use any device, software, or routine that interferes with the proper working of Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(j) </strong>
                                            <p className='mb-0'>  introduce or exploit any virus, Trojan horse, worm, logic bomb, or other material that is malicious or technologically harmful;</p>
                                        </li>
                                        <li>
                                            <strong>(k) </strong>
                                            <p className='mb-0'>  attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Augnito, the server on which Augnito is stored, or any server, computer, or database connected to Augnito;</p>
                                        </li>
                                        <li>
                                            <strong>(l) </strong>
                                            <p className='mb-0'>  attack Augnito via a denial-of-service attack or a distributed denial-of-service attack; and/or</p>
                                        </li>
                                        <li>
                                            <strong>(m) </strong>
                                            <p className='mb-0'>  otherwise attempt to interfere with the proper working of Augnito.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}