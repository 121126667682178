import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const AboutUsMetaAr = () => {

    return (
        <Helmet>
            <title>نبذة عن Augnito | الشركة الرائدة للذكاء الاصطناعي الصوتي الطبي الموثوق به في مجال الرعاية الصحية بالخليج</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="استكشف Augnito، الشركة الرائدة الموثوق بها في مجال التعرف على الصوت الطبي في منطقة الخليج العربي. تساهم ابتكاراتنا في تعزيز التوثيق السريري لأكثر من 500 مستشفى حول العالم، بما في ذلك مؤسسات الرعاية الصحية الرائدة في الخليج. انضم إلى الحركة التي تشكل مستقبل الكفاءة الطبية في الخليج." />
            <meta charset="UTF-8" />
            <meta name="language" content="ar" />
            <link rel="canonical" href={Constants.SITE_URL + "/ar/about-us"} />
            <meta property="og:url" content={Constants.SITE_URL + "/ar/about-us"} />
            <meta property="og:title" content="نبذة عن Augnito | الشركة الرائدة للذكاء الاصطناعي الصوتي الطبي الموثوق به في مجال الرعاية الصحية بالخليج" />
            <meta property="og:description" content="استكشف Augnito، الشركة الرائدة الموثوق بها في مجال التعرف على الصوت الطبي في منطقة الخليج العربي. تساهم ابتكاراتنا في تعزيز التوثيق السريري لأكثر من 500 مستشفى حول العالم، بما في ذلك مؤسسات الرعاية الصحية الرائدة في الخليج. انضم إلى الحركة التي تشكل مستقبل الكفاءة الطبية في الخليج." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/ar/about-us"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="نبذة عن Augnito | الشركة الرائدة للذكاء الاصطناعي الصوتي الطبي الموثوق به في مجال الرعاية الصحية بالخليج" />
            <meta name="twitter:description" content="استكشف Augnito، الشركة الرائدة الموثوق بها في مجال التعرف على الصوت الطبي في منطقة الخليج العربي. تساهم ابتكاراتنا في تعزيز التوثيق السريري لأكثر من 500 مستشفى حول العالم، بما في ذلك مؤسسات الرعاية الصحية الرائدة في الخليج. انضم إلى الحركة التي تشكل مستقبل الكفاءة الطبية في الخليج." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <link rel="alternate" hreflang="ar" href={Constants.SITE_URL + "/ar/about-us"} />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default AboutUsMetaAr;