import Header from './component/Shared/Header/Header';
import Footer from './component/Shared/Footer/Footer';
import Home from './component/Home/Home';
import Contact from './component/Contact/Contact';
import ThankYou from './component/ThankYou/ThankYou';
import PageNotFound from './component/PageNotFound/PageNotFound';
//import AlertDialog from './component/Shared/AlertDialog/AlertDialog';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/Shared/ScrollTop/ScrollToTop';
import Careers from './component/Careers/Careers';
import CareersAr from './component/Careers/CareersAr';
import AboutUs from './component/AboutUs/AboutUs';
import ContactSales from './component/ContactSales/ContactSales';
import ContactSalesAr from './component/ContactSales/ContactSalesAr';
import BaseComponent from './Common/BaseComponent'
import Legal from './component/Legal/Legal';
import BreachPolicy from './component/Legal/BreachPolicy/BreachPolicy';
import TermsOfUse from './component/Legal/TermsOfUse/TermsOfUse';
import ReferralPolicy from './component/Legal/ReferralPolicy/ReferralPolicy';
import RefundPolicy from './component/Legal/RefundPolicy/RefundPolicy';
import RetentionPolicy from './component/Legal/RetentionPolicy/RetentionPolicy';
import SubscriptionTerms from './component/Legal/SubscriptionTerms/SubscriptionTerms';
import CookiePolicy from './component/Legal/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './component/Legal/PrivacyPolicy/PrivacyPolicy';
import VoiceAgreement from './component/Legal/VoiceAgreement/VoiceAgreement';
import DataProcessing from './component/Legal/DataProcessing/DataProcessing';
import ContactAr from './component/Contact/ContactAr';
import ThankYouAr from './component/ThankYou/ThankYouAr';
import HomeAr from './component/Home/HomeAr';
import Spectra from './component/Spectra/Spectra';
import SpectraAr from './component/Spectra/SpectraAr';
import Omni from './component/Omni/Omni';
import OmniAr from './component/Omni/OmniAr';
import AboutUsAr from './component/AboutUs/AboutUsAr';
import SoftwareService from './component/Legal/SoftwareService/SoftwareService';
import SoftwareLicense from './component/Legal/SoftwareLicense/SoftwareLicense';

export default class App extends BaseComponent {
    render() {
        return (
            <div>
                <BrowserRouter>
                    {/* <div className='d-none d-md-block'>
                      <AlertDialog />
                    </div> */}
                    <ScrollToTop />
                    <Header isGeoLoading={this.state.isLoading}
                        isGeoRequestSuccess={this.state.isGeoRequestSuccess} userRegionData={this.state.regionData} />
                    <Routes>
                        <Route path="/"
                            element={<Home isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/"
                            element={<HomeAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/spectra"
                            element={<Spectra isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/spectra"
                            element={<SpectraAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/omni"
                            element={<Omni isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/omni"
                            element={<OmniAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/about-us"
                            element={<AboutUs isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/about-us"
                            element={<AboutUsAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/careers"
                            element={<Careers isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/careers"
                            element={<CareersAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/contact-sales"
                            element={<ContactSales isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/contact-sales"
                            element={<ContactSalesAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/contact-us"
                            element={<Contact isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/contact-us"
                            element={<ContactAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/thank-you"
                            element={<ThankYou isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/ar/thank-you"
                            element={<ThankYouAr isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/legal"
                            element={<Legal isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/terms-of-use"
                            element={<TermsOfUse isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/legal/augnito-privacy-policy"
                            element={<PrivacyPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-cookie-policy"
                            element={<CookiePolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/subscription-terms"
                            element={<SubscriptionTerms isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/refund-cancellation-policy"
                            element={<RefundPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/api-sdk-agreement"
                            element={<VoiceAgreement isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-data-breach-policy"
                            element={<BreachPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-data-retention-policy"
                            element={<RetentionPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/referral-policy"
                            element={<ReferralPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-data-processing-terms"
                            element={<DataProcessing isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/software-service-agreement"
                            element={<SoftwareService isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} />} />
                        <Route path="/legal/software-license-agreement"
                            element={<SoftwareLicense isGeoLoading={this.state.isLoading} />} />
                        <Route path="*"
                            element={<PageNotFound isGeoLoading={this.state.isLoading} />} />
                    </Routes>
                    <Footer isGeoLoading={this.state.isLoading} />
                    {/* <div className="d-block d-md-none">
                      <AlertDialog />
                    </div> */}
                </BrowserRouter>
            </div>
        );
    }
}
