import React from 'react';
import {Link} from "react-router-dom";

const ExpressInterest = () => {
    return (
        <section className='aug-thankyou-banner'>
            <div className='container'>
                <div className="row align-items-center">
                    <div className="col-12 col-lg-7">
                        <h1 className="aug-thankyou-banner__heading mb-3">Thank you for reaching out!</h1>
                        <p className='mb-0'>One of our representatives will be in touch shortly.</p>
                        <Link to="/" className="btn btn-primary">Back to home</Link>
                    </div>
                    <div className="offset-lg-1 col-12 col-lg-3">
                        <div className="aug-thankyou-banner__img">
                            <img src={require('../../../Assets/images/Pattrens/thank-you.webp')} className="img-fluid" alt="pattren" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExpressInterest;