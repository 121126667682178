import React from 'react';
import ActivateVideoMp from '../../../Assets/images/Spectra/EfficientDocumentation/activate-spectra.mp4';
import ActivateVideo from '../../../Assets/images/Spectra/EfficientDocumentation/activate-spectra.webm';

const ActivateSpectra = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>STEP 1</h6>
                        <h3 className='mb-3'>Activate Spectra</h3>
                        <p className='mb-0'>Click and drag to adjust the position of our sleek, floating widget on your device. Simply tap or use voice commands to enable and disable the mic.</p>
                    </div>
                </div>
                <div className='offset-md-1 col-12 col-md-10 col-lg-5'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="">
                            <video width="100%" id='videoActivate' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                                <source src={ActivateVideo} type="video/webm" />
                                <source src={ActivateVideoMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivateSpectra;