import React from 'react';
import {ReactComponent as AcceleratedGrowthIcone} from '../../../Assets/images/icons/accelerated-growth.svg';

const AcceleratedGrowth = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <AcceleratedGrowthIcone/>
            </div>
            <h3 className='mb-2'>Accelerated Growth</h3>
            <p className='mb-0'>Augnito’s dynamic team enables unparalleled exposure and development for individuals across specialties.</p>
        </div>
    );
};

export default AcceleratedGrowth;
