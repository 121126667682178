import React from 'react';
import clinicalDocumentation from '../../../Assets/images/Omni/ClinicianDesigned/clinical-documentation.webp';

const ClinicalDocumentationTab = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={clinicalDocumentation} loading="lazy" alt="Medical professionals collaborating on clinical documentation, reviewing x-rays and data." className="img-fluid" />
        </div>
    );
};

export default ClinicalDocumentationTab;