import React from 'react';
import {ReactComponent as ProvenROIIcone} from '../../../Assets/images/icons/competitive-compensation.svg';

const ProvenROI = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ProvenROIIcone/>
            </div>
            <h3 className='mb-2'>Proven ROI</h3>
            <p className='mb-0'>Spectra delivers a significant return on investment in just a short period of time; it has showcased the ability to generate a return 21 times over in just 6 months.</p>
        </div>
    );
};

export default ProvenROI;