import React from 'react';
import './WhySpectra.scss';
import EMRDocumentation from './EMRDocumentation';
import SeamlessInteroperability from './SeamlessInteroperability';
import CustomizableTemplates from './CustomizableTemplates';
import EnhancedProductivity from './EnhancedProductivity ';
import ClaimDenials from './ClaimDenials';
import BillingLevels from './BillingLevels';


const WhyOmni = () => {
    return (
        <section className='aug-why-spectra aug-augnito-difference'>
            <div className='container'>
                <h2 className='aug-career__title'>Why Omni?</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <EMRDocumentation/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <SeamlessInteroperability/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <CustomizableTemplates/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <EnhancedProductivity/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ClaimDenials/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <BillingLevels/>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default WhyOmni;
