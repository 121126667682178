import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/chevron-right-white.svg';
import ArrowLeft from '../../../Assets/images/icons/chevron-left-white.svg';
import {ReactComponent as Quote} from '../../../Assets/images/icons/quote.svg';
import './OurTeam.scss'

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize:'11px',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize:'11px',
        }}
        onClick={onClick}
      />
    );
  }

const OurTeam = () => {

    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 1,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        centerMode: true,
        className:'aug-our-team-slider',
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SampleNextArrow />,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
                centerMode: false,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                centerMode: false,
              }
            }
        ]
    };

    return (
       <section className='aug-our-team'>
            <div className='container'>
                <h2 className='aug-career__title'>Hear From Our Team</h2>
                <Slider {...settings}>
                    <div className='aug-our-team__items'>  
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Aman.webp')} alt="Aman Mehta" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                                <div className='aug-our-team__items-content-height'>
                                    <p className='mb-0'>Augnito embodies the true essence of a startup culture, fostering entrepreneurial spirit and hands-on learning. Working here has been an incredible journey, allowing me to explore human-centric technology and design while contributing to a product I genuinely believe in. It's not just a cutting-edge company; it's an incubator for personal and professional growth.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Aman Mehta, <span>Marketing</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Esra.webp')} alt="Esra Al Qarni" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                                <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>At Augnito, I’ve truly experienced the heart of startup culture. The environment promotes innovation and encourages hands-on learning, making every day an adventure. I’ve had the opportunity to dive into human-centric technology and design while being part of a product, I’m passionate about. Augnito isn’t just a forward-thinking company; it’s a space for both personal and professional growth.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Esra Al Qarni, <span>Sales</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Sam.webp')} alt="Kingsly Samuel" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>Rustom's inspiring vision, alongside the leadership team's guidance, has made this journey incredibly rewarding. It's a joy to see doctors and hospitals in GCC benefit from Augnito, and I cherish every moment of this experience. Collaborating with the efficient cross-functional Augnito team has been a highlight of my role.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Kingsly Samuel, <span>Regional CSM</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Gauri.webp')} alt="Gauri Sabnis" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>Joining Augnito has been an incredibly rewarding experience. The company's culture stands out for its collaborative spirit, where everyone is eager to help and learn. The fast-paced nature of the work here keeps me challenged, while the shared commitment to improving healthcare technology makes my job feel purposeful and impactful.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Gauri Sabnis, <span>Product</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Tejas.webp')} alt="Tejas Chhabra" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>Working at Augnito has been an incredibly rewarding experience. The company offers a fast-paced and exciting environment where innovation and growth are always at the forefront. From day one, I’ve felt supported by my colleagues and leadership, who are always willing to guide and provide valuable insights. The collaborative culture here ensures that no challenge feels insurmountable, and there’s always someone ready to help.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Tejas Chhabra, <span>Legal and Compliance</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Greeshma.webp')} alt="Greeshma S" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>Working at Augnito has been an inspiring journey where innovation meets inclusivity. As a female senior software developer, I've been empowered to push technical boundaries while contributing to impactful projects. The collaborative culture here not only values diverse perspectives but also fosters continuous learning and personal growth. It's a place where passion and purpose come together, making every day both challenging and rewarding.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Greeshma S, <span>Engineering</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className='aug-our-team__items'>
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Aishwarya.webp')} alt="Aishwarya" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>I really appreciate the freedom to experiment with new ideas and bring creativity to my work at Augnito. The culture here is all about 'Win and Learn'—either you succeed, or you gain valuable lessons that help you come back even stronger. Every achievement counts, and we always celebrate team wins!</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  Aishwarya, <span>HR</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </Slider>
            </div>
       </section>
    );
};

export default OurTeam;
