import React from 'react';
import './Footer.scss';
import FooterSPR from './FooterSPR';
import FooterInfo from './FooterInfo';
import FooterAr from './FooterAr';

import BaseComponent from '../../../Common/BaseComponent'

export function AddLibrary(url) {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    script.setAttribute("data-cookieyes", "cookieyes-analytics")
    document.body.appendChild(script);
}

export default class Footer extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        var pathname = window.location.pathname;
        var isArabicURL = false;
        if (pathname.toLowerCase().includes("/ar/")) {
            isArabicURL = true;
        }

        return (
            <>
                { isArabicURL ? <FooterAr /> :
                    <footer className='aug-footer'>
                        {/* {AddLibrary("//js.hs-scripts.com/9455047.js")} */}
                        <div className='aug-footer-wrap'>
                            <div className='container'>
                                <div className='aug-footer-wrap-content'>
                                    <FooterInfo />
                                </div>
                            </div>
                        </div>
                        <div className='aug-footer-copyright'>
                            <div className='container'>
                                <FooterSPR />
                            </div>
                        </div>
                    </footer>
                }
            </>
        );
    }
}