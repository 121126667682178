import React from 'react';
import './WeBelieve.scss';
import BaseComponent from '../../../Common/BaseComponent';
import PeopleAr from './PeopleAr';
import IntuitivenessAr from './IntuitivenessAr';
import PurposeAr from './PurposeAr';
import PrecisionAr from './PrecisionAr';
import IntegrityAr from './IntegrityAr';
import CustomerSuccessAr from './CustomerSuccessAr';

export default class WeBelieveAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section dir='rtl' className='aug-we-believe'>
                <div className='container'>
                    <h2 className='aug-career__title'>ما نؤمن به</h2>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <PeopleAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <IntuitivenessAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <PurposeAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <PrecisionAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <IntegrityAr/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <CustomerSuccessAr/>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}