import React from 'react';
import DynamicVideo from '../../../Assets/images/Spectra/ClinicianBuilt/dynamic-template.webm';
import DynamicVideoMp from '../../../Assets/images/Spectra/ClinicianBuilt/dynamic-template.mp4';

const DynamicTemplateTab = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <div className="">
                <video width="100%" id='videoDynamic' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                    <source src={DynamicVideo} type="video/webm" />
                    <source src={DynamicVideoMp} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default DynamicTemplateTab;