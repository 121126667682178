import React from 'react';
import {ReactComponent as PeopleIcone} from '../../../Assets/images/icons/people.svg';

const PeopleAr = () => {
    return (
        <div dir='rtl' className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <PeopleIcone/>
            </div>
            <h3 className='mb-0'>الأشخاص</h3>
            <p className='mb-0'>نؤمن بتبني نهج يضع الإنسان أولًا في كل ما نقوم به.سواء كان ذلك يُترجم إلى منتج يركز على الإنسان أو منظمة تركز على الموظف.</p>
        </div>
    );
};

export default PeopleAr;