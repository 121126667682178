import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import './BannerVideo.scss';
import ArrowRight from '../../../Assets/images/icons/chevron-right-white.svg';
import ArrowLeft from '../../../Assets/images/icons/chevron-left-white.svg';
import VideoOmniAr from '../../../Assets/images/home/omni-home-page.webm';
import VideoSpectraAr from '../../../Assets/images/home/spectra-home-page.webm';
import VideoSpectraArMp from '../../../Assets/images/home/spectra-home-page.mp4';
import VideoOmniArMp from '../../../Assets/images/home/omni-home-page.mp4';


function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            style={{
                backgroundImage: `url(${ArrowRight})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '25%',
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            style={{
                backgroundImage: `url(${ArrowLeft})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '25%',
            }}
            onClick={onClick}
        />
    );
}

const BannerVideoAr = () => {

    const [activeSlide, setActiveSlide] = useState(1);
    const gif1Ref = useRef(null);
    const gif2Ref = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 18000,
        className: 'aug-banner-video-slider',
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SampleNextArrow />,
        beforeChange: () => {
            // Pause and reset all videos before slide change
            if (gif1Ref.current) {
                gif1Ref.current.pause();
                gif1Ref.current.currentTime = 0;
                gif1Ref.current.load(); // Force reload to reset the video
            }
            if (gif2Ref.current) {
                gif2Ref.current.pause();
                gif2Ref.current.currentTime = 0;
                gif2Ref.current.load(); // Force reload to reset the video
            }
        },
        afterChange: (currentSlideIndex) => {
            // Update active slide
            setActiveSlide(currentSlideIndex);
        }
    };

    return (
        <section dir='rtl' className='aug-banner-video'>
            <div className='container'>
                <div className='row'>
                    <div className='mx-auto col-lg-9'>
                        <Slider {...settings}>
                            <div className='aug-banner-video-items px-md-5'>
                                <div className="">
                                    {activeSlide === 0 && (
                                        <video ref={gif1Ref} width="100%" playsInline muted height="auto" id='videoSpectraAr' className='aug-watch-video' autoPlay="autoPlay" preload="metadata" loop>
                                            <source src={VideoSpectraArMp} type="video/mp4" />
                                            <source src={VideoSpectraAr} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>)}
                                </div>
                            </div>
                            <div className='aug-banner-video-items px-md-4'>
                                <div className="">
                                    {activeSlide === 1 && (
                                        <video ref={gif2Ref} width="100%" playsInline muted height="auto" id='videoOmniAr' className='aug-watch-video' autoPlay="autoPlay" preload="metadata" loop>
                                            <source src={VideoOmniArMp} type="video/mp4" />
                                            <source src={VideoOmniAr} type="video/webm" />
                                            Your browser does not support the video tag.
                                        </video>)}
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerVideoAr;