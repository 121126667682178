import React from 'react';
import { ReactComponent as CustomerIcone } from '../../../Assets/images/icons/customer-success.svg';

const CustomerSuccessContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                <CustomerIcone /> تعزيز نجاح العملاء
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            المدفوع بالتحليلات العميقة والنتائج المُقاسة، لضمان فوائد ملموسة للعملاء على مستوى المؤسسات. تيسير التكيف والانتقال والتدريب والرعاية دون عناء - لتحويل الأطباء إلى أكبر داعمينا.
            </div>
        </div>
    );
};

export default CustomerSuccessContentAr;