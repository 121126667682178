import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import SubscriptionTermsMeta from './SubscriptionTermsMeta';

export default class SubscriptionTerms extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <SubscriptionTermsMeta />
                <section className='aug-legal-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 offset-lg-1 col-lg-10'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Augnito Subscription Terms</h1>
                                    <p className='mb-0'>Effective as of 28th October 2024.  </p>
                                </div>

                                <div className='mb-5'>
                                    <p className='mb-0'>
                                        These Terms replace and supersede all prior versions. This document contains additional
                                        terms to the General Terms of Use which will apply depending upon the Subscription Term
                                        and the plan availed by the User.
                                    </p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>Definitions</h3>
                                    <p className='mb-3'>In this Agreement, unless otherwise stated or unless the context otherwise requires, the words
                                        and expressions beginning with capital letters (other than clause headings) shall have the
                                        meaning set out below: </p>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.</strong>
                                        <p className='mb-0'><strong>"User" </strong> means an individual authorized by Augnito to use and or sell the Products
                                            including, Enterprise Clients, Authorized Partners, Distributors, Resellers and End
                                            Users.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.</strong>
                                        <p className='mb-0'><strong>"End User" </strong> means an Individual or Company who is the eventual User of the
                                            Product ,which may be purchased directly with Augnito or through a Authorized
                                            Partner, Distributor or Reseller. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>3.</strong>
                                        <p className='mb-0'><strong>"Authorized Partners" </strong> means independent contractors including Users who
                                            integrate our Product and sell further. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>4.</strong>
                                        <p className='mb-0'><strong>"Enterprise Clients" </strong>means Companies/Organization/Hospitals who purchase our
                                            Products. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>5.</strong>
                                        <p className='mb-0'><strong>"Products"</strong> means all products offered by Augnito including Augnito Spectra and
                                            Augnito Voice Services. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>6.</strong>
                                        <p className='mb-0'><strong>"Subscription Term"</strong> means the period for which the Product services are availed,
                                            which will in turn depend upon the Subscription Plan availed by the User. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>7.</strong>
                                        <p className='mb-0'><strong>"Subscription Terms"</strong> means additional terms which will apply depending upon
                                            the Subscription Term. </p>
                                    </div>
                                    <div className='d-flex'>
                                        <strong className='me-2'>8.</strong>
                                        <p className='mb-0'><strong>"Subscription Plan"</strong> also referred to as “Plan” means the plan available with the
                                            Product including but not limited to Subscription Term, refund, renewal and
                                            cancellation. </p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'>The available plans and their Subscription Terms are as below:</h3>
                                    <div className='aug-legal-page-subtitle'>
                                        <span className='me-1'>1.</span>
                                        Augnito Spectra is available with the following plans:
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.1.</strong>
                                        <strong className='mb-0 fw-semibold'> Professional Plan</strong>
                                    </div>

                                    <div className='mb-4'>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>1.1.1.</strong>
                                                <p className='mb-0'>
                                                    Your subscription shall be effective /start on the date the payment made by you is
                                                    processed and realized. It shall continue for the period for which payment is made, which
                                                    maybe monthly/quarterly/annually.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.1.2.</strong>
                                                <p className='mb-0'>
                                                    Your subscription can be cancelled at the end of your billing cycle. Please ensure to
                                                    cancel your Subscription at the end of the billing cycle if you don’t want to renew for the next
                                                    billing cycle.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.1.3.</strong>
                                                <p className='mb-0'>
                                                    Your Subscription/s will not be automatically renewed. Plan rates are subject to change
                                                    at Augnito India Private Ltd.’s discretion. In case of such a change, you will be duly notified
                                                    with an option to either continue or cancel.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.1.4.</strong>
                                                <p className='mb-0'>
                                                    In case of tax inclusive plans, if the applicable VAT or GST rate (or other included tax
                                                    or duty) changes during your billing term, we will accordingly adjust the tax-inclusive price
                                                    for your plan on your next billing date.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.1.5.</strong>
                                                <p className='mb-0'>
                                                    You can cancel your subscription anytime through the product. Please note that
                                                    Subscription fee for the remaining billing period upon cancellation request being raised is
                                                    non-refundable. Subscription of the Product availed by you will continue until the end of that
                                                    billing period.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='mb-4'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>1.2.</strong>
                                            <strong className='mb-0 fw-semibold'> Long Term Membership Plan previously known as LifetimeSaas: </strong>
                                        </div>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>1.2.1.</strong>
                                                <p className='mb-0'>
                                                    Your subscription shall be effective /start on the date the upfront payment made by you
                                                    in favor of Augnito is processed and realized. In addition to the upfront Subscription Fee, the
                                                    User is required to pay Annual Service Fee (ASF).
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.2.2.</strong>
                                                <p className='mb-0'>
                                                    Subject to the payment of Annual Service Fee (ASF) by the User, the Subscription shall
                                                    continue for a maximum period of 7 years. Augnito holds the right to suspend/cancel
                                                    subscription in case of non-realisation of ASF.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.2.3.</strong>
                                                <p className='mb-0'>
                                                    Augnito holds the right to continue or revise the Subscription Plan depending upon the
                                                    then available plans. It is hereinafter clarified continuance or revision of Subscription shall be
                                                    aligned based on Augnito’s sole discretion.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.2.4.</strong>
                                                <p className='mb-0'>
                                                    Please note this Long Term Membership Plan will be formally discontinued on or
                                                    around March 31, 2025 ("Discontinuation Date"). Following the Discontinuation Date, no
                                                    new subscriptions to this plan shall be accepted, and all specific terms and conditions related
                                                    to this plan shall cease to apply. Existing Users will retain their access only until the
                                                    Termination Date and shall not receive further extensions. In recognition of the
                                                    Discontinuation Date, Augnito may, at its discretion, offer current Users of the Long-Term
                                                    Membership Plan certain promotional discounts or incentives to migrate to alternative
                                                    subscription plans. By accepting any such discount offer, the User explicitly agrees to waive
                                                    and relinquish any and all rights to pursue claims, disputes, or legal remedies related to the
                                                    cessation of the Long-Term Membership Plan. Acceptance of the discount shall constitute the
                                                    User’s express acknowledgment of, and agreement to, the termination of the Long Term
                                                    Membership Plan and all associated rights in consideration of the exclusive discount
                                                    extended by Augnito.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='mb-4'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>1.3.</strong>
                                            <strong className='mb-0 fw-semibold'>Augnito Spectra Enterprise Plan: </strong>
                                        </div>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>1.3.1.</strong>
                                                <p className='mb-0'>
                                                    Subscription Terms for the Enterprise including Subscription Term, Effective date,
                                                    refund, cancellation etc. will be defined and governed by the Agreement concluded between
                                                    Augnito and the Enterprise Client.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='mb-0'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>1.4.</strong>
                                            <strong className='mb-0 fw-semibold'>Augnito Spectra (Only App) </strong>
                                        </div>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>1.4.1.</strong>
                                                <p className='mb-0'>
                                                    Your subscription is governed by In App Purchase policy of Google PlayStore or iOS
                                                    Appstore.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.4.2.</strong>
                                                <p className='mb-0'>
                                                    Subscription purchased on one mobile platform cannot be used on any other mobile
                                                    platform. Subscription will be auto-renewed Google PlayStore or iOS Appstore, unless
                                                    cancelled. Please note that the Augnito Spectra App is subject to the in-app purchase policy of
                                                    Google PlayStore and iOS Appstore. Accordingly like every other app hosted on these
                                                    platforms are subject to auto renewals. Augnito is not liable to refund any amount debited
                                                    from the User on account of auto renewal, Users are requested to cancel their subscription at
                                                    the end of the billing cycle if they do not intend to renew.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.4.3.</strong>
                                                <p className='mb-0'>
                                                    Please note your subscription fee/payment made is non-refundable. You can cancel your
                                                    subscription anytime through the product. Please note that Subscription fee for the remaining
                                                    billing period upon cancellation request being raised is non-refundable. Product Subscription
                                                    availed by you will continue until the end of that billing period.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.4.4.</strong>
                                                <p className='mb-0'>
                                                    Plan rates are subject to change at Augnito India Private Ltd.’s discretion, in case of
                                                    such a change, you will be duly notified with an option to either continue or cancel.
                                                </p>
                                            </li>
                                            <li>
                                                <strong>1.4.5.</strong>
                                                <p className='mb-0'>
                                                    In case of tax inclusive plans if the applicable VAT or GST rate (or other included tax or
                                                    duty) changes during your billing term, we will accordingly adjust the tax-inclusive price for
                                                    your plan on your next billing date.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span>2. </span>Augnito Voice Services</h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>2.1.</strong>
                                        <p className='mb-0'> Augnito Subscription Terms including but not limited to Subscription Term, Subscription
                                            Terms, Effective date, refund, cancellation will be defined and governed by the Agreement
                                            concluded between Augnito and the User.</p>
                                    </div>
                                </div>

                                <div>
                                    <strong>Resellers / Distributors</strong>
                                    <p className='mb-0 mt-3'>Customers who purchase any of the Products indirectly through resellers/distributors are
                                        liable to check the above Subscription Terms which apply to the Plan availed by them with
                                        the Reseller/Distributors. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}