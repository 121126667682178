import React from 'react';
import BaseComponent  from '../../Common/BaseComponent';
import HomeMetaAr from './Meta/HomeMetaAr';
import BannerAr from './Banner/BannerAr';
import CustomerSayAr from './CustomerSay/CustomerSayAr';
import BannerVideoAr from './BannerVideo/BannerVideoAr';
import OurPartnerAr from './OurPartner/OurPartnerAr';
import ConversationsClinicalAr from './ConversationsClinical/ConversationsClinicalAr';
import PerfectSolutionAr from './PerfectSolution/PerfectSolutionAr';
import SecureDesignAr from './SecureDesign/SecureDesignAr';
import LeadingPlatformsAr from './LeadingPlatforms/LeadingPlatformsAr';
import UniversalAccessibilityAr from './UniversalAccessibility/UniversalAccessibilityAr';
import OurSolutionAr from './OurSolution/OurSolutionAr';
import EmpoweringCliniciansAr from './EmpoweringClinicians/EmpoweringCliniciansAr';


export default class HomeAr extends BaseComponent {
    
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <HomeMetaAr/>
                <BannerAr/>
                <BannerVideoAr/>
                <OurPartnerAr/>
                <ConversationsClinicalAr/>
                <CustomerSayAr/>
                <EmpoweringCliniciansAr/>
                <OurSolutionAr/>
                <UniversalAccessibilityAr/>
                <LeadingPlatformsAr/>
                <SecureDesignAr/>
                <PerfectSolutionAr/>
            </main>
        );
    }
}
