import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import GlobalLanguageTab from './GlobalLanguageTab';
import GlobalLanguageContent from './GlobalLanguageContent';
import ClinicalDocumentationTab from './ClinicalDocumentationTab';
import ClinicalDocumentationContent from './ClinicalDocumentationContent';
import CodeRetrievalTab from './CodeRetrievalTab';
import AIDrivenTab from './AIDrivenTab';
import AIDrivenContent from './AIDrivenContent';
import EasyIntegrationsContent from './EasyIntegrationsContent';
import EasyIntegrationsTab from './EasyIntegrationsTab';
import CodeRetrievalContent from './CodeRetrievalContent';
import ClinicianDesignedMobile from './ClinicianDesignedMobile';

export default class ClinicianDesigned extends BaseComponent {
    
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-empowering-clinicians pt-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h2 className='aug-career__title'>Clinician-Designed & Purpose-Built</h2>
                        </div>
                    </div>
                    <div className='aug-empowering-clinicians__tab'>
                        <div className='d-none d-lg-block'>
                            <div className='row gx-80'>
                                <div className='col-lg-6'>
                                    <div className="tab-content" id="clinicianDesigned-tabContent">
                                        <div className="tab-pane fade show active" id="globalLanguage" role="tabpanel" aria-labelledby="globalLanguage-tab" tabIndex="0">
                                            <GlobalLanguageTab/>
                                        </div>
                                        <div className="tab-pane fade" id="clinicalDocumentation" role="tabpanel" aria-labelledby="clinicalDocumentation-tab" tabIndex="0">
                                            <ClinicalDocumentationTab/>
                                        </div>
                                        <div className="tab-pane fade" id="codeRetrieval" role="tabpanel" aria-labelledby="codeRetrieval-tab" tabIndex="0">
                                            <CodeRetrievalTab/>
                                        </div>
                                        <div className="tab-pane fade" id="simplifiedDeployment" role="tabpanel" aria-labelledby="simplifiedDeployment-tab" tabIndex="0">
                                            <AIDrivenTab/>
                                        </div>
                                        <div className="tab-pane fade" id="easyIntegrations" role="tabpanel" aria-labelledby="easyIntegrations-tab" tabIndex="0">
                                            <EasyIntegrationsTab/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="nav flex-column nav-pills" id="clinicianDesigned-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link mt-0 active" id="globalLanguage-tab" data-bs-toggle="pill" data-bs-target="#globalLanguage" type="button" role="tab" aria-controls="globalLanguage" aria-selected="true">
                                            <GlobalLanguageContent/>
                                        </button>
                                        <button className="nav-link" id="clinicalDocumentation-tab" data-bs-toggle="pill" data-bs-target="#clinicalDocumentation" type="button" role="tab" aria-controls="clinicalDocumentation" aria-selected="false">
                                            <ClinicalDocumentationContent/>
                                        </button>

                                        <button className="nav-link" id="codeRetrieval-tab" data-bs-toggle="pill" data-bs-target="#codeRetrieval" type="button" role="tab" aria-controls="codeRetrieval" aria-selected="false">
                                            <CodeRetrievalContent/>
                                        </button>
                                        <button className="nav-link" id="simplifiedDeployment-tab" data-bs-toggle="pill" data-bs-target="#simplifiedDeployment" type="button" role="tab" aria-controls="simplifiedDeployment" aria-selected="false">
                                            <AIDrivenContent/>
                                        </button>
                                        <button className="nav-link" id="easyIntegrations-tab" data-bs-toggle="pill" data-bs-target="#easyIntegrations" type="button" role="tab" aria-controls="easyIntegrations" aria-selected="false">
                                            <EasyIntegrationsContent/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                           <ClinicianDesignedMobile/>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};