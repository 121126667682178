import React from 'react';
import OfficesImg from '../../../Assets/images/ContactUs/our-offices/our-offices.webp';
import Bengaluru from './Bengaluru';
import USA from './USA';
import London from './London';
import Mumbai from './Mumbai';
import './OurOffices.scss'
import Dubai from './Dubai';
import Riyadh from './Riyadh';

const OurOffices = () => {
    return (
        <section className='aug-our-offices'>
            <div className='container'>
                <h2 className='aug-our-offices__title'>Our Offices Around the World</h2>
                <div className='row justify-content-center'>
                    <div className='col-lg-11 col-md-12'>
                        <img src={OfficesImg} alt="World map showing locations of Augnito offices around the globe" className='w-100 img-fluid' />
                        <div className="row gx-80">
                            <div className="col-12 col-md-6">
                                <Mumbai />
                            </div>
                            <div className="col-12 col-md-6">
                                <Bengaluru />
                            </div>
                            <div className="col-12 col-md-6">
                                <London />
                            </div>
                            <div className="col-12 col-md-6">
                                <USA />
                            </div>
                            <div className="col-12 col-md-6">
                                <Dubai />
                            </div>
                            <div className="col-12 col-md-6">
                                <Riyadh />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurOffices;