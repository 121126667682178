import React from 'react';
import './FAQ.scss';
import AugnitoCost from './AugnitoCost';
import AugnitoSubscription from './AugnitoSubscription';
import FreeTrial from './FreeTrial';
import AugnitoPricing from './AugnitoPricing';
import ImplementationCost from './ImplementationCost';
// import ROI from './ROI';

const FAQ = () => {
    return (
       <section className='aug-faq'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='aug-faq-title mb-60 mt-60'>Frequently asked questions</h2>
                        <div className="accordion aug-accordion" id="faq">
                            <AugnitoCost/>
                            <AugnitoSubscription/>
                            <FreeTrial/>
                            <AugnitoPricing/>
                            <ImplementationCost/>
                            {/* <ROI/> */}
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default FAQ;