import React from 'react';
import Pattren from '../../../Assets/images/Pattrens/footer.webp';
import { NavLink } from 'react-router-dom';

const DiscoverOmni = () => {

    return (
       <section className='aug-try-spectra aug-perfec-solution mt-0'>
            <div className='container'>
                <div className='aug-perfec-solution-wrap'>
                    <div className='row align-items-center'>
                        <div className='col-lg-8 col-md-12 col-12'>
                            <div className='aug-perfec-solution-box'>
                                <h2 className='mb-3'>Discover Omni.</h2>
                                <p>No credit card required. <br />
                                No implementation and training fees.</p>
                                <NavLink to="/contact-sales" className="btn btn-primary">Talk to an Expert</NavLink>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12'>
                            <div className='aug-perfec-solution__img'>
                                <img src={Pattren} alt="pattern" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default DiscoverOmni;