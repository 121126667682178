import React from 'react';
import { ReactComponent as MacrosIconeIcone } from '../../../Assets/images/icons/book-line.svg';

const CustomVocabularyContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <MacrosIconeIcone /> Personalization with Custom Vocabulary
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
                Optimize the system to your specific needs by filling in details like preferred spellings and pronunciations of specialty-specific terms, acronyms, drug names, and more, to ensure more accurate and relevant transcription. 
            </div>
        </div>
    );
};

export default CustomVocabularyContent;