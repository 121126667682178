import React from 'react';
import HeroLeft from '../../../Assets/images/Pattrens/hero-left.webp';
import HeroRight from '../../../Assets/images/Pattrens/hero-right.webp';
import './Banner.scss';
import {ReactComponent as Star} from '../../../Assets/images/icons/stars.svg';
import { NavLink } from 'react-router-dom';

const Banner = () => {

    return (
        <section className='container aug-banner'>
            <div className='aug-banner-img'>
                <img src={HeroLeft} alt="banner pattrens" className='img-fluid'/>
            </div>

            <div className="aug-banner-content text-center">
                <h1 className='mb-3'>
                    The Optimal AI Assistant for Healthcare
                    <Star className='ms-2'/>
                </h1>
                <p>Enterprise-grade clinical documentation solutions, trusted by clinicians across specialties.</p>
                <NavLink to="/contact-sales" className='btn btn-primary'>Request a Demo</NavLink>
            </div>

            <div className='aug-banner-img'>
                <img src={HeroRight} alt="banner pattrens" className='img-fluid'/>
            </div>
        </section>
    );
};


export default Banner;