import React from 'react';
import Slider from "react-slick";
 
const OurPartnerLogoIN = () => {
    const settings = {
        dots: false,
        autoplay:true,
        autoplaySpeed: 0,
        variableWidth: true,
        centerMode: true,
        infinite: true,
        slidesToShow: 6,
        pauseOnHover: false,
        slidesToScroll: 1,
        speed: 4000,
        arrows:false,
        cssEase: 'linear',
        className:'aug-our-partner-slider',
        responsive: [
            {
              breakpoint: 991,
               settings: {
                 slidesToShow: 3
              }
            },
            {
               breakpoint: 575,
               settings: {
                slidesToShow: 2
              }
            }
        ]
    };

    return (
        <Slider {...settings}>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/apollo-1.webp')} alt="apollo logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/medanta-1.webp')} alt="medanta logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/fortis-1.webp')} alt="fortis logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/ge-1.webp')} alt="ge logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/narayana-1.webp')} alt="narayana logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/manipal-1.webp')} alt="manipal logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/max-1.webp')} alt="max logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/aster-1.webp')} alt="aster logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/fujifilm-1.webp')} alt="fujifilm logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/hcg-1.webp')} alt="hcg logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/hinduja-1.webp')} alt="hinduja logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/lilavati-1.webp')} alt="lilavati logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/prime-1.webp')} alt="prime logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/IN/bahrain-1.webp')} alt="bahrain logo" className="img-fluid"/>
            </div>
        </Slider> 
    );
};

export default OurPartnerLogoIN;
