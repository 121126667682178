import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import SoftwareLicenseMeta from './SoftwareLicenseMeta';

export default class SoftwareLicense extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <SoftwareLicenseMeta />
                <section className='aug-legal-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 offset-lg-1 col-lg-10'>
                                <div className='aug-legal-page-header'>
                                    <div className='row'>
                                        <div className='col-lg-9'>
                                            <h1 className='aug-legal-page-title mb-3'>Software License Terms</h1>
                                        </div>
                                        <div className='col-lg-3'>
                                            <select class="form-select">
                                                <option defaultValue>Version 1.0 (current)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p className='mb-0'>These Software License Terms (“<strong>Terms</strong>”), along with the Order Form and the Software License Agreement (“<strong>Agreement</strong>”) and similar documents (including any other agreements) executed between Augnito India Private Limited (“<strong>Company</strong>” or “<strong>Augnito</strong>” or “<strong>AIPL</strong>”) and you (the “<strong>Customer</strong>”) shall be effective as of the Effective Date as set forth in the Order Form.  These Terms form an electronic record under the provisions of the Information Technology Act, 2000 and rules framed there under, including the Information Technology (Intermediaries Guidelines) Rules, 2011 (as applicable and as amended from time to time). The use of the Services is available only to persons competent to contract under the applicable laws and all person’s incompetent to contract, including minors, are ineligible to use the Services. </p>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>1.</span>Definitions </h3>
                                    <p className='mb-4'>In these Terms, unless otherwise stated or unless the context otherwise requires, the words and expressions beginning with capital letters (other than clause headings) shall have the meaning set out below:</p>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.1. </strong>
                                        <p className='mb-0'>“<strong>Affiliate</strong>”  means any entity that directly or indirectly Controls, is Controlled by, or is under common Control of the subject entity. “Control” for purposes hereof, means direct or indirect ownership or control of more than 50% (Fifty Percent) of the voting interests of the subject entity.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.2. </strong>
                                        <p className='mb-0'>“<strong>Aggregated Statistics</strong>”  means data and information related to Customer’s use of Services, which is used by AIPL in an aggregate and anonymized manner, including complying statistical and performance information related to the provision and operation of the Services.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.3. </strong>
                                        <p className='mb-0'>“<strong>Authorized Contractors</strong>”  means independent contractors, licensors or subcontractors.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.4. </strong>
                                        <p className='mb-0'>“<strong>AIPL Technology</strong>”  means AIPL proprietary software, technology, frameworks, platforms, methodologies, facilitation guides, techniques, general purpose consulting and related know-how, logic, coherence and methods of operation of systems, user interfaces, screen designs, presentation materials, and best-practices documentation, including any enhancements, modifications or derivatives thereof, which are provided to Customer.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.5. </strong>
                                        <p className='mb-0'>“<strong>Customer Data</strong>”  means all the data, records, files, images, graphics, audio, video, photographs, reports, forms and other content and material, in any format, that are submitted, stored, posted, displayed, transmitted or otherwise used with the Software. For avoidance of doubt, Customer Data does not include Resultant Data or any other information reflecting the access or use of Augnito by or on behalf of Customer or any Authorized User. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.6. </strong>
                                        <p className='mb-0'>“<strong>Documentation</strong>”  means AIPL’s product guides and other end user documentation for the Software made available to the Customer, as may be updated by AIPL in its sole direction, from time to time to reflect the functionality of the Software. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.7. </strong>
                                        <p className='mb-0'>“<strong>Order</strong>”  or “<strong>Order Form</strong>”  means an ordering document or online order specifying the licensing details of the Software, to be provided hereunder that is entered into between AIPL and Customer from time to time, including any addenda and supplements thereto. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.8. </strong>
                                        <p className='mb-0'>“<strong>Resultant Data</strong>”  means data and information related to Customer’s use of Augnito that is used by AIPL: (a) to train its proprietary AI technology to learn and produce sophisticated results, and (b) in an aggregate and anonymized manner, including to compile Aggregated Statistics. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.9. </strong>
                                        <p className='mb-0'>“<strong>Software</strong>”  or “<strong>Services</strong>”  shall mean the software or services being offered to the Customer as more particularly specified in the Order Form.</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>1.10. </strong>
                                        <p className='mb-0'>“<strong>License Term</strong>”  means the term of Software license availed by Customer which shall commence on the start date specified in the applicable Order and continue for the License Term specified therein and any renewals thereto.</p>
                                    </div>
                                    <div className='d-flex'>
                                        <strong className='me-2'>1.11. </strong>
                                        <p className='mb-0'>“<strong>User</strong>”  means an individual authorized by Customer to use the Software including, but not limited to, employees, consultants, contractors and third parties whom the Customer has supplied with a specific user identification.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>2.</span>Terms Of Software License </h3>
                                    <div className='mb-4'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>2.1. </strong>
                                            <strong>Grant of Software License </strong>
                                        </div>
                                        <ul className='mb-0 list-unstyled'>
                                            <li>
                                                <strong>2.1.1. </strong>
                                                <p className='mb-0'>Subject at all times to Customer’s continuing compliance with these Terms, the Agreement and all Orders, including all payment terms, AIPL grants to the Customer a non-exclusive, non-transferable, non-sublicensable, limited license during the License Term to: (a) download and install the Software on the number of servers indicated in the Order, provided that such servers must be owned, leased, or otherwise controlled by Customer; and (b) access and use, and allow Users to access and use, the Software solely for Customer’s internal business purposes only.</p>
                                            </li>
                                            <li>
                                                <strong>2.1.2. </strong>
                                                <p className='mb-0'>In addition to Customer’s other obligations and responsibilities set forth in the Agreement, Customer shall: (a) be responsible for all acts and omissions of Users in connection with their use of the Software; and (b) notify AIPL immediately in the event the Customer becomes aware of any unauthorized use of or access to the Software. In addition, Customer is solely responsible for obtaining and maintaining all computer hardware and other equipment needed to access or use the Software, and all related charges. </p>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='mb-4'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>2.2. </strong>
                                            <strong>Use Limits and Restrictions</strong>
                                        </div>
                                        <p className='mb-0'>Customer shall not: (a) copy the Software or Documentation, except as expressly permitted in the Agreement; (b) rent, lease, distribute, sell, sublicense, or transfer to any third party all or any part of the Customer’s right to access and use the Software; (c) reverse engineer, disassemble or decompile the Software; (d) modify or create derivative works of the Software without the prior written consent of AIPL; (e) access or use the Software for unlawful purposes; (f) install the Software on servers that are not owned, leased, or otherwise controlled by Customer; or (g) permit or assist anyone else to do any of the preceding. </p>
                                    </div>

                                    <div className='mb-4'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>2.3. </strong>
                                            <strong>Customer Covenants and Obligations</strong>
                                        </div>
                                        <p className='mb-0'>Customer agrees and undertakes to: (a) access and use the Services in accordance with the Agreement, applicable laws and government regulations; (b) use commercially reasonable efforts to prevent unauthorized access to AIPL Technology or use of the Services and notify AIPL promptly of any such unauthorized access or use. The Customer shall not and shall ensure that the Services and AIPL Technology are not used for any acts that are illegal and/or unlawful and/or malicious and/or any other activities whatsoever that AIPL deems to be improper in its sole judgment, including but not limited to the following, and any defiance in compliance will entitle AIPL to take such technical and/or legal remedies as it may deem necessary or fit, including but not limited to termination of the Agreement and suspending usage of the Services: a. impersonating any person and/or entity or misrepresenting himself/herself/itself; b. providing any information that is inaccurate, misleading and/or fraudulent; c. preventing AIPL from authenticating the information provided by the User; d. access and/or use (and/or attempt to access or use) the account and/or login credentials of any other User; e. transmit any software and/or any other media that contain any viruses, worms, trojan horses, defects, or other such destructive / malicious software; f. infringe upon the intellectual property in any manner whatsoever, including but not limited to modifying and/or adapting and/or decompiling any portion of the AIPL Technology; g. act in a manner that is fraudulent and/or otherwise illegal; and/or h. facilitate and/or encourage any of the aforesaid acts. </p>
                                    </div>

                                    <div className='mb-0'>
                                        <div className='d-flex mb-3'>
                                            <strong className='me-2'>2.4. </strong>
                                            <strong>Access to User’s system</strong>
                                        </div>
                                        <p className='mb-0'>Where applicable, AIPL shall always be provided remote access to the User’s system via a secure VPN connection until termination of the Agreement. The Customer shall ensure that AIPL and its personnel are granted sufficient rights and privileges to troubleshoot, update, install and uninstall the Software through the VPN connection. AIPL shall be entitled to terminate the Agreement without further notice if the Customer fails to maintain the VPN connection for a continuous period of at least 48 (forty-eight) hours at any time during the term of the Agreement. In case of such termination, the User shall also allow AIPL to, within 24 (twenty four) hours from receipt of written request to this effect from AIPL, physically access the User’s system to uninstall and remove the Software from its systems. </p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>3.</span> Fees and Payment</h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>3.1. </strong>
                                            <strong> Fees </strong>
                                        </div>
                                        <p className='mb-0'> Customer shall pay the license fees specified in the Order Form and any applicable additional fees if Customer exceeds the allotted capacity and/ or other applicable limits specified in the Order. Except as otherwise specified herein or in an Order: (a) fees are payable in Indian Rupees; (b) fees are based on the license availed, and not actual usage; (c) payment obligations are non-cancelable and fees paid is non-refundable; (d) all Software shall be deemed accepted upon delivery; and (e) payment of license fee is not dependent on the delivery of any future functionality. Customer shall reimburse AIPL for out-of-pocket expenses incurred by AIPL in connection with provision of the Software. AIPL will provide Customer with reasonably detailed invoices for such expenses. All amounts payable under rhe Agreement will be made without setoff or counterclaim.</p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>3.2. </strong>
                                            <strong> Invoicing and Payment </strong>
                                        </div>
                                        <p className='mb-0'>Unless otherwise specified in an Order, fees will be invoiced annually in advance. Except as otherwise stated in the applicable Order, Customer shall pay all invoiced amounts within 7 (seven) days of invoice date. If Customer fails to pay any amounts due under the Agreement by the due date, in addition to any other rights or remedies it may have under the Agreement or by matter of law: (a) AIPL reserves the right revoke the license to the Software as set out in Clause 2.1 above,  until such amounts are paid in full; and (b) AIPL will have the right to charge a late payment fee at a rate equal to the lesser of 1.5% (one point five percent) per month until Customer pays all amounts due.</p>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>3.3. </strong>
                                            <strong> Taxes </strong>
                                        </div>
                                        <p className='mb-0'>All fees for Services exclude applicable taxes, including sales tax, value-added tax, goods and services tax, and other indirect taxes or duties (collectively “Taxes”) related to the sale, delivery, or use of any product or Services. Unless Customer provides a valid, signed certificate or letter of exemption for each respective jurisdiction of its tax-exempt status, Customer is responsible for paying all applicable taxes related to AIPL's provision of the Services, except for taxes on AIPL’s net income. If AIPL pays or collects taxes on behalf of the Customer, the Customer shall reimburse AIPL promptly. If the Customer withholds taxes at source, it shall remit such taxes to the government as required and provide AIPL with the appropriate tax deduction certificate within the legally prescribed timelines.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>4.</span> Proprietary Right</h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>4.1. </strong>
                                            <strong> Ownership of Rights </strong>
                                        </div>
                                        <p className='mb-0'> Except for the rights expressly granted under the Agreement, AIPL and its licensors retain all right, title and interest in and to the Services, AIPL Technology and Documentation, including all related intellectual property rights therein. AIPL reserves all rights in and to the Services, AIPL Technology and Documentation not expressly granted to Customer under the Agreement. Customer acknowledges that, as between Customer and AIPL, AIPL owns all rights, title, and interest, including all intellectual property rights, in and to the AIPL Technology.</p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>4.2. </strong>
                                            <strong> Ownership of Customer Data  </strong>
                                        </div>
                                        <p className='mb-0'>As between Customer and AIPL, Customer is and will remain the sole and exclusive owner of all right, title and interest to all Customer Data, including any intellectual property rights therein. Customer grants AIPL and its Affiliates the rights to host, use, process, store, display, and transmit Customer Data as necessary to provide the Services, in accordance with the Agreement and AIPL’s Privacy Policy [https://augnito.ai/legal/augnito-privacy-policy]. Customer warrants that it has all necessary rights to permit AIPL to compile, use, and transfer aggregated Customer Data for internal and marketing purposes, provided such use does not identify Customer or its customers.  If Customer, its employees, and/or any Authorized Users send or transmit any communications or material to AIPL by mail, email, telephone, or otherwise, suggesting or recommending changes to the AIPL Technology, including new features or functionality relating thereto, or any comments, questions, suggestions, or the like (“Feedback”), with prior approval, AIPL may use such Feedback.</p>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>4.3. </strong>
                                            <strong> Publicity Rights </strong>
                                        </div>
                                        <p className='mb-0'>We may identify you as Augnito’s User in our promotional materials. We will promptly stop doing so upon your request sent to <a rel="noreferrer" target="_blank" href="mailto: marketing@augnito.ai">marketing@augnito.ai</a></p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>5.</span> Confidentiality </h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>5.1. </strong>
                                            <strong> Definition of Confidential Information:  </strong>
                                        </div>
                                        <p className='mb-0'> “Confidential Information” means all confidential or proprietary information of a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”), whether orally or in writing, that is designated as confidential or reasonably should be understood to be confidential given the nature of information and the circumstances of disclosure. Without limiting the coverage of these confidentiality obligations, the Parties acknowledge and agree that Confidential Information of each party shall include the terms and conditions of the Agreement (including pricing and other terms set forth in all Order Forms issued hereunder), related benchmark or similar test results, other technology and technical information, security information, security audit reports, and business and marketing plans, except that AIPL may reference and use Customer’s name, logos and the nature of the Services provided hereunder in AIPL’s business development and marketing efforts.</p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>5.2. </strong>
                                            <strong> Exceptions and Lawful Disclosure:  </strong>
                                        </div>
                                        <p className='mb-0'>Confidential Information shall not include information that: (a) is or becomes publicly available without a breach of any obligation owed to the Disclosing Party, (b) is already known to the Receiving Party at the time of its disclosure by the Disclosing Party, without a breach of any obligation (c) following its disclosure to the Receiving Party, is received by the Receiving Party from a third party without breach of any obligation owed to Disclosing Party, or (d) is independently developed by Receiving Party without reference to or use of the Disclosing Party’s Confidential Information. The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent required by applicable law, regulation or legal process, provided that the Receiving Party: (a) provides prompt written notice to the extent legally permitted; (b) provides reasonable assistance, at Disclosing Party’s cost, in the event the Disclosing Party wishes to oppose the disclosure; & (c) limits disclosure to that required by the applicable laws or regulations.</p>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>5.3. </strong>
                                            <strong> Protection of Confidential Information: </strong>
                                        </div>
                                        <p className='mb-0'>The Receiving Party shall use the same degree of care used to protect the confidentiality of its own Confidential Information of like kind (but in no event less than reasonable care), and, except with Disclosing Party’s written consent, shall: (a) not use any Confidential Information of Disclosing Party for any purpose outside the scope of the Agreement and (b) limit access to Confidential Information of Disclosing Party to those of its, its Authorized Contractors, and Affiliates’ employees, contractors and agents who need such access for purposes consistent with the Agreement and who have a duty or obligation of confidentiality no less stringent than those set forth herein.</p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>6. </span>Privacy and Information Security.  </h3>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>6.1. </strong>
                                        <p className='mb-0'><strong>Customer  Representations: </strong> The Customer represents and warrants that: (a) it owns all Customer Data or has collected it in compliance with Laws, including obtaining necessary consents; and (b) it will comply with all applicable laws and regulations to protect Customer Data, including Personal Information of end users</p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>6.2. </strong>
                                        <p className='mb-0'><strong>Customer – Privacy and Security: </strong>  Customer shall comply with all applicable laws that apply to its use of Services (collectively, “Privacy Laws”). The Customer is responsible for the security of Customer Data provided to AIPL. Customer shall employ all physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all access credentials and protect against any unauthorized access to or use of Services; and (b) control the use of Customer Data. </p>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <strong className='me-2'>6.3. </strong>
                                        <p className='mb-0'><strong>AIPL – Privacy and Security: </strong> AIPL shall maintain commercially reasonable administrative, physical, and technical safeguards to help protect the security, confidentiality, and integrity of Customer Data. AIPL has no responsibility or liability for the accuracy of Customer Data. AIPL further represents and warrants that AIPL shall not: (a) sell Personal Information, or (b) retain, use or disclose Personal Information except as necessary to fulfill the Agreement, within the direct business relationship with the Customer, or for AI training purposes subject to anonymization and applicable laws. AIPL will process data in accordance with applicable laws and shall not retain, store, or transfer any data beyond 90 days from receipt, except as required to provide services. AIPL will promptly delete or modify Customer Data upon request and take necessary measures to protect privacy when disclosing data, ensuring minimal disclosure of Personal Data. Both parties will notify the relevant authority of any data breaches, as required by applicable law.</p>
                                    </div>
                                    <div className='d-flex mb-0'>
                                        <strong className='me-2'>6.4. </strong>
                                        <p className='mb-0'><strong> Security Program: </strong> Notwithstanding the provisions in Sections 6.1 and 6.2, each Party shall maintain a security program in line with industry standards and applicable law to: (a) ensure the security and integrity of Customer Data; (b) protect against security threats; and (c) prevent unauthorized access. Each Party will regularly test its security systems, address vulnerabilities promptly, and notify the other Party of any unauthorized access to Customer Data. Both Parties will make reasonable efforts to remediate security vulnerabilities. </p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>7.</span> Warranties and Disclaimers </h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>7.1. </strong>
                                            <strong> AIPL Representations & Warranties </strong>
                                        </div>
                                        <p className='mb-0'> AIPL warrants to the Customer that, during the first 3 (three) months period following the Effective Date of the Customer's initial License Term, the Software will reasonably conform in all material respects with the applicable Documentation. If the Software fails to conform to the foregoing warranty, AIPL will use reasonable efforts to correct the non-compliance, provided that the Customer promptly notifies AIPL of the non-compliance and AIPL is able to reproduce the proven non-compliance. If AIPL is unable to remedy the non-compliance, Customer may terminate the Agreement, and AIPL will refund to the Customer the proportionate portion of the unused, pre-paid license fees (if any) as full and to the complete satisfaction of Customer's claim relating to such non-compliance. CUSTOMER ACKNOWLEDGES AND AGREES THAT THE FOREGOING REFUND WILL BE CUSTOMER'S SOLE AND EXCLUSIVE REMEDY FOR ANY CLAIM ARISING OUT OF A BREACH OF THE WARRANTY SET FORTH IN THIS CLAUSE. </p>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>7.2. </strong>
                                            <strong> Disclaimer  </strong>
                                        </div>
                                        <p className='mb-0'>EXCEPT AS SPECIFICALLY PROVIDED IN CLAUSE 6.1, AIPL GIVES NO OTHER WARRANTIES OR REPRESENTATIONS OF ANY KIND WHATSOEVER RELATING TO THE SOFTWARE, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, NONINFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, TITLE, OWNERSHIP, RESULTS, OR THE QUALITY, SUITABILITY, ADEQUACY, GENUINENESS, ACCURACY OR COMPLETENESS OF THE SOFTWARE. CUSTOMER ASSUMES FULL RESPONSIBILITY FOR: (A) SELECTING THE SOFTWARE; AND (B) VERIFYING THE RESULTS OBTAINED FROM USING THE SOFTWARE. WITHOUT LIMITING THE EFFECT OF THE FOREGOING, AIPL DOES NOT WARRANT THAT THE SOFTWARE WILL MEET CUSTOMER'S REQUIREMENTS OR THAT THE OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE. AIPL IS NOT RESPONSIBLE FOR PROBLEMS CAUSED CUSTOMER'S HARDWARE OR OPERATING SYSTEM SOFTWARE, OR FOR PROBLEMS IN THE INTERACTION OF THE SOFTWARE WITH ANY OTHER SOFTWARE.  </p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>8.</span> Indemnification </h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>8.1. </strong>
                                            <strong> Indemnification by AIPL </strong>
                                        </div>
                                        <p className='mb-0'> AIPL shall defend Customer against any claim, demand, suit, or proceeding made or brought against Customer by a third party alleging that the use of Software infringes or misappropriates the intellectual property rights of a third party (a “ <strong> Claim Against Customer </strong>”); provided that Customer: (a) promptly gives AIPL written notice of the Claim Against Customer; (b) gives AIPL sole control of the defense and settlement of the Claim Against Customer (provided that AIPL may not settle any Claim Against Customer unless the settlement unconditionally releases Customer of all liability); and (c) provides to AIPL all reasonable assistance, at AIPL’s expense. In the event of a Claim Against Customer, or if AIPL reasonably believes the Software may infringe or misappropriate the intellectual property rights of a third party, AIPL may in AIPL’s sole discretion and at no cost to Customer: (a) modify the Software so that they no longer infringe or misappropriate, without breaching AIPL’s warranties hereunder, (b) obtain a license for Customer’s continued use of Software in accordance with the Agreement, or (c) terminate Customer’s license to the Software and refund to Customer any prepaid fees covering the remainder of the License Term after the effective date of termination. Notwithstanding the foregoing, AIPL shall have no obligation to indemnify, defend, or hold Customer harmless from any Claim Against Customer to the extent it arises from: (a) Customer Data, (b) use by Customer after notice by AIPL to discontinue use of the Software, (c) use of Software by Customer in combination with equipment or software not supplied by AIPL where the Software itself would not be infringing, or (d) Customer’s breach of the Agreement. </p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>8.2. </strong>
                                            <strong> Indemnification by Customer  </strong>
                                        </div>
                                        <p className='mb-0'>Customer shall indemnify, defend and hold AIPL harmless from and against any judgments, settlements, costs and fees reasonably incurred (including reasonable attorney’s fees) resulting from any claim, demand, suit or proceeding made or brought against AIPL by a third party alleging that Customer Data or Customer’s use of the Software is in violation of the Agreement, infringes or misappropriates the intellectual property rights of a third party or violates applicable law. </p>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>8.3. </strong>
                                            <strong> Exclusive Remedy  </strong>
                                        </div>
                                        <p className='mb-0'>This Clause 8 states the indemnifying party’s sole liability to, and the indemnified party’s exclusive remedy against, the other party for any type of claim described in this Clause. If Customer’s use of Software is, or in AIPL’s reasonable opinion is likely to become, enjoined or materially diminished as a result of a Claim Against Customer, then AIPL will, at its sole option, either: (a) procure the continuing right of Customer to use the Software; (b) replace or modify the Software in a functionally equivalent manner so that it no longer infringes; or (c) terminate the Agreement and refund to Customer the license fees attributable for the remining License Term, provided however that for a perpetual license of the Software the refund will be based on a straight line amortization over 5 (five) year term beginning on the date of initial delivery of the Software. This Clause states AIPL’s sole and exclusive liability, and Customer’s sole and exclusive remedy, for the actual or alleged infringement or misappropriation of any third-party intellectual property right by the Software. </p>
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>9.</span> Limitation of Liability </h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>9.1. </strong>
                                            <p className='mb-0'> OTHER THAN THE OBLIGATIONS SET FORTH IN CLAUSE 8 (INDEMNIFICATION), UNDER NO CIRCUMSTANCES WILL AIPL BE LIABLE UNDER ANY CONTRACT, STRICT LIABILITY, TORT (INCLUDING NEGLIGENCE) OR OTHER LEGAL OR EQUITABLE THEORY, FOR ANY SPECIAL, INCIDENTAL, EXEMPLARY, INDIRECT OR CONSEQUENTIAL COSTS OR DAMAGES, INCLUDING LOST PROFITS, LITIGATION COSTS, LOSS OF DATA, CONTENT, PRODUCTION OR PROFIT, ARISING OUT OF OR RELATING IN ANY WAY TO THE SUBJECT MATTER OF THE AGREEMENT, EVEN IF INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY STATED HEREIN. IN NO EVENT WILL AIPL'S TOTAL LIABILITY UNDER OR RELATED TO THE AGREEMENT, THE TERMINATION THEREOF, AND/OR THE SOFTWARE, REGARDLESS OF THE FORM OF ACTION, EXCEED THE AMOUNT PAID OR PAYABLE BY CUSTOMER FOR THOSE SERVICES GIVING RISE TO SUCH CLAIM UNDER THE APPLICABLE ORDER FORM, ATTRIBUTABLE TO IMMEDIATELY 3 (THREE) PRECEDING MONTHS OF THE APPLICABLE INCIDENT. </p>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>9.2. </strong>
                                            <strong> Other Limitations </strong>
                                        </div>
                                        <p className='mb-0'>In no event shall AIPL be liable to the Customer to the extent that the alleged infringement of a third party intellectual property is based on or connected with: (a) a modification of the Software or Documentation by anyone other than AIPL; (b) the Customer’s use of the Software or Documentation in a manner contrary to the instructions given to the Customer by AIPL; or (c) the Customer’s use of the Software or Documentation after notice of the alleged or actual infringement from AIPL or any appropriate authority. Notwithstanding anything contained in the Agreement, Customer assumes sole responsibility for results obtained from the use of the Software and the Documentation by Customer, and for conclusions drawn from such use and AIPL shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts, Customer Data provided to AIPL by the Customer in connection with the utilization of the Software, or any actions taken by AIPL at the Customer’s direction. </p>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='d-flex'>
                                            <strong className='me-2'>9.3. </strong>
                                            <p className='mb-0'>The Customer expressly agrees that AIPL is not a licensed health care provider and that the use of the Software is not a substitute for independent medical decision making by a qualified health care practitioner. The Customer is solely responsible for its own and Users conduct with respect to patient care and any reliance upon the Software shall not diminish the Customer and/ or User’s responsibility for patient care.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>10.</span> Term and Termination</h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>10.1. </strong>
                                            <strong>Term of Agreement</strong>
                                        </div>
                                        <p className='mb-0'> The Agreement commences on the Effective Date and continues until otherwise terminated, by written agreement of the parties, in accordance with Clause 10.3 or upon the expiration of the last License Term or renewal thereof. Notwithstanding anything contained herein, the Customer shall not be entitled to terminate the license to the Software for such period as specifically agreed in the Order Form (“<strong>Lock-In Period</strong>”). In case of any termination by the Customer of the license under an Order during the Lock-In Period, the Customer shall be liable to pay the license fee attributable to the unexpired/remaining portion of the Lock-In Period. </p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>10.2. </strong>
                                            <strong> Renewal of Software License   </strong>
                                        </div>
                                        <p className='mb-0'>Except as otherwise specified in the applicable Order, the license shall automatically renew for successive [1 (one-year) or for such other periods as the parties may mutually agree, unless and until terminated by either party in accordance herewith or unless either party provides written notice of non-renewal to the other party at least 60 (sixty) days prior to the end of the then-current License Term. Except to the extent as otherwise agreed at the time of execution of the Order Form, AIPL shall be entitled to increase the pricing applicable to the renewal of any then-current License Term by providing the Customer with a written notice thereof. </p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>10.3. </strong>
                                            <strong> Termination  </strong>
                                        </div>
                                        <p className='mb-0'>Without prejudice to other terms and conditions hereof, a party may terminate the Agreement for cause if: (a) the other party does not cure its material breach within 30 (thirty) days of receiving written notice from the non-breaching party; or (b) the other party becomes the subject of an insolvency petition which has been admitted. AIPL may terminate the Agreement for cause: (a) within 10 (ten) days written notice of Customer’s failure to timely pay undisputed amounts due under the Agreement; or (b) immediately upon Customer’s breach of Clause 2 and 3. If this Agreement is terminated by AIPL for cause, Customer will pay any unpaid fees covering the remainder of all License Terms. Upon termination of an Order for a cause by Customer and upon Customer’s written request, AIPL shall refund on a pro rata basis, any fees paid thereunder that cover the remainder of the applicable Term after the effective date of termination. Upon termination of an Order for cause by AIPL, all amounts owed by Customer thereunder shall become due and payable. In no event shall any termination relieve the Customer of the obligation to pay all fees payable to AIPL for the period prior to the effective date of termination. Immediately on the termination or expiration of this Agreement, the Customer shall and shall ensure that the Users export any data they may need, cease using the Software and uninstall all instance of the same from its systems. AIPL shall upon termination delete all Customer data and communicate the same via email. </p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>10.4. </strong>
                                            <strong> Survival  </strong>
                                        </div>
                                        <p className='mb-0'>This Clause, Clauses 4 (<i>Proprietary Rights</i>), 5 (<i>Confidentiality</i>), 7.2 (<i>Disclaimer</i>), 8 (<i>Indemnification</i>), 9 (<i>Limitation of Liability</i>), 11 (<i>Notices, Dispute Resolution, Governing Law and Jurisdiction</i>) and 12 (<i>General Provisions</i>) and any other rights and obligations of the parties hereunder that by their nature are reasonably intended to survive termination or expiration, shall survive any termination or expiration of the Agreement. </p>
                                    </div>
                                </div>
                               
                                <div className='mb-5'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>11.</span> Notices, Dispute Resolution, Governing Law and Jurisdiction	</h3>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>11.1. </strong>
                                            <strong>Notices</strong>
                                        </div>
                                        <p className='mb-0'> Except as otherwise provided herein, all notices to the parties shall be sent to the addresses listed on the Order Form. All notices must be made either via email, conventional mail, or overnight courier. Notice sent via conventional mail, using registered mail, is deemed received four business days after mailing. Notice sent via email or overnight courier is deemed received the second day after having been sent. Billing-related notices to the Customer will be addressed to the relevant billing contact designated by the Customer on the applicable Order. </p>
                                    </div>
                                    <div className='mb-4'>
                                        <div className='mb-3 d-flex'>
                                            <strong className='me-2'>11.2. </strong>
                                            <strong>	Dispute Resolution, Governing Law and Jurisdiction  </strong>
                                        </div>
                                    </div>
                                    <ul className='mb-0 list-unstyled'>
                                        <li>
                                            <strong>11.2.1. </strong>
                                            <p className='mb-0'>	If you are a customer based in, or operating out of India, the following clause shall apply:  The Agreement will be governed by and covered in accordance with the laws of India. The courts of  Mumbai, Maharashtra will have the exclusive jurisdiction with respect to all matters arising out of or in connection with the Agreement. In the event of a dispute, Parties undertake to hold such consultation and negotiations in good faith and shall secure that the same is attended by persons with decision making authority regarding the dispute. If no settlement can be reached through friendly consultation and negotiation within 30 (thirty) days of one Party delivering a notice of the dispute to the other Party, then such dispute will be finally settled by arbitration in accordance with the provision of this Clause. The arbitration proceedings will be conducted in accordance with the provision of the Arbitration and Conciliation Act, 1996. The arbitration shall be conducted by a single arbitrator, to be appointed mutually by the Parties. The arbitration proceedings will be held at Mumbai, Maharashtra and the language to be used in the arbitral proceedings will be English. Any award made by the arbitration tribunal will be final and binding in each of the parties to the dispute. </p>
                                        </li>
                                        <li>
                                            <strong> 11.2.2.  </strong>
                                            <p className='mb-0'> If you are a customer based in, or operating out of the United Arab Emirates, the following clause shall apply:  The Agreement shall be governed by and shall be construed in accordance with the English laws. In the event of a dispute, Parties undertake to hold such consultation and negotiations in good faith and shall secure that the same is attended by persons with decision making authority regarding the dispute. If no settlement can be reached through friendly consultation and negotiation within 30 (thirty) days of one Party delivering a notice of the dispute to the other Party, then such dispute will be finally settled by arbitration in accordance with the provision of this Clause.  Any dispute, controversy, or claim arising out of or in connection with the Agreement, including its validity, interpretation, enforceability, or termination, shall be settled by arbitration in accordance with the rules of arbitration of the Dubai International Arbitration Centre ("DIAC") in force at the time of the dispute. The arbitration shall be conducted in DIFC (Dubai), United Arab Emirates, and shall be conducted in the English language. The decision of the arbitral tribunal shall be final and binding upon the Parties. </p>
                                        </li>
                                        <li>
                                        <strong> 11.2.3.  </strong>
                                        <p className='mb-0'>	If you are a customer based in, or operating out of the United States of America, the following clause shall apply: The Agreement and any dispute arising out of or relating to the Agreement, including its interpretation, performance, or breach, shall be governed by and construed in accordance with the laws of the State of California, the United States of America, without regard to its conflict of laws principles. In the event of a dispute, Parties undertake to hold such consultation and negotiations in good faith and shall secure that the same is attended by persons with decision making authority regarding the dispute. If no settlement can be reached through friendly consultation and negotiation within 30 (thirty) days of one Party delivering a notice of the dispute to the other Party, then such dispute will be finally settled by arbitration in accordance with the provision of this Clause. Any dispute, controversy, or claim arising out of or in connection with the Agreement, including its validity, interpretation, enforceability, or termination, shall be settled by arbitration in accordance with the rules of American Arbitration Association in force at the time of the dispute. The arbitration shall be conducted in California and shall be conducted in the English language. The decision of the arbitral tribunal shall be final and binding upon the Parties. </p>
                                        </li>
                                        <li>
                                        <strong> 11.2.4.  </strong>
                                        <p className='mb-0'> If you are a customer based in or operating out of any other country or region not expressly stated herein, the terms contained in the Order Form in relation to the dispute resolution, jurisdiction and governing law shall apply instead.</p>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className='mb-0'>
                                    <h3 className='aug-legal-page-subtitle'><span className='me-1'>12.</span> General Provisions </h3>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.1. </strong>
                                        <p className='mb-0'><strong>Service Analyses:</strong> AIPL may: (a) compile statistical and other information related to the performance, operation and use of the Services, and (b) use, and share data from the Services environment in aggregated form for security and operations management, to create statistical analyses, and for research and development purposes (Sub-clauses (a) and (b) above are collectively referred to as “<strong>Service Analyses</strong>”). Service Analyses will not incorporate any information, including Customer Data, in a form that could serve to identify Customer or an individual. AIPL retains all intellectual property rights in Service Analyses.</p>
                                    </div>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.2. </strong>
                                        <p className='mb-0'><strong>Relationship of the Parties: </strong> The parties are independent contractors. The Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties.</p>
                                    </div>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.3. </strong>
                                        <p className='mb-0'><strong>Non-Solicitation:  </strong> Customer agrees that during the term of the Agreement, and for 12 (twelve) months thereafter, it will not recruit or otherwise solicit for employment any person employed by AIPL. Nothing in this clause shall be construed to prohibit individual AIPL employees from responding to public employment advertisements, postings or job fairs of Customer, provided such response is not prompted by Customer intentionally circumventing the restrictions of this Clause.</p>
                                    </div>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.4. </strong>
                                        <p className='mb-0'><strong>Public Relations:   </strong> Customer agrees that AIPL may identify Customer as a AIPL customer in advertising, media relations, trade shows, the website, and other similar promotional activities, using Customer’s name and trademarks in accordance with Customer’s trademark guidelines. Customer shall also assist AIPL in preparing a press release announcing Customer as a new AIPL customer, with the view to publishing and in preparing a case study for external use that details Customer’s use of the Services. </p>
                                    </div>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.5. </strong>
                                        <p className='mb-0'><strong> Waiver:   </strong> No failure or delay by either party in exercising any right under the Agreement shall constitute a waiver of that right. </p>
                                    </div>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.6. </strong>
                                        <p className='mb-0'><strong> Severability: </strong> If any provision of the Agreement is held by a court of competent jurisdiction to be contrary to law, the provision shall be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Agreement shall remain in effect. </p>
                                    </div>
                                    <div className='mb-3 d-flex'>
                                        <strong className='me-2'>12.7. </strong>
                                        <p className='mb-0'><strong> Assignment:  </strong> Neither party may assign its rights and obligations hereunder, either in whole or in part, whether by operation of law or otherwise, without the prior written consent of the other party. Notwithstanding the foregoing, either party may assign the Agreement in its entirety (including all Order Forms), without consent of the other party, to its Affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other party.  </p>
                                    </div>
                                    <div className='mb-0 d-flex'>
                                        <strong className='me-2'>12.8. </strong>
                                        <p className='mb-0'><strong> Entire Agreement:   </strong> The Agreement, along with the Order Form and these Terms constitutes the entire agreement between the parties as it relates to the subject matter and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning or relating to the same. To the extent of any conflict or inconsistency between the provisions of this Agreement, the Documentation, any Order Form, the terms of such Order Form shall prevail.   </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}