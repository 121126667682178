import React from 'react';
import {ReactComponent as CustomerSuccessIcone} from '../../../Assets/images/icons/customer-success.svg';

const CustomerSuccess = () => {
    return (
        <div className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <CustomerSuccessIcone/>
            </div>
            <h3 className='mb-0'>Customer-Success</h3>
            <p className='mb-0'>We focus on understanding our clients' needs and delivering solutions that drive growth, fostering lasting partnerships.</p>
        </div>
    );
};

export default CustomerSuccess;