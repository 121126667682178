import React from 'react';
import globalLanguage from '../../../Assets/images/Omni/ClinicianDesigned/global-language.webp';

const GlobalLanguageTab = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={globalLanguage} loading="lazy" alt="Hands holding smartphone with holographic globe, surrounded by multiple language names, showcasing global multilingual capabilities" className="img-fluid" />
        </div>
    );
};

export default GlobalLanguageTab;