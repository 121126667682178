import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/chevron-right-white.svg';
import ArrowLeft from '../../../Assets/images/icons/chevron-left-white.svg';
import {ReactComponent as Quote} from '../../../Assets/images/icons/quote.svg';
import './OurTeam.scss'

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize:'11px',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize:'11px',
        }}
        onClick={onClick}
      />
    );
  }

const OurTeamAr = () => {

    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 1,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        centerMode: true,
        rtl:true,
        className:'aug-our-team-slider',
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SampleNextArrow />,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
                centerMode: false,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                centerMode: false,
              }
            }
        ]
    };

    return (
       <section dir='rtl' className='aug-our-team'>
            <div className='container'>
                <h2 className='aug-career__title'>نسمع من فريقنا</h2>
                <Slider {...settings}>
                    <div dir='rtl' className='aug-our-team__items'>  
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Aman.webp')} alt="Aman Mehta" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>تجسد Augnito الجوهر الحقيقي لثقافة الشركات الناشئة، وتعزز روح المبادرة والتعلم العملي. لقد كان العمل هنا رحلة مذهلة، مما سمح لي باستكشاف التكنولوجيا والتصميمات التي تركز على الإنسان مع المساهمة في منتج أؤمن به حقًا. إنها ليست مجرد شركة متطورة؛ إنها حاضنة للنمو الشخصي والمهني.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  أمان ميهتا, <span>التسويق</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div dir='rtl' className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Esra.webp')} alt="Esra Al Qarni" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>في Augnito، لقد جرّبت حقًا جوهر ثقافة الشركات الناشئة. تشجع البيئة الابتكار وتشجع التعلم العملي، مما يجعل كل يوم مغامرة. لقد أتيحت لي الفرصة للتعمق في التكنولوجيا والتصميم الذي يركز على الإنسان بينما أكون جزءًا من منتج، وأنا شغوف به. إن Augnito ليست مجرد شركة ذات تفكير تقدمي؛ إنها مساحة للنمو الشخصي والمهني.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  إسراء القرني, <span>المبيعات</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div dir='rtl' className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Sam.webp')} alt="Kingsly Samuel" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>رؤية رستم الملهمة، إلى جانب توجيهات فريق القيادة، جعلت هذه الرحلة مجزية بشكل لا يصدق. إنه لمن دواعي سروري أن أرى الأطباء والمستشفيات في دول مجلس التعاون الخليجي يستفيدون من Augnito ، وأنا أعتز بكل لحظة في هذه التجربة. لقد كان التعاون مع فريق Augnito الفعال متعدد الوظائف من أهم ما يميز دوري.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  كينغسلي صموئيل, <span>مدير خدمات العملاء الإقليمي</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div dir='rtl' className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Gauri.webp')} alt="Gauri Sabnis" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>لقد كان الانضمام إلى Augnito تجربة مجزية بشكل لا يصدق. تتميز ثقافة الشركة بروحها التعاونية، حيث يتوق الجميع إلى المساعدة والتعلم. إن طبيعة العمل سريعة الخطى هنا تجعلني أتحدى، في حين أن الالتزام المشترك بتحسين تكنولوجيا الرعاية الصحية يجعل وظيفتي تبدو هادفة ومؤثرة.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  جوري سابنيس, <span>المنتج</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div dir='rtl' className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Tejas.webp')} alt="Tejas Chhabra" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>لقد كان العمل في Augnito تجربة مجزية بشكل لا يصدق. توفر الشركة بيئة سريعة الخطى ومثيرة حيث يكون الابتكار والنمو دائمًا في المقدمة. منذ اليوم الأول، شعرت بدعم زملائي والقيادة، الذين هم دائمًا على استعداد لتوجيه وتقديم رؤى قيمة. وتضمن الثقافة التعاونية هنا عدم وجود أي تحدٍ لا يمكن التغلب عليه، فهناك دائمًا شخص مستعد للمساعدة.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  تيجاس تشابرا, <span>الشؤون القانونية والامتثال</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div dir='rtl' className='aug-our-team__items'>                    
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Greeshma.webp')} alt="Greeshma S" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>كان العمل في Augnito رحلة ملهمة حيث يلتقي الابتكار بالشمولية. باعتباري إحدى كبار مطوري البرامج، فقد تم تمكيني لدفع الحدود التقنية مع المساهمة في المشاريع المؤثرة. لا تقدر الثقافة التعاونية هنا وجهات النظر المتنوعة فحسب، بل تعزز أيضًا التعلم المستمر والنمو الشخصي. إنه مكان يجتمع فيه الشغف والهدف، مما يجعل كل يوم مليئًا بالتحديات والمكافأة.</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  جريشما إس, <span>الهندسة</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div dir='rtl' className='aug-our-team__items'>
                        <div className='d-md-flex'>                 
                            <img src={require('../../../Assets/images/Careers/testimonials/Aishwarya.webp')} alt="Aishwarya" className="img-fluid"/>
                            <div className='aug-our-team__items-content'>
                            <div className='aug-our-team__items-content-height'>
                                <p className='mb-0'>أقدر حقًا حرية تجربة الأفكار الجديدة وإضفاء الإبداع على عملي في Augnito. تدور الثقافة هنا حول مبدأ "الفوز والتعلم" - فإما أن تنجح، أو تكتسب دروسًا قيمة تساعدك على العودة بشكل أقوى. كل إنجاز مهم، ونحن نحتفل دائمًا بانتصارات الفريق!</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='aug-our-team__items-profile'>
                                        ~  ايشواريا, <span>الموارد البشرية</span>
                                    </div>
                                    <div className='aug-customer-items__quote'>
                                        <Quote width="54" height="40" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </Slider>
            </div>
       </section>
    );
};

export default OurTeamAr;