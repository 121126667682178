import React from 'react';
import CareerBanner from './CareerBanner/CareerBanner';
import CollageGallery from './CollageGallery/CollageGallery';
import KekaCareer from './KekaCareer/KekaCareer';
import BaseComponent from '../../Common/BaseComponent'
import CareersMeta from './Meta/CareersMeta';
import AugnitoDifference from './AugnitoDifference/AugnitoDifference';
import WeBelieve from './WeBelieve/WeBelieve';
import OurTeam from './OurTeam/OurTeam';
// import SkillsGoals from './SkillsGoals/SkillsGoals';

export default class Careers extends BaseComponent {
    render() {        
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <CareersMeta />
                <CareerBanner />
                <CollageGallery />
                <WeBelieve/>
                <OurTeam/>
                <AugnitoDifference/>
                <KekaCareer />
                {/* <SkillsGoals/> */}
            </main>
        );
    }
}
