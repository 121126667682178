import React from 'react';
import './ThankYou.scss';
import BaseComponent from '../../Common/BaseComponent'
import ExpressInterestAr from './ThankYouBanner/ExpressInterestAr';
import OurPartnerAr from '../Home/OurPartner/OurPartnerAr';
import CustomerSayAr from '../Home/CustomerSay/CustomerSayAr';
import ThankYouMetaAr from './Meta/ThankYouMetaAr';

export default class ThankYouAr extends BaseComponent {
    render() {
  
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ThankYouMetaAr/>
                <ExpressInterestAr/>
                <CustomerSayAr/>
                <OurPartnerAr/>
            </main>
        )
    }
}