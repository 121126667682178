import React from 'react';
import {ReactComponent as PeopleIcone} from '../../../Assets/images/icons/people.svg';

const People = () => {
    return (
        <div className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <PeopleIcone/>
            </div>
            <h3 className='mb-0'>People</h3>
            <p className='mb-0'>We believe in adopting a people-first approach to all that we do. Whether that translates as a human-centric product or an employee-focused organization.</p>
        </div>
    );
};

export default People;