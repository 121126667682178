import React from 'react';
import BaseComponent from '../../Common/BaseComponent';
import OmniMetaAr from './Meta/OmniMetaAr';
import OmniBannerAr from './OmniBanner/OmniBannerAr';
// import TrustedByAr from '../Spectra/TrustedBy/TrustedByAr';
import ClinicalNoteAr from './ClinicalNote/ClinicalNoteAr';
import DiscoverOmniAr from './DiscoverOmni/DiscoverOmniAr';
// import CustomerSayAr from '../Home/CustomerSay/CustomerSayAr';
import WhyOmniAr from './WhyOmni/WhyOmniAr';
// import ClinicalPlatformsAr from '../Spectra/ClinicalPlatforms/ClinicalPlatformsAr';
import ClinicianDesignedAr from './ClinicianDesigned/ClinicianDesignedAr';

export default class OmniAr extends BaseComponent {  
        
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
               <OmniMetaAr/>
               <OmniBannerAr/>
               {/* <TrustedByAr/> */}
               <ClinicalNoteAr/>
               {/* <ClinicalPlatformsAr/> */}
               <ClinicianDesignedAr/>
               <WhyOmniAr/>
               {/* <CustomerSayAr/> */}
               <DiscoverOmniAr/>
            </main>
        );
    }
}