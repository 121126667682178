import React from 'react';
import {ReactComponent as IntegrityIcone} from '../../../Assets/images/icons/integrity.svg';

const IntegrityAr = () => {
    return (
        <div dir='rtl' className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <IntegrityIcone/>
            </div>
            <h3 className='mb-0'>النزاهة</h3>
            <p className='mb-0'>نحن نعطي الأولوية للصدق والشفافية والمساءلة، وبناء الثقة وتعزيز بيئة يزدهر فيها الجميع.</p>
        </div>
    );
};

export default IntegrityAr;