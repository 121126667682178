import React from 'react';
import './EmpoweringClinicians.scss';
import BaseComponent from '../../../Common/BaseComponent';
import PhysicianBurnoutContentAr from './PhysicianBurnoutContentAr';
import PatientEngagementTabAr from './PatientEngagementTabAr';
import PatientEngagementContentAr from './PatientEngagementContentAr';
import ClaimDenialTabAr from './ClaimDenialTabAr';
import ClaimDenialContentAr from './ClaimDenialContentAr';
import SimplifiedDeploymentContentAr from './SimplifiedDeploymentContentAr';
import SimplifiedDeploymentTabAr from './SimplifiedDeploymentTabAr';
import CustomerSuccessContentAr from './CustomerSuccessContentAr';
import CustomerSuccessTabAr from './CustomerSuccessTabAr';
import EmpoweringCliniciansMobileAr from './EmpoweringCliniciansMobileAr';
import PhysicianBurnoutTabAr from './PhysicianBurnoutTabAr';

export default class EmpoweringCliniciansAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section dir='rtl' className='aug-empowering-clinicians'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2 className='aug-career__title'>تمكين الأطباء عبر مختلف مراحل الرعاية.</h2>
                        </div>
                    </div>
                    <div className='aug-empowering-clinicians__tab'>
                        <div className='d-none d-lg-block'>
                            <div className='row gx-80'>
                                <div className='col-lg-6'>
                                    <div className="tab-content" id="empoweringClinicians-tabContent">
                                        <div className="tab-pane fade show active" id="physicianBurnout" role="tabpanel" aria-labelledby="physicianBurnout-tab" tabIndex="0">
                                            <PhysicianBurnoutTabAr/>
                                        </div>
                                        <div className="tab-pane fade" id="patientEngagement" role="tabpanel" aria-labelledby="patientEngagement-tab" tabIndex="0">
                                            <PatientEngagementTabAr />
                                        </div>
                                        <div className="tab-pane fade" id="claimDenial" role="tabpanel" aria-labelledby="claimDenial-tab" tabIndex="0">
                                            <ClaimDenialTabAr />
                                        </div>
                                        <div className="tab-pane fade" id="simplifiedDeployment" role="tabpanel" aria-labelledby="simplifiedDeployment-tab" tabIndex="0">
                                            <SimplifiedDeploymentTabAr />
                                        </div>
                                        <div className="tab-pane fade" id="customerSuccess" role="tabpanel" aria-labelledby="customerSuccess-tab" tabIndex="0">
                                            <CustomerSuccessTabAr />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="nav flex-column nav-pills" id="empoweringClinicians-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link mt-0 active" id="physicianBurnout-tab" data-bs-toggle="pill" data-bs-target="#physicianBurnout" type="button" role="tab" aria-controls="physicianBurnout" aria-selected="true">
                                            <PhysicianBurnoutContentAr />
                                        </button>
                                        <button className="nav-link" id="patientEngagement-tab" data-bs-toggle="pill" data-bs-target="#patientEngagement" type="button" role="tab" aria-controls="patientEngagement" aria-selected="false">
                                            <PatientEngagementContentAr />
                                        </button>

                                        <button className="nav-link" id="claimDenial-tab" data-bs-toggle="pill" data-bs-target="#claimDenial" type="button" role="tab" aria-controls="claimDenial" aria-selected="false">
                                            <ClaimDenialContentAr />
                                        </button>
                                        <button className="nav-link" id="simplifiedDeployment-tab" data-bs-toggle="pill" data-bs-target="#simplifiedDeployment" type="button" role="tab" aria-controls="simplifiedDeployment" aria-selected="false">
                                            <SimplifiedDeploymentContentAr />
                                        </button>
                                        <button className="nav-link" id="customerSuccess-tab" data-bs-toggle="pill" data-bs-target="#customerSuccess" type="button" role="tab" aria-controls="customerSuccess" aria-selected="false">
                                            <CustomerSuccessContentAr />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                            <EmpoweringCliniciansMobileAr/>
                        </div>


                    </div>
                </div>
            </section>
        );
    };
};