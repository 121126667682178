import React from 'react';
import DictationVideoMp from '../../../Assets/images/Spectra/EfficientDocumentation/begin-dictation.mp4';
import DictationVideo from '../../../Assets/images/Spectra/EfficientDocumentation/begin-dictation.webm';

const BeginDictation = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center flex-lg-row flex-column-reverse">
                <div className='col-12 col-md-10 col-lg-5 mx-md-auto'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="">
                            <video width="100%" id='videoDictation' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                                <source src={DictationVideo} type="video/webm" />
                                <source src={DictationVideoMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className='offset-lg-1 col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>STEP 2</h6>
                        <h3 className='mb-3'>Begin Dictation</h3>
                        <p className='mb-0'>Speak naturally, while Spectra records your notes in the built-in Smart Editor or directly in any third-party HIS/EMR/PACS/RIS. Our solution is 99% accurate straight out of the box, with support for over 55 medical specialties and sub-specialties.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BeginDictation;