import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import { ReactComponent as LanguageIcone } from '../../../Assets/images/icons/language-outline.svg';
import { ReactComponent as DocumentationIcone } from '../../../Assets/images/icons/check-ring.svg';
import { ReactComponent as CodeIcone } from '../../../Assets/images/icons/connect-collaborate.svg';
import { ReactComponent as IntegrationIcone } from '../../../Assets/images/icons/dashboard-setting.svg';
import { ReactComponent as DrivenIcone } from '../../../Assets/images/icons/voice-check.svg';
import GlobalLanguageTab from './GlobalLanguageTab';
import ClinicalDocumentationTab from './ClinicalDocumentationTab';
import CodeRetrievalTab from './CodeRetrievalTab';
import AIDrivenTab from './AIDrivenTab';
import EasyIntegrationsTab from './EasyIntegrationsTab';


export default class ClinicianDesignedMobileAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (

            <div dir='rtl' class="accordion" id="accordionClinicianDesigned">
                <div class="accordion-item">
                    <div className='accordion-item-content' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <LanguageIcone /> دعم اللغة العالمية
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            يُسهّل Omni التقاط المحادثة السريرية بأكثر من 37 لغة. إنه لا يعتمد على اللهجة ولديه قدرات مدمجة للتعرف على الكلام متعدد اللغات، مما يضمن التفاعل الطبيعي بين الأطباء والمرضى.
                        </div>
                    </div>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionClinicianDesigned">
                        <div className='mt-1'>
                            <GlobalLanguageTab />
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <DocumentationIcone /> تكامل الوثائق السريرية (CDI)
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            يقدم Omni مراجعة منهجية وتحسينًا للوثائق السريرية للمساعدة في تحسين
                            جودة ودقة واكتمال السجلات الخاصة بالمريض من المصدر.
                        </div>
                    </div>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <ClinicalDocumentationTab />
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <CodeIcone /> استرجاع الكود تلقائيًا
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            يعمل Omni على تبسيط عمليات الترميز السريري من خلال التحليل الذكي للوثائق
                            تعيين التلقائي للأكواد ICD 10 أو SNOMED أو SBS ذات الصلة مما يقلل من مخاطر الخطأ
                            ويضمن الامتثال للوائح.
                        </div>
                    </div>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <CodeRetrievalTab />
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <DrivenIcone /> التخصيص القائم على الذكاء الاصطناعي
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            يسمح Omni بنقل البيانات دون متاعب إلى السجلات الطبية الإلكترونية المستندة إلى الويب دون الحاجة إلى عمليات دمج معقدة، مما يوفر إمكانية الوصول الفوري إلى سجلات المرضى ضمن سير العمل الحالي.
                        </div>
                    </div>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <AIDrivenTab />
                        </div>
                    </div>
                </div>
                <div class="accordion-item border-0">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                            <IntegrationIcone /> عمليات دمج سهلة عبر واجهات برمجة التطبيقات ومجموعات تطوير البرامج
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            يتيح Omni للمطورين تحسين تطبيقات البرامج السريرية الحالية لديهم من خلال
                            خيارات النشر باستخدام أكواد قليلة ودون أكواد.
                        </div>
                    </div>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <EasyIntegrationsTab />
                        </div>
                    </div>
                </div>
            </div>

        );
    };
};