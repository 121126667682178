import validator from "validator";

const Validation = (values) => {    
    let errors = {};

    if (!values.FirstName) {
        errors.FirstName = "Please Enter First Name";
    }

    if (!values.LastName) {
        errors.LastName = "Please Enter Last Name";
    }

    if (!values.Email) {
        errors.Email = "Please Enter Email ID";
    }else if (!validator.isEmail(values.Email)) {
        errors.Email = "Invalid Email Address";
    }

    if (!values.Phone) {
        errors.Phone = "Please Enter Phone Number";
    }else if (!validator.isMobilePhone(values.Phone)) {
        errors.Phone = "Please Enter Correct Phone Number";
    }

    if (!values.Country) {
        errors.Country = "Please Select Country Name";
    }

    if (!values.Product) {
        errors.Product = "Please Select Product Name";
    }

    if (!values.Company) {
        errors.Company = "Please Enter Company Name";
    }

    if (!values.JobTitle) {
        errors.JobTitle = "Please Enter JobTitle";
    }

    if (!values.SetupType) {
        errors.SetupType = "Please Select Setup Type";
    }

    if(!values.AgreeToPrivacy){
        errors.AgreeToPrivacy = "Please agree to the Privacy policy to proceed";
    }
    
    return errors;
};

export default Validation;
