import React from 'react';
import Pattren from '../../../Assets/images/Pattrens/footer.webp';

const CustomizedQuoteAr = () => {
    return (
        <section dir='rtl' className='aug-perfec-solution'>
            <div className='container'>
                <div className='aug-perfec-solution-wrap'>
                    <div className='row align-items-center'>
                        <div className='offset-lg-3 col-lg-5 col-md-12 col-12'>
                            <div className='aug-perfec-solution-box'>
                                <h2 className='mb-3'>أداة الذكاء الاصطناعي الأمثل للأطباء</h2>
                                <p>سيكون فريقنا سعيدًا بتقديم المساعدة في أي استفسارات إضافية. <a href="mailto:support@augnito.ai"> support@augnito.ai </a></p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12'>
                            <div className='aug-perfec-solution__img'>
                                <img src={Pattren} alt="pattern" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomizedQuoteAr;