import React from 'react';
import {ReactComponent as ZeroVoiceIcone} from '../../../Assets/images/icons/voice-check.svg';

const ZeroVoice = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ZeroVoiceIcone/>
            </div>
            <h3 className='mb-2'>Zero Voice Profile Training</h3>
            <p className='mb-0'>Spectra is a fast and easy speech-to-text solution that understands and takes accurate clinical notes across all accents. It is 99% accurate straight out of the box, so you can get started as soon as you subscribe.</p>
        </div>
    );
};

export default ZeroVoice;