import React from 'react';
import easyIntegrations from '../../../Assets/images/Omni/ClinicianDesigned/easy-integration.webp';

const EasyIntegrationsTab = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={easyIntegrations} loading="lazy" alt="Developer coding on laptop, displaying complex programming syntax for advanced software integration" className="img-fluid" />
        </div>
    );
};

export default EasyIntegrationsTab;