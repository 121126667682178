import React from 'react';

const ImplementationCostAr = () => {
    return (
        <div dir='rtl' className="accordion-item">
            <h3 className="accordion-header" id="ImplementationCost">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseImplementationCost" aria-expanded="false" aria-controls="collapseImplementationCost">
                كيف يتناسب Augnito مع أنظمة/هندسة المعلومات الحالية الخاصة بي؟ 
                </button>
            </h3>
            <div id="collapseImplementationCost" className="accordion-collapse collapse" aria-labelledby="ImplementationCost" data-bs-parent="#faq">
                <div className="accordion-body">
                تم تصميم Augnito ليكون متوافقًا مع معظم أنظمة RIS/PACS/LIS/HIS/EMR العالمية. لتأكيد التوافق مع نظامك الحالي، يرجى الاتصال بنا. في حالة عدم إنشاء التكامل بالفعل، سيوفر فريق الخدمات الاحترافي لدينا الدعم اللازم لضمان التكامل السلس في البنية التحتية الحالية لديك من خلال طبقة قوية للتشغيل البيني تتضمن العديد من إمكانيات الواجهة بما في ذلك واجهات برمجة التطبيقات (APIs) ومجموعات تطوير البرامج (SDK) وFHIR وHL7. نحن نقدم أيضًا خيار تكامل بدون كود للأنظمة المتوافقة وبوابة قوية للمطورين تتضمن أدلة خطوة بخطوة، وبيئة Sandbox، ومجموعات SDK، والوثائق، وموارد التطوير الأخرى.
                </div>
            </div>
        </div>
    );
};

export default ImplementationCostAr;