import React from 'react';
import './PageNotFound.scss';
import BaseComponent from '../../Common/BaseComponent';
import PageNotFoundMeta from './Meta/PageNotFoundMeta';
import Oops from './Oops';
import PerfectSolution from '../Home/PerfectSolution/PerfectSolution';

export default class PageNotFound extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main className="aug-error-page">
                <PageNotFoundMeta />
                <Oops/>
                <PerfectSolution/>
            </main>
        )
    }
}