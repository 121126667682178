import React from 'react';
import {ReactComponent as OfficeRiyadh} from '../../../Assets/images/ContactUs/our-offices/riyadh.svg';

const Riyadh = () => {
    return (
        <div className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeRiyadh/>
            </div>
            <h3 className='aug-our-offices__name'>Riyadh, Saudi Arabia</h3>
            <p className='mb-3'>While we work on setting up our office space in the region, you can reach us at</p>
            <a href="mailto:sales@augnito.ai"> sales@augnito.ai</a>
        </div>
    );
};

export default Riyadh;