import React from 'react';
import { ReactComponent as TemplatesIcone } from '../../../Assets/images/icons/google-docs.svg';

const DynamicTemplatesContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <TemplatesIcone /> Dynamic Templates
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Upload predefined templates for widely used clinical note types like Imaging Reports, Discharge Summaries, SOAP notes, and many more. Easily open, navigate, dictate, and edit clinical reports using just your voice.
            </div>
        </div>
    );
};

export default DynamicTemplatesContent;