import React from 'react';
import {ReactComponent as CompetitiveCompensationIcone} from '../../../Assets/images/icons/competitive-compensation.svg';

const CompetitiveCompensation = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <CompetitiveCompensationIcone/>
            </div>
            <h3 className='mb-2'>Competitive Compensation</h3>
            <p className='mb-0'>Augnito offers attractive salary packages that recognize your skills and contributions.</p>
        </div>
    );
};

export default CompetitiveCompensation;