import React from 'react';
import { ReactComponent as LanguageIcone } from '../../../Assets/images/icons/check-ring.svg';

const ClinicalDocumentationContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <LanguageIcone /> Clinical Documentation Integrity (CDI)
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Omni offers a systematic review and refinement of clinical documentation to help improve the quality, accuracy, and completeness of patient records at source.
            </div>
        </div>
    );
};

export default ClinicalDocumentationContent;