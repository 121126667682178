import React from 'react';
import Slider from "react-slick";
 
const OurPartnerLogoMEAr = () => {
    const settings = {
        dots: false,
        autoplay:true,
        autoplaySpeed: 0,
        variableWidth: true,
        centerMode: true,
        infinite: true,
        slidesToShow: 6,
        pauseOnHover: false,
        slidesToScroll: 1,
        rtl:true,
        speed: 4000,
        arrows:false,
        cssEase: 'linear',
        className:'aug-our-partner-slider',
        responsive: [
            {
              breakpoint: 991,
               settings: {
                 slidesToShow: 3
              }
            },
            {
               breakpoint: 575,
               settings: {
                slidesToShow: 2
              }
            }
        ]
    };

    return (
        <Slider {...settings}>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/kings.webp')} alt="kings logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/prime.webp')} alt="prime health logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/cloud-solutions.webp')} alt="cloud solutions logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/fujifilm.webp')} alt="fujifilm logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/dallah.webp')} alt="dallah logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/aster.webp')} alt="aster logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/king-abdulaziz.webp')} alt="fujifilm logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/rak.webp')} alt="narayana logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/kims.webp')} alt="kims logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/fakeeh-tech.webp')} alt="fakeeh logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/bahrain.webp')} alt="bahrain logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/apollo.webp')} alt="apollo logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/international-modern.webp')} alt="modern logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/ahalia.webp')} alt="ahalia logo" className="img-fluid"/>
            </div>
            <div className='aug-our-partner-items'>                    
                <img src={require('../../../Assets/images/PartnersLogo/ME/nha.webp')} alt="nha logo" className="img-fluid"/>
            </div>
        </Slider>
    );
};

export default OurPartnerLogoMEAr;