import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";
import * as Constants from '../../../Common/Constants';
import { ScrollTop } from '../../../Common/Utils';
import FooterLogo from './FooterLogo';

const FooterInfo = () => {
    return (
        <div className='row'>
            <div className='col-lg-3 col-md-12'>
                <FooterLogo/>
            </div>
            <div className='col-lg-3 col-md-4 col-6'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-3'>Products</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <Link to="/omni" onClick={ScrollTop}>Omni</Link>
                        </li>
                        <li>
                            <Link to="/spectra" onClick={ScrollTop} >Spectra</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-lg-3 col-md-4 col-6'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-3'>Company</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <Link to="/about-us" onClick={ScrollTop}>About us</Link>
                        </li>
                        <li>
                            <Link to="/careers" onClick={ScrollTop}>Careers</Link>
                        </li>
                        <li>
                            <Link to="/contact-us" onClick={ScrollTop}>Contact us</Link>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "legal"} target="_blank" rel="noreferrer" className="nav-link">Legal</a>                            
                        </li> 
                    </ul>
                </div>
            </div>
            <div className='col-lg-3 col-md-4 col-6'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-3'>Resources</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <a href={Constants.SITE_URL + "resources/blog" } target="_blank" rel="noreferrer" className="nav-link">Blog</a>                            
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/case-studies"} target="_blank" rel="noreferrer" className="nav-link">Case studies</a>   
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/newsroom"} target="_blank" rel="noreferrer" className="nav-link">Newsroom</a> 
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/faq"} target="_blank" rel="noreferrer" className="nav-link">FAQs</a> 
                        </li>
                        {/* <li>
                            <a href={Constants.SITE_URL + "resources/docs"} target="_blank" rel="noreferrer" className="nav-link">Documentation</a>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/videos"} target="_blank" rel="noreferrer" className="nav-link">Videos</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FooterInfo;