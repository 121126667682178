import React from 'react';
import {ReactComponent as CustomizableTemplatesIcone} from '../../../Assets/images/icons/google-docs.svg';

const CustomizableTemplatesAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <CustomizableTemplatesIcone/>
            </div>
            <h3 className='mb-2'>قوالب قابلة للتخصيص</h3>
            <p className='mb-0'>يعمل Omni ككاتب طبي محيطي، حيث يوفر قوالب ملاحظات سريرية قابلة للتهيئة تلبي التخصصات الطبية المتعددة وأنواع الزيارات وتفضيلات مقدمي الخدمات.</p>
        </div>
    );
};

export default CustomizableTemplatesAr;