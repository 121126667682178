import React from 'react';
import {ReactComponent as EnhancedProductivityIcone} from '../../../Assets/images/icons/ownership-success.svg';

const EnhancedProductivity = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <EnhancedProductivityIcone/>
            </div>
            <h3 className='mb-2'>Enhanced Clinician Productivity </h3>
            <p className='mb-0'>Omni automates data structuring, saving clinicians an average of 2+ hours per day and reducing documentation time by 60% - also mitigating physician burnout.</p>
        </div>
    );
};

export default EnhancedProductivity;