import React from 'react';
import { ReactComponent as DrivenIcone } from '../../../Assets/images/icons/voice-check.svg';

const AIDrivenContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <DrivenIcone /> AI Driven Personalization
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Omni allows for hassle-free data transfer to web-based EMRs without the need for complex integrations, providing immediate access to patient records within existing workflows.
            </div>
        </div>
    );
};

export default AIDrivenContent;