import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import { ReactComponent as LanguageIcone } from '../../../Assets/images/icons/language-outline.svg';
import { ReactComponent as DocumentationIcone } from '../../../Assets/images/icons/check-ring.svg';
import { ReactComponent as CodeIcone } from '../../../Assets/images/icons/connect-collaborate.svg';
import { ReactComponent as IntegrationIcone } from '../../../Assets/images/icons/dashboard-setting.svg';
import { ReactComponent as DrivenIcone } from '../../../Assets/images/icons/voice-check.svg';
import GlobalLanguageTab from './GlobalLanguageTab';
import ClinicalDocumentationTab from './ClinicalDocumentationTab';
import CodeRetrievalTab from './CodeRetrievalTab';
import AIDrivenTab from './AIDrivenTab';
import EasyIntegrationsTab from './EasyIntegrationsTab';


export default class ClinicianDesignedMobile extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (

            <div className="accordion" id="accordionClinicianDesigned">
                <div className="accordion-item">
                    <div className='accordion-item-content' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <LanguageIcone /> Global Language Support
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Omni facilitates clinical conversation capture in over 37 languages. It is accent agnostic and has built-in capabilities for multilingual speech recognition, ensuring natural clinician-patient interactions.
                        </div>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <GlobalLanguageTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <DocumentationIcone /> Clinical Documentation Integrity (CDI)
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Omni offers a systematic review and refinement of clinical documentation to help improve the quality, accuracy, and completeness of patient records at source.
                        </div>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <ClinicalDocumentationTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <CodeIcone /> Automatic Code Retrieval
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Omni streamlines clinical coding processes via intelligent analysis of documentation and automatic assignment of relevant ICD 10, SNOMED, or SBS codes – minimizing risk of error and ensuring regulatory compliance.
                        </div>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <CodeRetrievalTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <DrivenIcone /> AI Driven Personalization
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Omni allows for hassle-free data transfer to web-based EMRs without the need for complex integrations, providing immediate access to patient records within existing workflows.
                        </div>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <AIDrivenTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item border-0">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <IntegrationIcone /> Easy Integrations via APIs & SDKs
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Omni empowers developers to enhance their existing clinical software applications via low-code and no-code deployment options.
                        </div>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianDesigned">
                        <div className="mt-1">
                            <EasyIntegrationsTab />
                        </div>
                    </div>
                </div>
            </div>

        );
    };
};