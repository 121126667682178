import React from 'react';

const AugnitoCost = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="accessFeatures">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAccessFeatures" aria-expanded="true" aria-controls="collapseAccessFeatures">
                How can I buy Augnito's Medical Voice AI solutions?
                </button>
            </h3>
            <div id="collapseAccessFeatures" className="accordion-collapse collapse show" aria-labelledby="accessFeatures" data-bs-parent="#faq">
                <div className="accordion-body">
                We offer multiple purchasing options tailored to your specific needs, whether for individual use, a team, or a large enterprise. To explore these options, please contact our sales team by filling out the form above or emailing the addresses listed on our Contact Us page. A member of our sales team will promptly get in touch with you to guide you through the process.
                </div>
            </div>
        </div>
    );
};

export default AugnitoCost;
