import React from 'react';
import {ReactComponent as ClinicalSpecialtiesIcone} from '../../../Assets/images/icons/check-ring.svg';

const ClinicalSpecialtiesAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ClinicalSpecialtiesIcone/>
            </div>
            <h3 className='mb-2'>يدعم أكثر من 55 تخصصًا سريريًا</h3>
            <p className='mb-0'>يغطي Spectra تخصصات مثل الطب العام وعلم الأورام وأمراض القلب والجراحةالعامة والأشعة والمزيد.الذكاء الاصطناعي يتعلم ذاتيًا من ملايين الإملاءات على مستوى العالم ويتم تحديثه بانتظام للتعرف على الأدوية والإجراءات الجديدة.</p>
        </div>
    );
};

export default ClinicalSpecialtiesAr;