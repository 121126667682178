import React from 'react';

const AugnitoSubscriptionAr = () => {
    return (
        <div dir='rtl' className="accordion-item">
            <h3 className="accordion-header" id="switchPlan">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSwitchPlan" aria-expanded="false" aria-controls="collapseSwitchPlan">
                كيف يساعد Augnito الأطباء على استخلاص القيمة من ممارساتهم الشخصية؟
                </button>
            </h3>
            <div id="collapseSwitchPlan" className="accordion-collapse collapse" aria-labelledby="switchPlan" data-bs-parent="#faq">
                <div className="accordion-body">
                تعمل حلول الذكاء الاصطناعي الصوتية الطبية من Augnito على تحسين الممارسة الشخصية من خلال تبسيط عمليات التوثيق، وتقليل الأعباء الإدارية، وتحسين الدقة في السجلات الطبية. يمكن للأطباء التركيز بشكل أكبر على رعاية المرضى مع التأكد من أن وثائقهم دقيقة وحديثة. تم تصميم حلولنا لتتكامل بسلاسة مع سير عملك. لمزيد من المعلومات، يرجى الاتصال بفريقنا عن طريق ملء النموذج أعلاه أو إرسال بريد إلكتروني إلى العناوين المدرجة في صفحة اتصل بنا.
                </div>
            </div>
        </div>
    );
};

export default AugnitoSubscriptionAr;