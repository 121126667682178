import React from 'react';

const FreeTrial = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="FreeTrial">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFreeTrial" aria-expanded="false" aria-controls="collapseFreeTrial">
                How does Augnito help clinicians derive value in an enterprise setup?
                </button>
            </h3>
            <div id="collapseFreeTrial" className="accordion-collapse collapse" aria-labelledby="FreeTrial" data-bs-parent="#faq">
                <div className="accordion-body">
                In an enterprise setting, Augnito's solutions provide scalable voice AI technology that enhances collaboration and efficiency in an individual provider’s workflow as well as orchestrating patient care across departments. We offer effortless integrations with all major EMRs, ensuring smooth data flows and compatibility. Deep integrations, as well as low code and no-code options, allow clinicians to record and transfer live data seamlessly. Our enterprise solutions are designed to support large-scale deployments with robust information and data security and compliance features. For more information on how Augnito’s solutions can be tailored to fit your enterprise workflows, including custom drug formularies and other bespoke databases, please contact our team. You can reach us by filling out the form above or emailing the addresses listed on our Contact Us page. 
                </div>
            </div>
        </div>
    );
};

export default FreeTrial;