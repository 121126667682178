import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const AboutUsMeta = () => {

    return (
        <Helmet>
            <title>About Augnito | Trusted Medical Voice AI for Global Healthcare</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Discover Augnito, the trusted leader in medical voice recognition technology. Our solutions are transforming clinical documentation for 450+ hospitals globally. Be part of the healthcare evolution. worldwide. Join thousands of healthcare providers in shaping the future of clinical efficiency." />
            <link rel="canonical" href={Constants.SITE_URL + "/about-us"} />
            <meta property="og:url" content={Constants.SITE_URL + "/about-us"} />
            <meta property="og:title" content="About Augnito | Trusted Medical Voice AI for Global Healthcare" />
            <meta property="og:description" content="Discover Augnito, the trusted leader in medical voice recognition technology. Our solutions are transforming clinical documentation for 450+ hospitals globally. Be part of the healthcare evolution. worldwide. Join thousands of healthcare providers in shaping the future of clinical efficiency." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/about-us"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="About Augnito | Trusted Medical Voice AI for Global Healthcare" />
            <meta name="twitter:description" content="Discover Augnito, the trusted leader in medical voice recognition technology. Our solutions are transforming clinical documentation for 450+ hospitals globally. Be part of the healthcare evolution. worldwide. Join thousands of healthcare providers in shaping the future of clinical efficiency." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default AboutUsMeta;
