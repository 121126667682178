import React from 'react';
import Pattren from '../../../Assets/images/Pattrens/footer.webp';
import { NavLink } from 'react-router-dom';

const WorkUs = () => {

    return (
       <section className='aug-perfec-solution'>
            <div className='container'>
                <div className='aug-perfec-solution-wrap'>
                    <div className='row align-items-center'>
                        <div className='col-lg-8 col-md-12 col-12'>
                            <div className='aug-perfec-solution-box'>
                                <h2 className='mb-3'>Work with us</h2>
                                <p>Be part of our mission to augment clinicians with the most optimal AI</p>
                                <NavLink to="/careers" className="btn btn-primary">Explore Careers</NavLink>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12'>
                            <div className='aug-perfec-solution__img'>
                                <img src={Pattren} alt="pattern" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default WorkUs;