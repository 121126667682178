import React from 'react';
import OutputVideoAr from '../../../Assets/images/Omni/ClinicalNote/generate-output.webm';
import OutputVideoArMp from '../../../Assets/images/Omni/ClinicalNote/generate-output.mp4';

const GenerateOutputAr = () => {
    return (
        <div dir='rtl' className='aug-efficient-documentation__item'>
            <div className="row align-items-center flex-lg-row flex-column-reverse">
                <div className='offset-lg-1 col-12 col-md-10 col-lg-5 col-xl-4 me-xl-auto'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoDictation' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                <source src={OutputVideoAr} type="video/webm" />
                                <source src={OutputVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 2</h6>
                        <h3 className='mb-3'>توليد الناتج</h3>
                        <p className='mb-0'>يقوم الذكاء الاصطناعي المدمج الخاص بنا بإنشاء مستند سريري منظم على الفور من المحادثة مع التاريخ الطبي، والشكوى الرئيسية، والتشخيص، وخطة الرعاية، والوصفة الطبية، ومواعيد المتابعة، وأكثر من ذلك بكثير اعتمادًا على التخصصات.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateOutputAr;