import React from 'react';
import { NavLink } from 'react-router-dom';
import requestImg from '../../../Assets/images/ContactUs/HelpYou/request-demo.webp';

const BookDemoAr = () => {
    return (
        <div dir='rtl' className='aug-card'>
            <img src={requestImg} alt="Doctor in white coat with stethoscope typing on laptop, green plants in background" className="w-100 img-fluid"/>
            <div className='aug-card-body'>
                <h3 className='aug-card-header mb-0'>طلب عرض توضيحي </h3>
                <p className='mb-0'>هل أنت مهتم بمنتجاتنا؟ <br/> شاهدها أثناء العمل.</p>
                <NavLink to="/ar/contact-sales" className='btn btn-primary text-primary'>طلب عرض توضيحي</NavLink>
            </div>
        </div>
    );
};

export default BookDemoAr;