import React from 'react';

const ContactBanner = () => {
    return (
        <section className='aug-legal-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h6 className="mb-2">Contact us</h6>
                        <h1 className='mb-3'>Learn more about Augnito’s solutions</h1>
                        <p className='mb-0'>Our team would be delighted to assist you.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactBanner;