import React from 'react';
import { ReactComponent as OfficeRiyadh } from '../../../Assets/images/ContactUs/our-offices/riyadh.svg';

const RiyadhAr = () => {
    return (
        <div dir='rtl' className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeRiyadh />
            </div>
            <h3 className='aug-our-offices__name'>الرياض، المملكة العربية السعودية</h3>
            <p className='mb-3'>
                بينما نعمل على إنشاء مكاتبنا في المنطقة، يمكنك التواصل معنا على</p>
            <a href="mailto:sales@augnito.ai"> sales@augnito.ai</a>
        </div>
    );
};

export default RiyadhAr;