import React from 'react';
import './OmniBanner.scss'
import OmniVideo from '../../../Assets/images/Omni/omni-banner.webm';
import OmniVideoMp from '../../../Assets/images/Omni/omni-banner.mp4';
import {ReactComponent as PattrenIcone} from '../../../Assets/images/icons/pattren-icone.svg';
import { NavLink } from 'react-router-dom';

const OmniBanner = () => {
    return (
        <section className='aug-omni-banner aug-spectra-banner'>
            <div className='container'>
                <div className="row gx-40">
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-banner-content'>
                            <h6 className='mb-2'><PattrenIcone className='me-2'/> Omni</h6>
                            <h1 className='mb-3'>Ambient Clinical Intelligence for Enhanced Documentation </h1>
                            <p>Powered by clinical intelligence, <span className='text-active'>Omni AI Scribe</span> enhances documentation by capturing multi-lingual medical dialogues and efficiently organizing data for integration into Electronic Medical Records.</p>
                            <NavLink to="/contact-sales" className='btn btn-primary'>Request a Demo</NavLink>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-video'>
                            <div className="">
                                <video width="100%" id='videoOmni' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                    <source src={OmniVideo} type="video/webm" />
                                    <source src={OmniVideoMp} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default OmniBanner;