import React from 'react';

const CareerBannerAr = () => {
    return (
    <section dir='rtl' className='aug-legal-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <h6 className='mb-2'>فرص العمل</h6>
                    <h1 className='mb-3'>انضم إلى فريق Augnito</h1>
                    <p className='mb-0'>نحن في مهمة لتسريع اعتماد التقنيات سريعة الاستخدام التي تعمل على تعزيز وتمكين الأطباء بشكل شامل. <span className='d-xl-block'> نؤمن بتمكين الإخلاص والإنسانية الذين لا مثيل لهما في رعاية المرضى - عبر سلسلة الرعاية الصحية بأكملها. </span></p>
                </div>
            </div>
        </div>
    </section>
    );
};

export default CareerBannerAr;