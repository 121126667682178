import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import ContactSalesBanner from './ContactSalesBanner/ContactSalesBanner';
import ContactSalesMeta from './Meta/ContactSalesMeta';
import ScheduleDemo from './ScheduleDemo/ScheduleDemo';
import FAQ from './FAQ/FAQ';
import CustomizedQuote from './CustomizedQuote/CustomizedQuote';
// import CustomerSay from '../Home/CustomerSay/CustomerSay';
// import CustomerSayME from '../Home/CustomerSay/CustomerSayME';
// import CustomerSayIN from '../Home/CustomerSay/CustomerSayIN';
// import OurPartner from '../Home/OurPartner/OurPartner';

export default class ContactSales extends BaseComponent {
    render() {
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ContactSalesMeta/>
                <ContactSalesBanner/>
                <ScheduleDemo/>
                {/* <OurPartner userRegionData={regionData}/> */}
                {/* {
                    regionData === "ME" ? <CustomerSayME/> :
                    regionData === "IN" ? <CustomerSayIN/> :
                    <CustomerSay />
                } */}
                <FAQ/>
                <CustomizedQuote/>
            </main>
        );
    }
}