import React from 'react';
import IntelligentVideoAr from '../../../Assets/images/Omni/ClinicalNote/intelligent-interoperability.webm';
import IntelligentVideoArMp from '../../../Assets/images/Omni/ClinicalNote/intelligent-interoperability.mp4';

const IntelligentInteroperabilityAr = () => {
    return (
        <div dir='rtl' className='aug-efficient-documentation__item'>
            <div className="row align-items-center flex-lg-row flex-column-reverse">
                <div className='offset-lg-1 col-12 col-md-10 col-lg-5 col-xl-4 me-xl-auto'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoDictation' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                <source src={IntelligentVideoAr} type="video/webm" />
                                <source src={IntelligentVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 4</h6>
                        <h3 className='mb-3'>التوافق الذكي</h3>
                        <p className='mb-0'>نقدم عمليات دمج عميقة بالإضافة إلى خيارات ذات أكواد منخفضة ودون أكواد، حتى تتمكن من سحب ودفع البيانات المباشرة بسلاسة. لن تكون هناك حاجة إلى نسخ ولصق. تقليل الوقت المستغرق في ملء أنظمة السجلات الطبية الإلكترونية لزيادة الوقت الذي تقضيه مع مرضاك.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntelligentInteroperabilityAr;