import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import ContactMetaAr from './Meta/ContactMetaAr';
import ContactBannerAr from './ContactBanner/ContactBannerAr';
import HelpYouAr from './HelpYou/HelpYouAr';
import OurOfficesAr from './OurOffices/OurOfficesAr';
import PerfectSolutionAr from '../Home/PerfectSolution/PerfectSolutionAr';

export default class ContactAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ContactMetaAr/>
                <ContactBannerAr/>
                <HelpYouAr/>
                <OurOfficesAr/>
                <PerfectSolutionAr/>
            </main>
        );
    }
}