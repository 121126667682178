import React from 'react';
import Pattrens from '../../../Assets/images/Pattrens/contact-sales.webp';
import './ContactSalesBanner.scss';

const ContactSalesBannerAr = () => {
    return (
        <section dir='rtl' className='aug-contact-sales'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className='aug-contact-sales-contant'>
                            <h6 className='mb-2'>اتصل بالمبيعات</h6>
                            <h1 className="mb-3 aug-bookdemo-banner-title">هل أنت مستعد لاستكشاف Augnito عن قرب؟</h1>
                            <p className='mb-0'>املأ النموذج للتواصل مع فريقنا للحصول على عرض أسعار خاص بـ Augnito وتعرف على <span className='d-lg-block'>خيارات التسعير المخصصة لدينا</span></p>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 me-auto">
                        <div className='aug-contact-sales__img'>
                            <img src={Pattrens} alt="pattrens" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSalesBannerAr;