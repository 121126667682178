import React from 'react';
import { ReactComponent as MacrosIcone } from '../../../Assets/images/icons/text-square.svg';

const ProductivityMacrosContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <MacrosIcone /> زيادة الإنتاجية باستخدام وحدات الماكرو 
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            حفظ الجمل أو الفقرات المتكررة على هيئة عبارات بسيطة، مما يوفر الوقت
تبسيط سير عملك السريري.
            </div>
        </div>
    );
};

export default ProductivityMacrosContentAr;