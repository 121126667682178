import React from 'react';
import { ReactComponent as DashboardIcone } from '../../../Assets/images/icons/dashboard-setting.svg';

const EasyIntegrationsContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                <DashboardIcone /> عمليات دمج سهلة عبر واجهات برمجة التطبيقات ومجموعات تطوير البرامج
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">  
            دمج التعرف على الكلام الطبي بسلاسة في تطبيقاتك السريرية الحالية
وسير العمل.
تتيح واجهات برمجة التطبيقات ومجموعات تطوير البرامج القوية لدينا للمطورين إضافة إمكانيات الذكاء الاصطناعي الصوتي
بسهولة إلى أي منصة أو برنامج، مما يعزز تجربة المستخدم والوظائف.
            </div>
        </div>
    );
};

export default EasyIntegrationsContentAr;