import React from 'react';
import Slider from "react-slick";

const LeadingPlatformsLogoME = () => {

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: false,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        cssEase: 'linear',
        autoplaySpeed: 0,
        speed: 4000,
        className: 'aug-leading-platforms-slider',
    };

    return (
        <Slider {...settings}>
            <ul className='list-unstyled mb-0'>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/ramsoft.webp')} className="img-fluid" alt="Ramsoft logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/fujifilm.webp')} className="img-fluid" alt="FujiFilm logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/paxera.webp')} className="img-fluid" alt="Paxera Health logo" />
                </li>
            </ul>
            <ul className='list-unstyled mb-0'>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/schema.webp')} className="img-fluid" alt="Schema HIS logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/medinuous.webp')} className="img-fluid" alt="Medinous logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/exsys.webp')} className="img-fluid" alt="Exsys Solutions logo" />
                </li>
            </ul>
            <ul className='list-unstyled mb-0'>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/everrtech.webp')} className="img-fluid" alt="Everrtech logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/intelerad.webp')} className="img-fluid" alt="Intelerad logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/cimar.webp')} className="img-fluid" alt="Cimar logo" />
                </li>
            </ul>
            <ul className='list-unstyled mb-0'>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/adva.webp')} className="img-fluid" alt="Adva Solution logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/capri.webp')} className="img-fluid" alt="Capri Healthcare logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/scanaptics.webp')} className="img-fluid" alt="Scanaptics logo" />
                </li>
            </ul>
            <ul className='list-unstyled mb-0'>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/ge.webp')} className="img-fluid" alt="ge logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/cloudsolutions.webp')} className="img-fluid" alt="Cloud Solutions logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/fakeeh.webp')} className="img-fluid" alt="Fakeeh Tech logo" />
                </li>
            </ul>
            <ul className='list-unstyled mb-0'>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/magentus.webp')} className="img-fluid" alt="Magentus logo" />
                </li>
                <li>
                    <img src={require('../../../Assets/images/Integrations/allregion/chartnote.webp')} className="img-fluid" alt="ChartNote logo" />
                </li>
                <li>

                </li>
            </ul>
        </Slider>
    );
};

export default LeadingPlatformsLogoME;