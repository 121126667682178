import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageCard.scss';

const PageCard = () => {
    return (
        <section className='aug-page-card'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/terms-of-use">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Terms of Use (Retail and Authorised Users)</h3>
                                <p className='mb-0'>These terms apply to individuals accessing our services through the website or applications for personal use by a single user, based on the selected subscription plan. They also govern individuals who have purchased the services via our authorized distributors or resellers.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/augnito-privacy-policy">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Privacy Policy </h3>
                                <p className='mb-0'>Our policy on what information we collect at Augnito, how we use it and what choices you have.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/augnito-cookie-policy">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Cookie Policy</h3>
                                <p className='mb-0'>A list of all the cookies and trackers implemented to improve or customize our products and your experience.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/augnito-data-breach-policy">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Data Breach Policy</h3>
                                <p className='mb-0'>Details about the cybersecurity and data privacy frameworks and response mechanisms.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/augnito-data-retention-policy">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Data Retention Policy</h3>
                                <p className='mb-0'>Why and how we store your data, the duration it is kept for, as well as data disposal procedures.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/api-sdk-agreement">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>API & SDK License Agreement</h3>
                                <p className='mb-0'>API & SDK license agreement. Outlining the terms of each party’s rights and usage of the software.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/subscription-terms">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Subscription Terms & Conditions</h3>
                                <p className='mb-0'>The parameters of your subscription. Payment terms, duration, limitations of liability, and more.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/refund-cancellation-policy">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Refund and Cancellation Policy</h3>
                                <p className='mb-0'>Guidelines for termination and renewal of your subscription. Criteria and regulations for rebates.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/referral-policy">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Referral Policy</h3>
                                <p className='mb-0'>Benefits and parameters of the customer referral policy and implementation of referral codes.</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/augnito-data-processing-terms">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Data Processing Terms</h3>
                                <p className='mb-0'>Our policy on how we process your data including personal information and the rights you have to safeguard your data</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/software-service-agreement">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Software Service Agreement</h3>
                                <p className='mb-0'>Applicable to Enterprise Clients or organizations that purchase Augnito Services for their own use typically for operational needs or internal business purposes for use by their authorised users</p>
                            </div>
                        </NavLink>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 aug-page-card__item'>
                        <NavLink to="/legal/software-license-agreement">
                            <div className='aug-page-card__box'>
                                <h3 className='mb-3'>Software License Agreement</h3>
                                <p className='mb-0'>Applicable to Enterprise Clients or organizations that purchase on premises Augnito Services for their own use typically for operational needs or internal business purposes for use by their  authorised users</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageCard;