import React from 'react';
import ActivateVideoArMp from '../../../Assets/images/Spectra/EfficientDocumentation/activate-spectra.mp4';
import ActivateVideoAr from '../../../Assets/images/Spectra/EfficientDocumentation/activate-spectra.webm';

const ActivateSpectraAr = () => {
    return (
        <div dir='rtl' className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='offset-md-1 col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 1</h6>
                        <h3 className='mb-3'>تفعيل Spectra</h3>
                        <p className='mb-0'>انقر واسحب لتعديل موضع أداتنا العائمة الأنيقة على جهازك.فقط اضغط أو استخدم الأوامر الصوتية لتمكين الميكروفون أو تعطيله.</p>
                    </div>
                </div>
                <div className='col-12 col-md-10 col-lg-5'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="">
                            <video width="100%" id='videoActivate' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                                <source src={ActivateVideoAr} type="video/webm" />
                                <source src={ActivateVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivateSpectraAr;