import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import DynamicTemplatesContent from './DynamicTemplatesContent';
import VoiceCommandsTab from './VoiceCommandsTab';
import ProductivityMacrosTab from './ProductivityMacrosTab';
import CustomVocabularyTab from './CustomVocabularyTab';
import EasyIntegrationsTab from './EasyIntegrationsTab';
import VoiceCommandsContent from './VoiceCommandsContent';
import ProductivityMacrosContent from './ProductivityMacrosContent';
import CustomVocabularyContent from './CustomVocabularyContent';
import EasyIntegrationsContent from './EasyIntegrationsContent';
import DynamicTemplateTab from './DynamicTemplateTab';
import ClinicianBuiltMobile from './ClinicianBuiltMobile';

export default class ClinicianBuilt extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-empowering-clinicians pt-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h2 className='aug-career__title'>Clinician-Designed & Purpose-Built</h2>
                        </div>
                    </div>
                    <div className='aug-empowering-clinicians__tab'>
                        <div className='d-none d-lg-block'>
                            <div className='row gx-80'>
                                <div className='col-lg-6'>
                                    <div className="tab-content" id="empoweringClinicians-tabContent">
                                        <div className="tab-pane fade show active" id="physicianBurnout" role="tabpanel" aria-labelledby="physicianBurnout-tab" tabIndex="0">
                                            <DynamicTemplateTab/>
                                        </div>
                                        <div className="tab-pane fade" id="patientEngagement" role="tabpanel" aria-labelledby="patientEngagement-tab" tabIndex="0">
                                            <VoiceCommandsTab/>
                                        </div>
                                        <div className="tab-pane fade" id="claimDenial" role="tabpanel" aria-labelledby="claimDenial-tab" tabIndex="0">
                                            <ProductivityMacrosTab/>
                                        </div>
                                        <div className="tab-pane fade" id="simplifiedDeployment" role="tabpanel" aria-labelledby="simplifiedDeployment-tab" tabIndex="0">
                                            <CustomVocabularyTab/>
                                        </div>
                                        <div className="tab-pane fade" id="customerSuccess" role="tabpanel" aria-labelledby="customerSuccess-tab" tabIndex="0">
                                            <EasyIntegrationsTab/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="nav flex-column nav-pills" id="empoweringClinicians-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link mt-0 active" id="physicianBurnout-tab" data-bs-toggle="pill" data-bs-target="#physicianBurnout" type="button" role="tab" aria-controls="physicianBurnout" aria-selected="true">
                                            <DynamicTemplatesContent/>
                                        </button>
                                        <button className="nav-link" id="patientEngagement-tab" data-bs-toggle="pill" data-bs-target="#patientEngagement" type="button" role="tab" aria-controls="patientEngagement" aria-selected="false">
                                             <VoiceCommandsContent/>
                                        </button>

                                        <button className="nav-link" id="claimDenial-tab" data-bs-toggle="pill" data-bs-target="#claimDenial" type="button" role="tab" aria-controls="claimDenial" aria-selected="false">
                                            <ProductivityMacrosContent/>
                                        </button>
                                        <button className="nav-link" id="simplifiedDeployment-tab" data-bs-toggle="pill" data-bs-target="#simplifiedDeployment" type="button" role="tab" aria-controls="simplifiedDeployment" aria-selected="false">
                                            <CustomVocabularyContent/>
                                        </button>
                                        <button className="nav-link" id="customerSuccess-tab" data-bs-toggle="pill" data-bs-target="#customerSuccess" type="button" role="tab" aria-controls="customerSuccess" aria-selected="false">
                                            <EasyIntegrationsContent/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                           <ClinicianBuiltMobile/>
                        </div>


                    </div>
                </div>
            </section>
        );
    };
};