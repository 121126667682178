import React from 'react';
import {ReactComponent as OfficeDubai} from '../../../Assets/images/ContactUs/our-offices/dubai.svg';

const Dubai = () => {
    return (
        <div className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeDubai/>
            </div>
            <h3 className='aug-our-offices__name'>Dubai, UAE</h3>
            <p className='mb-3'>Unit IH-00-VZ-01-FL-148, Level 1, Innovation Hub Virtual Inventory, Dubai International Financial Centre</p>
            <a href="mailto:sales@augnito.ai"> sales@augnito.ai</a>
        </div>
    );
};

export default Dubai;