import React from 'react';
import BaseComponent from '../../Common/BaseComponent';
import SpectraMetaAr from './Meta/SpectraMetaAr';
import SpectraBannerAr from './SpectraBanner/SpectraBannerAr';
// import TrustedByAr from './TrustedBy/TrustedByAr';
import TrySpectraAr from './TrySpectra/TrySpectraAr';
// import CustomerSayAr from '../Home/CustomerSay/CustomerSayAr';
import WhySpectraAr from './WhySpectra/WhySpectraAr';
// import ClinicalPlatformsAr from './ClinicalPlatforms/ClinicalPlatformsAr';
import EfficientDocumentationAr from './EfficientDocumentation/EfficientDocumentationAr';
import ClinicianBuiltAr from './ClinicianBuilt/ClinicianBuiltAr';

export default class SpectraAr extends BaseComponent {
    
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <SpectraMetaAr/>
                <SpectraBannerAr/>
                {/* <TrustedByAr/> */}
                <EfficientDocumentationAr/>
                {/* <ClinicalPlatformsAr/> */}
                <ClinicianBuiltAr/>
                <WhySpectraAr/>
                {/* <CustomerSayAr/> */}
                <TrySpectraAr/>
            </main>
        );
    }
}