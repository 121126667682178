import React from 'react';
import './WhySpectra.scss';
import EMRDocumentationAr from './EMRDocumentationAr';
import SeamlessInteroperabilityAr from './SeamlessInteroperabilityAr';
import CustomizableTemplatesAr from './CustomizableTemplatesAr';
import EnhancedDictationAr from './EnhancedDictationAr';
import ClaimDenialsAr from './ClaimDenialsAr';
import BillingLevelsAr from './BillingLevelsAr';


const WhyOmniAr = () => {
    return (
        <section dir='rtl' className='aug-why-spectra aug-augnito-difference'>
            <div className='container'>
                <h2 className='aug-career__title'>لماذا  Omni؟</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <EMRDocumentationAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <SeamlessInteroperabilityAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <CustomizableTemplatesAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <EnhancedDictationAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ClaimDenialsAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <BillingLevelsAr/>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default WhyOmniAr;