import React from 'react';
import './WhySpectra.scss';
import FlexibleDeployments from './FlexibleDeployments';
import ProvenROI from './ProvenROI';
import ZeroVoice from './ZeroVoice';
import EnhancedDictation from './EnhancedDictation';
import RobustCompatibility from './RobustCompatibility';
import ClinicalSpecialties from './ClinicalSpecialties';


const WhySpectra = () => {
    return (
        <section className='aug-why-spectra aug-augnito-difference'>
            <div className='container'>
                <h2 className='aug-career__title'>Why Spectra?</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <EnhancedDictation/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <RobustCompatibility/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ClinicalSpecialties/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ZeroVoice/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ProvenROI/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <FlexibleDeployments/>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default WhySpectra;
