import React from 'react';
import {ReactComponent as FlexibleTimeIcone} from '../../../Assets/images/icons/flexible-time.svg';

const FlexibleTime = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <FlexibleTimeIcone/>
            </div>
            <h3 className='mb-2'>Flexible Time Off</h3>
            <p className='mb-0'>Enjoy unlimited paid leave, allowing you to take the time you need to recharge without the constraints of traditional policies.</p>
        </div>
    );
};

export default FlexibleTime;