import React from 'react';
import physicianBurnout from '../../../Assets/images/home/EmpoweringClinicians/physician-burnout.webp';

const PhysicianBurnoutTabAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={physicianBurnout} alt="Physician using Augnito medical speech recognition software on desktop" className="img-fluid" />
        </div>
    );
};

export default PhysicianBurnoutTabAr;