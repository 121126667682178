import React from 'react';


const CareerBanner = () => {
    return (
        <section className='aug-legal-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h6 className='mb-2'>Careers</h6>
                        <h1 className='mb-3'>Join the Augnito Team</h1>
                        <p className='mb-0'>We are on a mission to accelerate the adoption of intuitive technologies that augment and empower clinicians holistically. <span className='d-xl-block'> We believe in enabling unparalleled fidelity and humanity in patient care – across the entire healthcare continuum. </span> </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CareerBanner;