import React from 'react';
import DictationVideoAr from '../../../Assets/images/Spectra/EfficientDocumentation/begin-dictation.webm';
import DictationVideoArMp from '../../../Assets/images/Spectra/EfficientDocumentation/begin-dictation.mp4';

const BeginDictationAr = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center flex-lg-row flex-column-reverse">
                <div className='offset-lg-1 col-12 col-md-10 col-lg-5'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="">
                            <video width="100%" id='videoDictation' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                                <source src={DictationVideoAr} type="video/webm" />
                                <source src={DictationVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 2</h6>
                        <h3 className='mb-3'>ابدأ الإملاء</h3>
                        <p className='mb-0'>تحدث بشكل طبيعي، بينما يقوم Spectra بتسجيل ملاحظاتك في المحرر الذكي المدمج أو مباشرة في أي نظام معلومات مستشفى (HIS) أو سجلات طبية إلكترونية (EMR) أو نظام اتصالات وأرشفة صور (PACS) أو نظام معلومات أشعة (RIS) لجهة خارجية.حلنا دقيق بنسبة 99% وجاهز للاستخدام،مع دعم لأكثر من 55 تخصصًا طبيًا وتخصصًا فرعيًا.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BeginDictationAr;