import React from 'react';
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from '../../../Assets/images/logo-white.svg';
import { ScrollTop } from '../../../Common/Utils';

const FooterLogo = () => {
    return (
        <div className='aug-footer-logo'>
            <div className='row'>
                <div className='col-12'>
                    <NavLink to="/" onClick={ScrollTop}>
                        <Logo height="25" width="155" />
                    </NavLink>
                </div>
                <div className='col-12'>
                    <div className='aug-brand-logo'>
                        <ul className='list-unstyled mb-0 d-flex align-items-center flex-wrap'>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/iso-27001.webp')} className="img-fluid" height={48} width={48} alt="iso27001 logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/aicpa.webp')} className="img-fluid" height={48} width={48} alt="aicpa soc2 logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/gdpr.webp')} className="img-fluid" height={48} width={48} alt="gdpr logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/hipaa.webp')} className="img-fluid" height={48} width={79} alt="hipaa logo"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterLogo;