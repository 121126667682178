import React from 'react';
import { ReactComponent as ClaimIcone } from '../../../Assets/images/icons/claim-denials.svg';

const ClaimDenialContent = () => {

    return (

        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <ClaimIcone /> Reduce Claim Denials
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Boost top and bottom-line with more comprehensive clinical notes for claims processing. Enhance CDI at source with CAPD and automated clinical coding capabilities (SNOMED, ICD-10, CPT, SBS).
            </div>
        </div>

    );
};

export default ClaimDenialContent;