import React from 'react';
import {ReactComponent as AcceleratedGrowthIcone} from '../../../Assets/images/icons/accelerated-growth.svg';

const AcceleratedGrowthAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <AcceleratedGrowthIcone/>
            </div>
            <h3 className='mb-2'>فرص نمو متسارع</h3>
            <p className='mb-0'>يتيح فريق Augnito الديناميكي التعرض والتطوير غير المسبوق للأفراد عبر التخصصات.</p>
        </div>
    );
};

export default AcceleratedGrowthAr;