import React from 'react';
import {ReactComponent as CustomerSuccessIcone} from '../../../Assets/images/icons/customer-success.svg';

const CustomerSuccessAr = () => {
    return (
        <div dir='rtl' className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <CustomerSuccessIcone/>
            </div>
            <h3 className='mb-0'>نجاح العملاء</h3>
            <p className='mb-0'>نحن نركز على فهم احتياجات عملائنا وتقديم الحلول التي تدفع النمو وتعزز الشراكات الدائمة.</p>
        </div>
    );
};

export default CustomerSuccessAr;