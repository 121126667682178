import React from 'react';
import './OurPartner.scss';
import BaseComponent from '../../../Common/BaseComponent';
import OurPartnerLogoIN from './OurPartnerLogoIN';
import OurPartnerLogoME from './OurPartnerLogoME';
import OurPartnerLogo from './OurPartnerLogo';

export default class OurPartner extends BaseComponent {

    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-our-partner'>
                <h2>Trusted by <br/><span>500+ Healthcare Institutions</span> across <br/> <span>25+ Countries</span></h2>
                {
                    regionData === "IN" ? <OurPartnerLogoIN/> :
                    regionData === "ME" ? <OurPartnerLogoME/>  :
                    <OurPartnerLogo/>
                }
            </section>
        );
    };
};
