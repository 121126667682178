import React from 'react';
import './OurSolution.scss';
import {ReactComponent as Star} from '../../../Assets/images/icons/star-single.svg';
import {ReactComponent as ProductIcone} from '../../../Assets/images/icons/product-icon.svg';
import { NavLink } from 'react-router-dom';

const OurSolutionAr = () => {

    return (
        <section dir='rtl' className='aug-ou-solution'>
            <div className='container'>
                <h3 className='aug-ou-solution__title'>حلولنا</h3>
                <div className='row gx-80'>
                    <div className='col-lg-6 col-12'>
                       <div className='aug-ou-solution__item'>
                            <div className='d-flex align-items-center mb-4'>
                                <ProductIcone/>
                                <h3 className='aug-ou-solution__item-title mb-0'>Omni</h3>
                            </div>
                            <p className='mb-md-5 mb-4 pb-2 pb-md-0'>ذكاء سريري محيطي مع التقاط محادثات متعددة اللغات وهيكلة  التفاعل مع المرضى بكفاءة وإنشاء مستندات طبية من حوار طبيعي. البيانات لتكاملها بسلاسة في سجلات المرضى الإلكترونية. </p>
                            <h4 className='aug-ou-solution__item-list-title'>الميزات البارزة: </h4>
                            <ul className='list-unstyled pe-0 aug-ou-solution__item-list'>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' /> 
                                    التقاط المحادثات السريرية في الوقت الفعلي 
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    نقل البيانات بسهولة إلى أنظمة السجلات الطبية الإلكترونية
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    قوالب توثيق قابلة للتخصيص 
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    دعم عالمي للغات واللهجات
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    تكامل سهل عبر واجهات برمجة التطبيقات ومجموعات تطوير البرامج
                                </li>
                                <li className='d-flex align-items-center'>
                                    <Star height="16" width="16" className='ms-2' />
                                    أمان وامتثال على مستوى المؤسسات
                                </li>
                            </ul>
                            <NavLink to="/ar/omni" className="btn btn-primary w-100">اعرف المزيد</NavLink>
                       </div> 
                    </div>
                    <div className='col-lg-6 col-12'>
                        <div className='aug-ou-solution__item mb-0'>
                            <div className='d-flex align-items-center mb-4'>
                                <ProductIcone/>
                                <h3 className='aug-ou-solution__item-title mb-0'>Spectra</h3>
                            </div>
                            <p className='mb-5'>حل التدوين الطبي الرائد في الصناعة. باستخدام التعرف التلقائي على الصوت ومعالجة اللغة الطبيعية لتبسيط سير العمل السريري، تلتقط أداتنا سهلة الاستخدام ملاحظات المرضى في ثوانٍ، مما يزيد من الإنتاجية ويحسن رعاية المرضى.  </p>
                            <h4 className='aug-ou-solution__item-list-title'>الميزات البارزة: </h4>
                            <ul className='list-unstyled pe-0 aug-ou-solution__item-list'>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' /> 
                                    دقة بنسبة 99% من الاستخدام الأول
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    محسّن لمختلف اللهجات العالمية
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    زيادة الإنتاجية باستخدام الأوامر المختصرة
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    تقارير مخصصة باستخدام مفردات مخصصة
                                </li>
                                <li className='d-flex align-items-center mb-2'>
                                    <Star height="16" width="16" className='ms-2' />
                                    تكامل سهل عبر واجهات برمجة التطبيقات ومجموعات تطوير البرامج
                                </li>
                                <li className='d-flex align-items-center'>
                                    <Star height="16" width="16" className='ms-2' />
                                    أمان وامتثال على مستوى المؤسسات
                                </li>
                            </ul>
                            <NavLink to="/ar/spectra" className="btn btn-primary w-100">اعرف المزيد</NavLink>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    );
};


export default OurSolutionAr;