import React from 'react';
import {ReactComponent as PrecisionIcone} from '../../../Assets/images/icons/precision.svg';

const Precision = () => {
    return (
        <div className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <PrecisionIcone/>
            </div>
            <h3 className='mb-0'>Precision</h3>
            <p className='mb-0'>We undertake any task with thoroughness, accuracy and sharpness, born from both experience and ability.</p>
        </div>
    );
};

export default Precision;