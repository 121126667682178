import React from 'react';
import { ReactComponent as BurnoutIcone } from '../../../Assets/images/icons/physician-burnout.svg';

const PhysicianBurnoutContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <BurnoutIcone /> Mitigate Physician Burnout
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
                Streamline clinical workflows and create structured documentation via an intuitive voice interface. With medical voice AI, users are more productive, experience less fatigue, and spend more face time with patients.
            </div>
        </div>
    );
};

export default PhysicianBurnoutContent;