import React from 'react';
import {ReactComponent as OfficeUSA} from '../../../Assets/images/ContactUs/our-offices/usa.svg';

const USAAr= () => {
    return (
        <div dir='rtl' className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeUSA/>
            </div>
            <h3 className='aug-our-offices__name'>الولايات المتحدة الأمريكية</h3>
            <p className='mb-3'>ص. صندوق بريد 332 سيوكس فولز، مينيهاها داكوتا الجنوبية 57101
            </p>
            <a dir='ltr' href="tel:+18003907163" className='text-end'>+ 1-800-390-7163</a>
        </div>
    );
};

export default USAAr;