import React from 'react';
import { ReactComponent as CodeIcone } from '../../../Assets/images/icons/connect-collaborate.svg';

const CodeRetrievalContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <CodeIcone /> استرجاع الكود تلقائيًا
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            يعمل Omni على تبسيط عمليات الترميز السريري من خلال التحليل الذكي للوثائق
تعيين التلقائي للأكواد ICD 10 أو SNOMED أو SBS ذات الصلة مما يقلل من مخاطر الخطأ
ويضمن الامتثال للوائح.
            </div>
        </div>
    );
};

export default CodeRetrievalContentAr;