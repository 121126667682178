import React from 'react';
import {ReactComponent as SeamlessInteroperabilityIcone} from '../../../Assets/images/icons/dashboard-setting.svg';

const SeamlessInteroperability = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <SeamlessInteroperabilityIcone/>
            </div>
            <h3 className='mb-2'>Seamless Interoperability</h3>
            <p className='mb-0'>Omni integrates effortlessly with all major EMRs, ensuring smooth data flows and compatibility. Deep integrations, as well as low code and no-code options, allow clinicians to record and transfer live data seamlessly.</p>
        </div>
    );
};

export default SeamlessInteroperability;