import React from 'react';
import ActivateVideoAr from '../../../Assets/images/Omni/ClinicalNote/activate-omni.webm';
import ActivateVideoArMp from '../../../Assets/images/Omni/ClinicalNote/activate-omni.mp4';

const ActivateOmniAr = () => {
    return (
        <div dir='rtl' className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='offset-lg-1 col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 1</h6>
                        <h3 className='mb-3'>تفعيل Omni</h3>
                        <p className='mb-0'>تفاعل مع المرضى بشكل طبيعي، بينما يسجل برنامج الذكاء السريري المحيطي الخاص بنا آنيًا، مع دقة على مستوى المؤسسات ودعم لأكثر من 37 لغة.</p>
                    </div>
                </div>
                <div className='col-12 col-md-10 col-lg-5 col-xl-4 mx-auto mx-lg-0'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoActivate' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                <source src={ActivateVideoAr} type="video/webm" />
                                <source src={ActivateVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivateOmniAr;