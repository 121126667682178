import React from 'react';
import aIDriven from '../../../Assets/images/Omni/ClinicianDesigned/ai-driven.webp';

const AIDrivenTab = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={aIDriven} loading="lazy" alt="Smiling doctor with curly hair reviewing digital patient data on tablet, brain scan visible on monitor behind" className="img-fluid" />
        </div>
    );
};

export default AIDrivenTab;