import React from 'react';
import {ReactComponent as ProvenROIIcone} from '../../../Assets/images/icons/competitive-compensation.svg';

const ProvenROIAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ProvenROIIcone/>
            </div>
            <h3 className='mb-2'>عائد استثمار مُثبت</h3>
            <p className='mb-0'>يقدم Spectra عائدًا كبيرًا على الاستثمار في فترة زمنية قصيرة؛لقد أظهر القدرة على توليد العائد 21 مرة في 6 أشهر فقط.</p>
        </div>
    );
};

export default ProvenROIAr;