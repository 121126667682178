import React from 'react';
import claimDenial from '../../../Assets/images/home/EmpoweringClinicians/reduced-claim.webp';

const ClaimDenialTab = () => {

  return (
    <div className='aug-empowering-clinicians__accordion-img'>
        <img src={claimDenial} alt="Healthcare professionals reviewing medical charts and claim documents with pens, collaborating on patient data management" className="img-fluid" />
    </div>
  );
};

export default ClaimDenialTab;