import React from 'react';
import { ReactComponent as MacrosIcone } from '../../../Assets/images/icons/text-square.svg';

const ProductivityMacrosContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <MacrosIcone /> Increased Productivity with Macros 
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
                Save frequently repeating sentences or paragraphs as simple phrases, saving time and streamlining your clinical workflows.
            </div>
        </div>
    );
};

export default ProductivityMacrosContent;