import React from 'react';
import {ReactComponent as FlexibleDeploymentsIcone} from '../../../Assets/images/icons/accelerated-growth.svg';

const FlexibleDeployments = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <FlexibleDeploymentsIcone/>
            </div>
            <h3 className='mb-2'>Flexible Deployments</h3>
            <p className='mb-0'>Spectra enables enterprises to choose between a secure, cloud-based SaaS solution or an on-premises deployment. Both options ensure industry standard compliance and data localization.</p>
        </div>
    );
};

export default FlexibleDeployments;