import React from 'react';
import './ConversationsClinical.scss';
import {ReactComponent as Line} from '../../../Assets/images/icons/line.svg';

const ConversationsClinical = () => {

    return (
        <section className='aug-conversations-clinical'>
            <div className='container'>
                <div className='row gx-40'>
                    <div className='col-lg-5 col-12 mb-md-1 mb-lg-0'>
                        <h3 className='aug-conversations-clinical__title'>Turn Conversations into Clinical Records</h3>
                        <p className='aug-conversations-clinical__text mb-5 pb-md-2 pb-lg-0'>Effortless clinical documentation and EMR usability with intuitive Voice AI. Our Ambient Clinical Intelligence instantly generates structured medical reports, transforming the clinician-patient experience.</p>
                    </div>
                    <div className='col-lg-7 col-12'>
                        <div className="row">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='ps-1 ms-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            20+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>Years of HealthTech Experience</p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='ps-1 ms-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            55+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>Medical Specialties Supported</p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='ps-1 ms-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            71
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>Net Promoter Score</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='ps-1 ms-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            21x
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>Proven ROI in Just Six Months</p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='ps-1 ms-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            37+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>Languages for Conversation Capture</p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='ps-1 ms-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            15000+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>Clinicians Trust Augnito Globally</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


export default ConversationsClinical;