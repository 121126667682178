import React from 'react';
import './FAQ.scss';
import AugnitoCostAr from './AugnitoCostAr';
import AugnitoSubscriptionAr from './AugnitoSubscriptionAr';
import FreeTrialAr from './FreeTrialAr';
import AugnitoPricingAr from './AugnitoPricingAr';
import ImplementationCostAr from './ImplementationCostAr';
// import ROIAr from './ROIAr';

const FAQAr = () => {
    return (
       <section dir='rtl' className='aug-faq'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='aug-faq-title mb-60 mt-60'>الأسئلة المتكررة</h2>
                        <div className="accordion aug-accordion" id="faq">
                            <AugnitoCostAr/>
                            <AugnitoSubscriptionAr/>
                            <FreeTrialAr/>
                            <AugnitoPricingAr/>
                            <ImplementationCostAr/>
                            {/* <ROIAr/> */}
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default FAQAr;