import React from 'react';
import {ReactComponent as FlexibleDeploymentsIcone} from '../../../Assets/images/icons/accelerated-growth.svg';

const FlexibleDeploymentsAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <FlexibleDeploymentsIcone/>
            </div>
            <h3 className='mb-2'>نشر مرن</h3>
            <p className='mb-0'>يتيح Spectra للمؤسسات الاختيار بين حل البرمجيات كخدمة (SaaS) آمن قائم على السحابة أوالنشر محليًا.يضمن كلا الخيارين الامتثال لمعايير الصناعة وتوطينالبيانات.</p>
        </div>
    );
};

export default FlexibleDeploymentsAr;