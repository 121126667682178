import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import { ReactComponent as TemplatesIcone } from '../../../Assets/images/icons/google-docs.svg';
import { ReactComponent as VoiceIcone } from '../../../Assets/images/icons/voice-check.svg';
import { ReactComponent as MacrosIcone } from '../../../Assets/images/icons/text-square.svg';
import { ReactComponent as DashboardIcone } from '../../../Assets/images/icons/dashboard-setting.svg';
import { ReactComponent as MacrosIconeIcone } from '../../../Assets/images/icons/book-line.svg';
import DynamicTemplateTab from './DynamicTemplateTab';
import VoiceCommandsTab from './VoiceCommandsTab';
import ProductivityMacrosTab from './ProductivityMacrosTab';
import CustomVocabularyTab from './CustomVocabularyTab';
import EasyIntegrationsTab from './EasyIntegrationsTab';


export default class ClinicianBuiltMobile extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (

            <div className="accordion" id="accordionClinicianBuilt">
                <div className="accordion-item">
                    <div className='accordion-item-content' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <TemplatesIcone /> Dynamic Templates
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Upload predefined templates for widely used clinical note types like Imaging Reports, Discharge Summaries, SOAP notes, and many more. Easily open, navigate, dictate, and edit clinical reports using just your voice.
                        </div>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <DynamicTemplateTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <VoiceIcone /> Intuitive Voice Commands
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Hands-free navigation with simple spoken instructions. Control your system and data effortlessly using natural language, allowing you to multitask and work more efficiently without touching a keyboard or mouse.
                        </div>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <VoiceCommandsTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <MacrosIcone /> Increased Productivity with Macros
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Save frequently repeating sentences or paragraphs as simple phrases, saving time and streamlining your clinical workflows.
                        </div>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <ProductivityMacrosTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <MacrosIconeIcone /> Personalization with Custom Vocabulary
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Optimize the system to your specific needs by filling in details like preferred spellings and pronunciations of specialty-specific terms, acronyms, drug names, and more, to ensure more accurate and relevant transcription.
                        </div>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <CustomVocabularyTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item border-0">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                            <DashboardIcone /> Easy Integrations via APIs & SDKs
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Seamlessly incorporate medical speech recognition into your existing clinical applications and workflows. Our robust APIs and SDKs enable developers to easily add voice AI capabilities to any platform or software, enhancing user experience and functionality.
                        </div>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <EasyIntegrationsTab />
                        </div>
                    </div>
                </div>
            </div>

        );
    };
};