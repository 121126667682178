import React from 'react';
import './LeadingPlatforms.scss';
import Slider from "react-slick";
import { RedirectToDeveloper } from '../../../Common/Utils';

const LeadingPlatformsAr = () => {

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: false,
        slidesToScroll: 1,
        variableWidth: true,
        rtl: true,
        arrows: false,
        cssEase: 'linear',
        autoplaySpeed: 0,
        speed: 4000,
        className: 'aug-leading-platforms-slider',
    };

    return (
        <section dir='rtl' className='aug-leading-platforms'>
            <div className='container'>
                <div className='aug-leading-platforms-wrap border-top'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-leading-platforms-box'>
                                <h2 className='mb-3'>متوافق مع المنصات السريرية الرائدة </h2>
                                <p className='mb-0'>تكامل عميق مع المنصات السريرية العالمية الرائدة، بالإضافة إلى خيارات منخفضة ومنعدمة الرموز لنقل البيانات والتدوين المباشر في أي نظام معلومات صحي/سجلات طبية إلكترونية/نظام تخزين الصور الطبية/نظام معلومات الأشعة </p>
                                <button type='button' onClick={RedirectToDeveloper} className='btn btn-primary'>اعرف المزيد</button>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-leading-platforms__img'>
                                <Slider {...settings}>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/ramsoft.webp')} className="img-fluid" alt="Ramsoft logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/fujifilm.webp')} className="img-fluid" alt="FujiFilm logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/paxera.webp')} className="img-fluid" alt="Paxera Health logo" />
                                        </li>
                                    </ul>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/schema.webp')} className="img-fluid" alt="Schema HIS logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/medinuous.webp')} className="img-fluid" alt="Medinous logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/exsys.webp')} className="img-fluid" alt="Exsys Solutions logo" />
                                        </li>
                                    </ul>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/everrtech.webp')} className="img-fluid" alt="Everrtech logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/intelerad.webp')} className="img-fluid" alt="Intelerad logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/cimar.webp')} className="img-fluid" alt="Cimar logo" />
                                        </li>
                                    </ul>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/adva.webp')} className="img-fluid" alt="Adva Solution logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/capri.webp')} className="img-fluid" alt="Capri Healthcare logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/scanaptics.webp')} className="img-fluid" alt="Scanaptics logo" />
                                        </li>
                                    </ul>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/ge.webp')} className="img-fluid" alt="ge logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/cloudsolutions.webp')} className="img-fluid" alt="Cloud Solutions logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/fakeeh.webp')} className="img-fluid" alt="Fakeeh Tech logo" />
                                        </li>
                                    </ul>
                                    <ul className='list-unstyled mb-0'>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/magentus.webp')} className="img-fluid" alt="Magentus logo" />
                                        </li>
                                        <li>
                                            <img src={require('../../../Assets/images/Integrations/allregion/chartnote.webp')} className="img-fluid" alt="ChartNote logo" />
                                        </li>
                                        <li>

                                        </li>
                                    </ul>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LeadingPlatformsAr;