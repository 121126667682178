import React from 'react';
import { ReactComponent as DeploymentIcone } from '../../../Assets/images/icons/simplified-deployment.svg';

const SimplifiedDeploymentContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <DeploymentIcone /> Simplified Deployment
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Secure, simple, and out-of-the-box ready solutions that are easy to deploy and manage at any scale. No infrastructure or hardware costs. Plug-and-play APIs & SDKs for streamlined integrations.
            </div>
        </div>
    );
};

export default SimplifiedDeploymentContent;