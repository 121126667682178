import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import DynamicTemplatesContentAr from './DynamicTemplatesContentAr';
import VoiceCommandsTabAr from './VoiceCommandsTabAr';
import ProductivityMacrosTabAr from './ProductivityMacrosTabAr';
import CustomVocabularyTabAr from './CustomVocabularyTabAr';
import EasyIntegrationsTabAr from './EasyIntegrationsTabAr';
import VoiceCommandsContentAr from './VoiceCommandsContentAr';
import ProductivityMacrosContentAr from './ProductivityMacrosContentAr';
import CustomVocabularyContentAr from './CustomVocabularyContentAr';
import EasyIntegrationsContentAr from './EasyIntegrationsContentAr';
import DynamicTemplateTabAr from './DynamicTemplateTabAr';
import ClinicianBuiltMobileAr from './ClinicianBuiltMobileAr';

export default class ClinicianBuiltAr extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section dir='rtl' className='aug-empowering-clinicians pt-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2 className='aug-career__title'>تم تصميمه وبنائه خصيصًا من قبل الأطباء</h2>
                        </div>
                    </div>
                    <div className='aug-empowering-clinicians__tab'>
                        <div className='d-none d-lg-block'>
                            <div className='row gx-80'>
                                <div className='col-lg-6'>
                                    <div className="tab-content" id="empoweringClinicians-tabContent">
                                        <div className="tab-pane fade show active" id="physicianBurnout" role="tabpanel" aria-labelledby="physicianBurnout-tab" tabIndex="0">
                                            <DynamicTemplateTabAr/>
                                        </div>
                                        <div className="tab-pane fade" id="patientEngagement" role="tabpanel" aria-labelledby="patientEngagement-tab" tabIndex="0">
                                            <VoiceCommandsTabAr/>
                                        </div>
                                        <div className="tab-pane fade" id="claimDenial" role="tabpanel" aria-labelledby="claimDenial-tab" tabIndex="0">
                                            <ProductivityMacrosTabAr/>
                                        </div>
                                        <div className="tab-pane fade" id="simplifiedDeployment" role="tabpanel" aria-labelledby="simplifiedDeployment-tab" tabIndex="0">
                                            <CustomVocabularyTabAr/>
                                        </div>
                                        <div className="tab-pane fade" id="customerSuccess" role="tabpanel" aria-labelledby="customerSuccess-tab" tabIndex="0">
                                            <EasyIntegrationsTabAr/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="nav flex-column nav-pills" id="empoweringClinicians-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link mt-0 active" id="physicianBurnout-tab" data-bs-toggle="pill" data-bs-target="#physicianBurnout" type="button" role="tab" aria-controls="physicianBurnout" aria-selected="true">
                                            <DynamicTemplatesContentAr/>
                                        </button>
                                        <button className="nav-link" id="patientEngagement-tab" data-bs-toggle="pill" data-bs-target="#patientEngagement" type="button" role="tab" aria-controls="patientEngagement" aria-selected="false">
                                             <VoiceCommandsContentAr/>
                                        </button>

                                        <button className="nav-link" id="claimDenial-tab" data-bs-toggle="pill" data-bs-target="#claimDenial" type="button" role="tab" aria-controls="claimDenial" aria-selected="false">
                                            <ProductivityMacrosContentAr/>
                                        </button>
                                        <button className="nav-link" id="simplifiedDeployment-tab" data-bs-toggle="pill" data-bs-target="#simplifiedDeployment" type="button" role="tab" aria-controls="simplifiedDeployment" aria-selected="false">
                                            <CustomVocabularyContentAr/>
                                        </button>
                                        <button className="nav-link" id="customerSuccess-tab" data-bs-toggle="pill" data-bs-target="#customerSuccess" type="button" role="tab" aria-controls="customerSuccess" aria-selected="false">
                                            <EasyIntegrationsContentAr/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                           <ClinicianBuiltMobileAr/>
                        </div>


                    </div>
                </div>
            </section>
        );
    };
};