import React from 'react';
import './EmpoweringClinicians.scss';
import BaseComponent from '../../../Common/BaseComponent';
import PhysicianBurnoutContent from './PhysicianBurnoutContent';
import PhysicianBurnoutTab from './PhysicianBurnoutTab';
import PatientEngagementTab from './PatientEngagementTab';
import PatientEngagementContent from './PatientEngagementContent';
import ClaimDenialTab from './ClaimDenialTab';
import ClaimDenialContent from './ClaimDenialContent';
import SimplifiedDeploymentContent from './SimplifiedDeploymentContent';
import SimplifiedDeploymentTab from './SimplifiedDeploymentTab';
import CustomerSuccessContent from './CustomerSuccessContent';
import CustomerSuccessTab from './CustomerSuccessTab';
import EmpoweringCliniciansMobile from './EmpoweringCliniciansMobile';

export default class EmpoweringClinicians extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-empowering-clinicians'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <h2 className='aug-career__title'>Empowering Clinicians <br /> Across the Care Continuum</h2>
                        </div>
                    </div>
                    <div className='aug-empowering-clinicians__tab'>
                        <div className='d-none d-lg-block'>
                            <div className='row gx-80'>
                                <div className='col-lg-6'>
                                    <div className="tab-content" id="empoweringClinicians-tabContent">
                                        <div className="tab-pane fade show active" id="physicianBurnout" role="tabpanel" aria-labelledby="physicianBurnout-tab" tabIndex="0">
                                            <PhysicianBurnoutTab />
                                        </div>
                                        <div className="tab-pane fade" id="patientEngagement" role="tabpanel" aria-labelledby="patientEngagement-tab" tabIndex="0">
                                            <PatientEngagementTab />
                                        </div>
                                        <div className="tab-pane fade" id="claimDenial" role="tabpanel" aria-labelledby="claimDenial-tab" tabIndex="0">
                                            <ClaimDenialTab />
                                        </div>
                                        <div className="tab-pane fade" id="simplifiedDeployment" role="tabpanel" aria-labelledby="simplifiedDeployment-tab" tabIndex="0">
                                            <SimplifiedDeploymentTab />
                                        </div>
                                        <div className="tab-pane fade" id="customerSuccess" role="tabpanel" aria-labelledby="customerSuccess-tab" tabIndex="0">
                                            <CustomerSuccessTab />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="nav flex-column nav-pills" id="empoweringClinicians-tab" role="tablist" aria-orientation="vertical">
                                        <button className="nav-link mt-0 active" id="physicianBurnout-tab" data-bs-toggle="pill" data-bs-target="#physicianBurnout" type="button" role="tab" aria-controls="physicianBurnout" aria-selected="true">
                                            <PhysicianBurnoutContent />
                                        </button>
                                        <button className="nav-link" id="patientEngagement-tab" data-bs-toggle="pill" data-bs-target="#patientEngagement" type="button" role="tab" aria-controls="patientEngagement" aria-selected="false">
                                            <PatientEngagementContent />
                                        </button>

                                        <button className="nav-link" id="claimDenial-tab" data-bs-toggle="pill" data-bs-target="#claimDenial" type="button" role="tab" aria-controls="claimDenial" aria-selected="false">
                                            <ClaimDenialContent />
                                        </button>
                                        <button className="nav-link" id="simplifiedDeployment-tab" data-bs-toggle="pill" data-bs-target="#simplifiedDeployment" type="button" role="tab" aria-controls="simplifiedDeployment" aria-selected="false">
                                            <SimplifiedDeploymentContent />
                                        </button>
                                        <button className="nav-link" id="customerSuccess-tab" data-bs-toggle="pill" data-bs-target="#customerSuccess" type="button" role="tab" aria-controls="customerSuccess" aria-selected="false">
                                            <CustomerSuccessContent />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-block d-lg-none'>
                            <EmpoweringCliniciansMobile/>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};