import React from 'react';
import {ReactComponent as Error} from '../../../Assets/images/icons/location-error.svg';
import { NavLink } from 'react-router-dom';
import { ReloadPage } from '../../../Common/Utils';

const DetectingLocationError = () => {

    return(
        <section className="aug-detecting-location">
            <div className='aug-detecting-location-box mt-5 pt-5'>
                <div className='aug-detecting-location-icon'>
                    <Error className='aug-detecting-location-error-icon'/>
                </div>
            </div>
            <p className='mb-0 mt-4'>
                Oops! There was an error to load your page
            </p>
            <div className='mt-4 pt-3'>
                <NavLink className="aug-location-try" onClick={ReloadPage}>Try again</NavLink>
            </div>
        </section>
    );
};

export default DetectingLocationError;