import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import ContactSalesMetaAr from './Meta/ContactSalesMetaAr';
import ContactSalesBannerAr from './ContactSalesBanner/ContactSalesBannerAr';
import ScheduleDemoAr from './ScheduleDemo/ScheduleDemoAr';
import CustomizedQuoteAr from './CustomizedQuote/CustomizedQuoteAr';
import FAQAr from './FAQ/FAQAr';
// import CustomerSayAr from '../Home/CustomerSay/CustomerSayAr';
// import OurPartnerAr from '../Home/OurPartner/OurPartnerAr';

export default class ContactSalesAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ContactSalesMetaAr/>
                <ContactSalesBannerAr/>
                <ScheduleDemoAr/>
                {/* <OurPartnerAr/>
                <CustomerSayAr/> */}
                <FAQAr/>
                <CustomizedQuoteAr/>
            </main>
        );
    }
}