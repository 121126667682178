import React from 'react';
import {ReactComponent as EnhancedDictationIcone} from '../../../Assets/images/icons/lightning-duotone-line.svg';


const EnhancedDictation = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <EnhancedDictationIcone/>
            </div>
            <h3 className='mb-2'>Enhanced  Dictation</h3>
            <p className='mb-0'>Create quality at source with advanced medical voice AI that boosts productivity and
            efficiency, while also improving the overall quality of care. Using Spectra is 4 times faster than typing.</p>
        </div>
    );
};

export default EnhancedDictation;