import React from 'react';

const FreeTrialAr = () => {
    return (
        <div dir='rtl' className="accordion-item">
            <h3 className="accordion-header" id="FreeTrial">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFreeTrial" aria-expanded="false" aria-controls="collapseFreeTrial">
                كيف يساعد Augnito الأطباء على استخلاص القيمة في إعداد المؤسسة؟
                </button>
            </h3>
            <div id="collapseFreeTrial" className="accordion-collapse collapse" aria-labelledby="FreeTrial" data-bs-parent="#faq">
                <div className="accordion-body">
في بيئة المؤسسة، توفر حلول Augnito تقنية الذكاء الاصطناعي الصوتي القابلة للتطوير والتي تعزز التعاون والكفاءة في سير عمل مقدم الخدمة الفردي بالإضافة إلى تنسيق رعاية المرضى عبر الأقسام. نحن نقدم عمليات تكامل سهلة مع جميع السجلات الطبية الإلكترونية الرئيسية، مما يضمن التدفق السلس للبيانات والتوافق. تتيح عمليات التكامل العميق، بالإضافة إلى خيارات التعليمات البرمجية المنخفضة وبدون تعليمات برمجية، للأطباء تسجيل البيانات المباشرة ونقلها بسلاسة. تم تصميم حلول مؤسستنا لدعم عمليات النشر واسعة النطاق من خلال ميزات قوية لأمن المعلومات والبيانات والامتثال. لمزيد من المعلومات حول كيفية تصميم حلول Augnito لتناسب سير عمل مؤسستك، بما في ذلك كتيبات الأدوية المخصصة وقواعد البيانات المخصصة الأخرى، يرجى الاتصال بفريقنا. يمكنك التواصل معنا عن طريق ملء النموذج أعلاه أو إرسال بريد إلكتروني إلى العناوين المدرجة في صفحة اتصل بنا.

                </div>
            </div>
        </div>
    );
};

export default FreeTrialAr;