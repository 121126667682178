import React from 'react';
import './UniversalAccessibility.scss';
import {ReactComponent as Chrome} from '../../../Assets/images/icons/chrome.svg';
import {ReactComponent as Windows} from '../../../Assets/images/icons/Windows.svg';
import {ReactComponent as Web} from '../../../Assets/images/icons/Web.svg';
import {ReactComponent as IOS} from '../../../Assets/images/icons/IOS.svg';
import {ReactComponent as Android} from '../../../Assets/images/icons/Android.svg';

const UniversalAccessibilityAr = () => {

    return (
       <section dir='rtl' className='aug-universal-accessibility'>
            <div className='container'>
                <div className='aug-universal-accessibility-wrap'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-universal-accessibility-box'>
                                <h2 className='mb-3'>إمكانية وصول شاملة</h2>
                                <p className='mb-0'>تتيح لك مرونة Augnito إمكانية الوصول بسهولة من جميع الأجهزة، مما يعزز سير عمل الطبيب.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='aug-universal-accessibility__img'>
                                <ul className='list-unstyled mb-0'>
                                    <li>
                                        <Windows/>
                                        <span>Windows</span>
                                    </li>
                                    <li>
                                        <Web/>
                                        <span>Web</span>
                                    </li>
                                    <li>
                                        <Chrome/>
                                        <span>Chrome</span>
                                    </li>
                                    <li>
                                        <IOS/>
                                        <span>iOS</span>
                                    </li>
                                    <li>
                                        <Android/>
                                        <span>Android</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default UniversalAccessibilityAr;