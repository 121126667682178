import React from 'react';
import {ReactComponent as BillingLevelsIcone} from '../../../Assets/images/icons/competitive-compensation.svg';

const BillingLevels = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <BillingLevelsIcone/>
            </div>
            <h3 className='mb-2'> Improvement in Billing Levels</h3>
            <p className='mb-0'>Omni enables clinicians to more accurately capture the full scope and complexity of patient encounters. This results in more appropriate billing levels, ensuring fair compensation for services rendered and increased overall revenue.</p>
        </div>
    );
};

export default BillingLevels;