import React from 'react';
import {ReactComponent as CustomizableTemplatesIcone} from '../../../Assets/images/icons/google-docs.svg';

const CustomizableTemplates = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <CustomizableTemplatesIcone/>
            </div>
            <h3 className='mb-2'>Customizable Templates</h3>
            <p className='mb-0'>Omni acts as an ambient medical scribe, offering configurable clinical note templates that cater to multiple medical specialties, visit-types, and provider preferences.</p>
        </div>
    );
};

export default CustomizableTemplates;