import React from 'react';
import './AugnitoDifference.scss';
import CompetitiveCompensationAr from './CompetitiveCompensationAr';
import OwnershipSuccessAr from './OwnershipSuccessAr';
import HealthCoverageAr from './HealthCoverageAr';
import FlexibleTimeAr from './FlexibleTimeAr';
import AcceleratedGrowthAr from './AcceleratedGrowthAr';
import ConnectCollaborateAr from './ConnectCollaborateAr';

const AugnitoDifferenceAr = () => {
    return (
        <section dir='rtl' className='aug-augnito-difference'>
            <div className='container'>
                <h2 className='aug-career__title'>الفرق مع Augnito</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <CompetitiveCompensationAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <OwnershipSuccessAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <HealthCoverageAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <FlexibleTimeAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <AcceleratedGrowthAr/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ConnectCollaborateAr/>
                    </div>
                </div>
                
            </div>
        </section>
    );
};

export default AugnitoDifferenceAr;