import React from 'react';
import { NavLink } from 'react-router-dom';
import requestImg from '../../../Assets/images/ContactUs/HelpYou/request-demo.webp';

const BookDemo = () => {
    return (
        <div className='aug-card'>
            <img src={requestImg} alt="Doctor in white coat with stethoscope typing on laptop, green plants in background" className="w-100 img-fluid"/>
            <div className='aug-card-body'>
                <h3 className='aug-card-header mb-0'>Request a Demo</h3>
                <p className='mb-0'>Interested in our products? <br/> Watch them in action.</p>
                <NavLink to="/contact-sales" className='btn btn-primary text-primary'>Request a Demo</NavLink>
            </div>
        </div>
    );
};

export default BookDemo;