import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";
import * as Constants from '../../../Common/Constants';
import { ScrollTop } from '../../../Common/Utils';
import FooterLogoAr from './FooterLogoAr';

const FooterInfoAr = () => {
    return (
        <div dir='rtl' className='row'>
            <div className='col-lg-3 col-md-12'>
                <FooterLogoAr/>
            </div>
            <div className='col-lg-3 col-md-4 col-6'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-3'>المنتجات</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <Link to="/ar/omni" onClick={ScrollTop}>Omni</Link>
                        </li>
                        <li>
                            <Link to="/ar/spectra" onClick={ScrollTop} >Spectra</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-lg-3 col-md-4 col-6'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-3'>الشركة</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <Link to="/ar/about-us" onClick={ScrollTop}>نبذة عنا</Link>
                        </li>
                        <li>
                            <Link to="/ar/careers" onClick={ScrollTop}>فرص العمل</Link>
                        </li>
                        <li>
                            <Link to="/ar/contact-us" onClick={ScrollTop}>اتصل بنا</Link>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "/legal"} target="_blank" rel="noreferrer" className="nav-link">قانوني</a>                            
                        </li> 
                    </ul>
                </div>
            </div>
            <div className='col-lg-3 col-md-4 col-6'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-3'>الموارد</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <a href={Constants.SITE_URL + "resources/blog" } target="_blank" rel="noreferrer" className="nav-link">المدونة</a>                            
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/case-studies"} target="_blank" rel="noreferrer" className="nav-link">دراسة حالة</a>   
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/newsroom"} target="_blank" rel="noreferrer" className="nav-link">غرفة الأخبار</a> 
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/faq"} target="_blank" rel="noreferrer" className="nav-link">الأسئلة الشائعة</a> 
                        </li>
                        {/* <li>
                            <a href={Constants.SITE_URL + "resources/docs"} target="_blank" rel="noreferrer" className="nav-link">التوثيق</a>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/videos"} target="_blank" rel="noreferrer" className="nav-link">مقاطع الفيديو</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FooterInfoAr;