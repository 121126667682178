import React from 'react';
import './EmpoweringClinicians.scss';
import BaseComponent from '../../../Common/BaseComponent';
import { ReactComponent as BurnoutIcone } from '../../../Assets/images/icons/physician-burnout.svg';
import physicianBurnout from '../../../Assets/images/home/EmpoweringClinicians/physician-burnout.webp';
import { ReactComponent as PatientIcone } from '../../../Assets/images/icons/patient-engagement.svg';
import { ReactComponent as ClaimIcone } from '../../../Assets/images/icons/claim-denials.svg';
import { ReactComponent as CustomerIcone } from '../../../Assets/images/icons/customer-success.svg';
import { ReactComponent as DeploymentIcone } from '../../../Assets/images/icons/simplified-deployment.svg';
import simplifiedDeployment from '../../../Assets/images/home/EmpoweringClinicians/simplified-deployment.webp';
import patientEngagement from '../../../Assets/images/home/EmpoweringClinicians/patient-engagement.webp';
import customerSuccess from '../../../Assets/images/home/EmpoweringClinicians/customer-success.webp';
import claimDenial from '../../../Assets/images/home/EmpoweringClinicians/reduced-claim.webp';


export default class EmpoweringCliniciansMobile extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (

            <div className="accordion" id="accordionEmpowering">
                <div className="accordion-item">
                    <div className='accordion-item-content' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <BurnoutIcone /> Mitigate Physician Burnout
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Streamline clinical workflows and create structured documentation via an intuitive voice interface. With medical voice AI, users are more productive, experience less fatigue, and spend more face time with patients.
                        </div>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={physicianBurnout} alt="Physician using Augnito medical speech recognition software on desktop" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <PatientIcone /> Drive Patient Engagement
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            Experience a more meaningful appointment via doctors equipped with advanced AI for medical dictation and transcription, leading to better care and treatment overall.
                        </div>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={patientEngagement} alt="Smiling African American patient with short hair in gray sweater, receiving primary care from a healthcare professional" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <ClaimIcone /> Reduce Claim Denials
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                        Boost top and bottom-line with more comprehensive clinical notes for claims processing. Enhance CDI at source with CAPD and automated clinical coding capabilities (SNOMED, ICD-10, CPT, SBS).
                        </div>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={claimDenial} alt="Healthcare professionals reviewing medical charts and claim documents with pens, collaborating on patient data management" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <DeploymentIcone /> Simplified Deployment
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                        Secure, simple, and out-of-the-box ready solutions that are easy to deploy and manage at any scale. No infrastructure or hardware costs. Plug-and-play APIs & SDKs for streamlined integrations.
                        </div>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                        <img src={simplifiedDeployment} alt="Hands typing on laptop with futuristic holographic interface displaying data analytics and cloud storage icons" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="accordion-item border-0">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" type="button">
                        <h2 className="aug-empowering-clinicians__accordion-box__title">
                            <CustomerIcone /> Robust Customer Success
                        </h2>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                        Driven by deep analytics and measured outcomes, ensuring tangible benefits for enterprise-level clients. Hassle-free adoption, transition, training, and nurture - turning clinicians into our biggest advocates.
                        </div>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionEmpowering">
                        <div className='aug-empowering-clinicians__accordion-img mt-1'>
                            <img src={customerSuccess} alt="Smiling female doctor with stethoscope holding tablet, giving thumbs up, with colleagues in background" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

        );
    };
};