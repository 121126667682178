import React from 'react';
import {ReactComponent as EnhancedDictationIcone} from '../../../Assets/images/icons/customer-success.svg';


const EnhancedDictationAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <EnhancedDictationIcone/>
            </div>
            <h3 className='mb-2'>إملاء محسّن</h3>
            <p className='mb-0'>إنشاء الجودة في المصدر باستخدام الذكاء الاصطناعي الصوتي الطبي المتقدم الذي يعزز الإنتاجيةوالكفاءة، مع تحسين جودة الرعاية الشاملة أيضًا.يعد استخدام Spectra أسرع بأربع مراتمن الكتابة.</p>
        </div>
    );
};

export default EnhancedDictationAr;