import React from 'react';
import mediaImg from '../../../Assets/images/ContactUs/HelpYou/media-press.webp';


const GeneralQueries = () => {   

    return (
        <div className='aug-card'>
            <img src={mediaImg} alt="Hand holding smartphone displaying news app with latest headlines and images" className="w-100 img-fluid"/>
            <div className='aug-card-body'>
                <h3 className='mb-0 aug-card-header'>Media & Press</h3>
                <div className='mt-2 pt-1'>
                    <span className='mb-0'>Email</span> 
                    <a href="mailto:marketing@augnito.ai" rel="noreferrer" target="_blank" className='text-active'> marketing@augnito.ai</a> 
                    <span className='mb-0'> to converse with our PR & Communications manager  </span>
                </div>
                
            </div>
        </div>
    );
};

export default GeneralQueries;