import React from 'react';
import OutputVideo from '../../../Assets/images/Omni/ClinicalNote/generate-output.webm';
import OutputVideoMp from '../../../Assets/images/Omni/ClinicalNote/generate-output.mp4';

const GenerateOutput = () => {
    return (
        <div className='aug-efficient-documentation__item'>
            <div className="row align-items-center flex-lg-row flex-column-reverse">
                <div className='offset-xl-1 col-12 col-md-10 col-lg-5 col-xl-4'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoDictation' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                <source src={OutputVideo} type="video/webm" />
                                <source src={OutputVideoMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className='offset-lg-1 col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>STEP 2</h6>
                        <h3 className='mb-3'>Generate Output</h3>
                        <p className='mb-0'>Our built-in AI instantly creates a structured clinical document from the conversation – with medical history, chief complaint, diagnosis, plan of care, prescription, follow-up appointments, and lots more depending on specialties.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateOutput;
