import React from 'react';
import { ReactComponent as TemplatesIcone } from '../../../Assets/images/icons/google-docs.svg';

const DynamicTemplatesContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <TemplatesIcone /> قوالب ديناميكية
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            تحميل قوالب محددة مسبقًا لأنواع الملاحظات السريرية المستخدمة على نطاق واسع مثل تقارير التصوير بالأشعة،وملخصات الخروج، وملاحظات المعلومات الذاتية والموضوعية والتقييم والخطة (SOAP)،وغير ذلك الكثير.يمكنك فتحه بسهولة والتنقل فيه إملائه وتحرير التقارير السريرية باستخدام صوتك فقط. 
            </div>
        </div>
    );
};

export default DynamicTemplatesContentAr;