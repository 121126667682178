import React from 'react';
import simplifiedDeployment from '../../../Assets/images/home/EmpoweringClinicians/simplified-deployment.webp';

const SimplifiedDeploymentTabAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={simplifiedDeployment} alt="Hands typing on laptop with futuristic holographic interface displaying data analytics and cloud storage icons" className="img-fluid" />
        </div>
    );
};

export default SimplifiedDeploymentTabAr;