import React from 'react';
import {Link} from "react-router-dom";

const ExpressInterestAr = () => {
    return (
        <section dir='rtl' className='aug-thankyou-banner'>
            <div className='container'>
                <div className="row align-items-center">
                    <div className="offset-lg-1 col-12 col-lg-7">
                        <h1 className="aug-thankyou-banner__heading mb-3">شكرًا لتواصلك معنا!</h1>
                        <p className='mb-0'>سوف يتواصل معك أحد ممثلينا قريبًا.</p>
                        <Link to="/ar/" className="btn btn-primary">العودة إلى الصفحة الرئيسية</Link>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="aug-thankyou-banner__img">
                            <img src={require('../../../Assets/images/Pattrens/thank-you.webp')} className="img-fluid" alt="pattren" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExpressInterestAr;