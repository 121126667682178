import React from 'react';
import './OurEthos.scss';

const OurEthosAr = () => {
    return (
        <section dir='rtl' className='aug-our-ethos aug-leadership-team'>
            <div className='container'>
                <h2 className="aug-leadership-team__title">أخلاقياتنا</h2>
                <div className='row gx-0'>
                    <div className='col-md-12 col-lg-4'>
                        <div className='aug-our-ethos-centricity'>
                        التمحور حول الإنسان
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-2'>
                        <div className='aug-our-ethos-line'>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>الخبرة وسهولة الوصول</h3>
                            <p className='mb-0'>يتمثل هدفنا في تحويل أكثر من عقدين من الخبرة الطبية إلى منتجات ذكاء اصطناعي يسهل الوصول إليها من خلال البحث العميق وتطوير التكنولوجيا المتقدمة. يضمن فريقنا متعدد التخصصات توفير حلول مثالية للأطباء من كل نوع تخصص. </p>
                        </div>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>الإبداع وسهولة الاستخدام </h3>
                            <p className='mb-0'>تم تصميم منتجاتنا للحفاظ على تفضيلات المستخدم وتجربته في المقدمة، في كل مرحلة. نشجع التعقيبات الفورية من الأطباء لمعالجة نقاط الضعف بشكل فعال وتقييم المفاهيم الجديدة من خلال التجربة المبكرة وبشكل متكرر. </p>
                        </div>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>الاهتمام والتجاوب</h3>
                            <p className='mb-0'>متخصصو نجاح العملاء لدينا متاحون لإضفاء اللمسة البشرية في أي مرحلة، ما يساعدك على تحقيق أقصى استفادة من تقنياتنا. نفهم ]أهمية بناء الروابط القوية وتوفير التحليلات والاختبارات وإصدار التقارير عند الطلب.</p>
                        </div>
                        <div className='aug-our-ethos-item'>
                            <h3 className='aug-our-ethos-item-title mb-0'>المراعاة والدقة</h3>
                            <p className='mb-0'>مبادئ عملنا هي الاجتهاد وتحقيق الأهداف والقوة. يُعد إحداث ثورة في أنظمة الرعاية الصحية مشروعًا طموحًا نسعى جاهدين لتنفيذه بشمولية ودقة ووضوح نابعين من الخبرة والقدرة.</p>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    );
};

export default OurEthosAr;