import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import SoftwareServiceMeta from './SoftwareServiceMeta';
import SoftwareServiceUS from './SoftwareServiceUS';
import SoftwareServiceME from './SoftwareServiceME';
import SoftwareServiceIN from './SoftwareServiceIN';

export default class SoftwareService extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }
        
        if(!this.state.selectedRegion) {
            this.setState({ selectedRegion  : this.props.userRegionData })
        }
        
        const handleRegionChange = (event) => {
            this.setState({ selectedRegion : event.target.value })
        }

        return (
            <>
                <SoftwareServiceMeta/>
                <section className='aug-legal-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 offset-lg-1 col-lg-10'>
                                <div className='aug-legal-page-header'>
                                    <div className='row'>
                                        <div className='col-lg-9'>
                                            <h1 className='aug-legal-page-title mb-3'>Software Service Terms</h1>
                                        </div>
                                        <div className='col-lg-3 d-none d-lg-block'>
                                            <select class="form-select">
                                                <option defaultValue>Version 1.0 (current)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <p>These Software Service Terms (“<strong>Terms</strong>”), along with the Order Form and the Software as a Subscription Agreement (“<strong>Agreement</strong>”) and similar documents (including any other agreements) executed between Augnito India Private Limited (“<strong>Company</strong>” or “<strong>Augnito</strong>” or “<strong>AIPL</strong>”) and you (the “<strong>Customer</strong>”) shall be effective as of the Effective Date of the first Order Form.  These Terms form an electronic record under the provisions of the Information Technology Act, 2000 and rules framed there under, including the Information Technology (Intermediaries Guidelines) Rules, 2011 (as applicable and as amended from time to time). The use of the Services is available only to persons competent to contract under the applicable laws.</p>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-4 col-12'>
                                            <select class="form-select"  value={this.state.selectedRegion} onChange={handleRegionChange}>
                                                <option value="IN">INDIA</option>
                                                <option value="US">USA</option>
                                                <option value="ME">UAE</option>
                                            </select>
                                        </div>
                                        <div className='col-md-3 col-12 d-block d-lg-none mt-3'>
                                            <select class="form-select">
                                                <option defaultValue>Version 1.0 (current)</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                {
                                    this.state.selectedRegion === "IN" ? <SoftwareServiceIN/> :
                                    this.state.selectedRegion === "ME" ? <SoftwareServiceME/> :
                                    <SoftwareServiceUS/>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}