import React from 'react';
import './LeadershipTeam.scss';
import { ReactComponent as Linkedin } from '../../../Assets/images/icons/linkedin.svg';

const LeadershipTeamAr = () => {
    return (
        <section dir='rtl' className='aug-leadership-team'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-xl-11 col-12'>
                        <h2 className="aug-leadership-team__title">فريق الإدارة</h2>
                        <div className='row gx-80'>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/rustom.webp')} alt="Rustom Lawyer, CEO at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>رستم لوير </h3>
                                    <div className='aug-leadership-team-designation'>
                                        المؤسس المشارك والرئيس التنفيذي
                                    </div>
                                    <a href='https://www.linkedin.com/in/rustom-lawyer/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/shiraz.webp')} alt="Shiraz Austin, Co-founder at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>شيراز أوستن  </h3>
                                    <div className='aug-leadership-team-designation'>
                                        المؤسس المشارك
                                    </div>
                                    <a href='https://www.linkedin.com/in/shiraz-austin-89760511/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/piyush.webp')} alt="Piyush Arya, Chief Growth Officer at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>بيوش أريا </h3>
                                    <div className='aug-leadership-team-designation'>
                                        كبير مسؤولي التطوير
                                    </div>
                                    <a href='https://www.linkedin.com/in/piyusharya/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/iyaz.webp')} alt="Mohamed Iyaz, Chief Operating Officer at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>محمد إياز </h3>
                                    <div className='aug-leadership-team-designation'>
                                        الرئيس التنفيذي للعمليات
                                    </div>
                                    <a href='https://www.linkedin.com/in/iyaz1974/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/aravind.webp')} alt="Aravind Gaddala, Head of Product at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>حمزة مفتاح </h3>
                                    <div className='aug-leadership-team-designation'>
                                        نائب الرئيس المساعد للمبيعات
                                    </div>
                                    <a href='https://www.linkedin.com/in/agaddala/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/hammond.webp')} alt="Hammond Pereira, Head of Engineering at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>فيروشكا باتيل </h3>
                                    <div className='aug-leadership-team-designation'>
                                        مدير الشراكات العالمية والاستراتيجية
                                    </div>
                                    <a href='https://www.linkedin.com/in/hammond-pereira/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-md-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/verushka.webp')} alt="Verushka Patel, Director – Global Partnerships & Retail Sales at Augnito AI" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>تشيثان كومار إس</h3>
                                    <div className='aug-leadership-team-designation'>
                                        رئيس قسم نجاح العملاء
                                    </div>
                                    <a href='https://www.linkedin.com/in/verushka-patel-15352b98/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-md-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/chethan.webp')} alt="Chethan Kumar, Director - Customer Success" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>أرافيند جادالا </h3>
                                    <div className='aug-leadership-team-designation'>
                                        مدير المنتجات التنفيذي
                                    </div>
                                    <a href='https://www.linkedin.com/in/chetan-k-cs/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-4'>
                                <div className='aug-leadership-team-item mb-0'>
                                    <div className='aug-leadership-team-img'>
                                        <img loading="lazy" src={require('../../../Assets/images/AboutUs/leadership-team/hamza.webp')} alt="Rustom Lawyer" className="img-fluid" />
                                    </div>
                                    <h3 className='aug-leadership-team-name mb-0'>هاموند بيريرا</h3>
                                    <div className='aug-leadership-team-designation'>
                                        رئيس قسم الهندسة
                                    </div>
                                    <a href='https://www.linkedin.com/in/hamzamoftah/' target="_blank" rel="noreferrer">
                                        <Linkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default LeadershipTeamAr;