import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import AboutBanner from './AboutBanner/AboutBanner';
import LeadershipTeam from './LeadershipTeam/LeadershipTeam';
import AboutUsMeta from './Meta/AboutUsMeta';
// import TrustedBy from './TrustedBy/TrustedBy';
import OurStory from './OurStory/OurStory';
import AdvisoryBoard from './AdvisoryBoard/AdvisoryBoard';
import MedicalAdvisoryBoard from './MedicalAdvisoryBoard/MedicalAdvisoryBoard';
import OurEthos from './OurEthos/OurEthos';
import WorkUs from './WorkUs/WorkUs';
// import ClinicalPlatforms from './ClinicalPlatforms/ClinicalPlatforms';

export default class AboutUs extends BaseComponent {
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <AboutUsMeta/>
                <AboutBanner/>
                <OurStory/>
                <LeadershipTeam/>
                <AdvisoryBoard/>
                <MedicalAdvisoryBoard/>
                <OurEthos/>
                {/* <ClinicalPlatforms userRegionData={regionData}/> */}
                {/* <TrustedBy/> */}
                <WorkUs/>
            </main>
         );
    }
}