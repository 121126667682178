import React from 'react';
import {ReactComponent as OwnershipSuccessIcone} from '../../../Assets/images/icons/ownership-success.svg';

const OwnershipSuccessAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <OwnershipSuccessIcone/>
            </div>
            <h3 className='mb-2'>الملكية في النجاح</h3>
            <p className='mb-0'>قم بمواءمة طموحات نموك الشخصي مع طموحات Augnito من خلال مخططنا الخاص لملكية أسهم الموظفين (ESOP).</p>
        </div>
    );
};

export default OwnershipSuccessAr;