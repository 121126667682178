import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import AboutUsMetaAr from './Meta/AboutUsMetaAr';
import OurStoryAr from './OurStory/OurStoryAr';
import LeadershipTeamAr from './LeadershipTeam/LeadershipTeamAr';
import AdvisoryBoardAr from './AdvisoryBoard/AdvisoryBoardAr';
import MedicalAdvisoryBoardAr from './MedicalAdvisoryBoard/MedicalAdvisoryBoardAr';
import OurEthosAr from './OurEthos/OurEthosAr';
import AboutBannerAr from './AboutBanner/AboutBannerAr';
import WorkUsAr from './WorkUs/WorkUsAr';
// import TrustedByAr from './TrustedBy/TrustedByAr';
// import ClinicalPlatformsAr from './ClinicalPlatforms/ClinicalPlatformsAr';

export default class AboutUsAr extends BaseComponent {
    render() {
   
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <AboutUsMetaAr/>
                <AboutBannerAr/>
                <OurStoryAr/>
                <LeadershipTeamAr/> 
                <AdvisoryBoardAr/>
                <MedicalAdvisoryBoardAr/>
                <OurEthosAr/>  
                {/* <ClinicalPlatformsAr/> */}
                {/* <TrustedByAr/> */}
                <WorkUsAr/>        
            </main>
         );
    }
}