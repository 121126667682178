import React from 'react';
import './WeBelieve.scss';
import BaseComponent from '../../../Common/BaseComponent';
import People from './People';
import Purpose from './Purpose';
import Intuitiveness from './Intuitiveness';
import Precision from './Precision';
import Integrity from './Integrity';
import CustomerSuccess from './CustomerSuccess';

export default class WeBelieve extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-we-believe'>
                <div className='container'>
                    <h2 className='aug-career__title'>What We Believe In</h2>
                    <div className='row'>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <People />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <Intuitiveness />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <Purpose />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <Precision />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <Integrity />
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            <CustomerSuccess />
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}
