import React from 'react';
import {ReactComponent as RobustCompatibilityIcone} from '../../../Assets/images/icons/connect-collaborate.svg';

const RobustCompatibility = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <RobustCompatibilityIcone/>
            </div>
            <h3 className='mb-2'>Robust Compatibility</h3>
            <p className='mb-0'>Spectra supports direct dictation into any RIS/LIS/HIS/EMR such as Epic, Cerner, Intersystems, Cloud Solutions, and more, without the need for native integration. </p>
        </div>
    );
};

export default RobustCompatibility;