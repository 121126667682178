import React from 'react';
import {ReactComponent as Erorr} from '../../Assets/images/icons/error.svg';
import {Link} from "react-router-dom";

const Oops = () => {

    return (
       <section className='aug-error-oops'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <Erorr height={60} width={60} className="mb-3" />
                        <h1 className='aug-error-page__heading'>Oops, this is unexpected.</h1>
                        <p className='mb-0 aug-arror-code'>Error code - 404</p>
                        <div className='aug-helpfull-link'>
                            <p>Here are some helpful links to continue browsing while we resolve the issue.</p>
                            <ul className='list-unstyled mb-0'>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">About us</Link>
                                </li>
                                <li>
                                    <Link to="/spectra">Spectra</Link>
                                </li>
                                <li>
                                    <Link to="/omni">Omni</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default Oops;