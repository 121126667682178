import React from 'react';

const KekaCareerAr = () => {
    return (
        <section dir='rtl' className='aug-career-keka'>
            <div className='container'>
                <h2 className='aug-career__title mb-3'> انضم إلى Augnito اليوم </h2>
                <p> 	نحن فريق ديناميكي، نواجه التحديات بالطاقة والقدرة على التكيف. نحن نبحث دائمًا عن أشخاص مبدعين ومفعمين بالحيوية ليكونوا جزءًا من مهمتنا المتمثلة في تعزيز الأطباء بأفضل تقنيات الذكاء الاصطناعي. في حين أننا ملتزمون بشدة بأهدافنا، فإننا نعطي الأولوية أيضًا للحظات الفرح ونساعد بعضنا البعض على النمو بشكل شامل. 
                </p>
                <div className='d-flex'>
                    <p className='mb-0'>اتصل بنا على </p>
                    <a className='me-1 text-active' href="mailto:careers@augnito.ai"> careers@augnito.ai </a>
                </div>
                {/* <iframe title="kekaFrame" src="https://augnito.kekahire.com/api/embedjobs/37be0e0a-5863-4fdc-a569-1738d6669d42" id="kekaCareerRMS" onLoad={function () { }}></iframe> */}
            </div>
        </section>
    );
};


// var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
// var eventer = window[eventMethod];
// var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
// var HeightSet = false;
// eventer(messageEvent, function (e) {
//     // If the message is a resize frame request
    
//     if (e.data && typeof e.data === "string" && e.origin && !HeightSet) {
//         if (e.origin.indexOf("augnito.keka.com") !== -1 && e.data.indexOf('kekaHireCareerPortalHeight') !== -1) {
//             var kekaData = JSON.parse(e.data);
//             var kekaCareerRMS = document.getElementById("kekaCareerRMS");
//             kekaCareerRMS.style.height = (kekaData.kekaHireCareerPortalHeight - 120) + 'px';
//             if (kekaData.kekaHireCareerPortalHeight > 500) {
//                 HeightSet = true;
//             }
//         }
//     }
// }, false);

export default KekaCareerAr;