import React from 'react';
import './ConversationsClinical.scss';
import {ReactComponent as Line} from '../../../Assets/images/icons/line.svg';

const ConversationsClinicalAr = () => {

    return (
        <section dir='rtl' className='aug-conversations-clinical'>
            <div className='container'>
                <div className='row gx-40'>
                    <div className='col-lg-5 col-12 mb-md-1 mb-lg-0'>
                        <h3 className='aug-conversations-clinical__title'>تحويل المحادثات إلى سجلات سريرية مفصَّلة </h3>
                        <p className='aug-conversations-clinical__text mb-5 pb-md-2 pb-lg-0'>توثيق المعلومات السريرية دون عناء وسهولة استخدام السجلات الطبية الإلكترونية بمساعدة الذكاء الاصطناعي  ذكاءنا السريري المحيطي يولد تقارير طبية منظمة بشكل فوري، مما يحول تجربة الطبيب والمريض إلى مسار أفضل. الصوتي سهل الاستخدام. </p>
                    </div>
                    <div className='col-lg-7 col-12'>
                        <div className="row gx-40">
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='pe-1 me-2'>
                                        <h6 dir='ltr' className='aug-conversations-clinical__item-number text-end'>
                                            20+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>أكثر من 20 عامًا من الخبرة في مجال التكنولوجيا الصحية </p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='pe-1 me-2'>
                                        <h6 dir='ltr' className='aug-conversations-clinical__item-number text-end'>
                                            55+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>دعم أكثر من 55 تخصصًا طبيًا </p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='pe-1 me-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            71
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>درجة مؤشر الترويج الصافي: 71 </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='pe-1 me-2'>
                                        <h6 className='aug-conversations-clinical__item-number'>
                                            21x
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>عائد استثمار مثبت بمقدار 21 ضعفًا خلال ستة أشهر فقط </p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='pe-1 me-2'>
                                        <h6 dir='ltr' className='aug-conversations-clinical__item-number text-end'>
                                            37+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>أكثر من 37 لغة لالتقاط المحادثات </p>
                                    </div>
                                </div>
                                <div className='aug-conversations-clinical__item'>
                                    <Line />
                                    <div className='pe-1 me-2'>
                                        <h6 dir='ltr' className='aug-conversations-clinical__item-number text-end'>
                                            15000+
                                        </h6>
                                        <p className='aug-conversations-clinical__item-text'>أكثر من 15000 طبيب يثقون بـ Augnito حول العالم </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};


export default ConversationsClinicalAr;