import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const ReferralPolicyMeta = () => {

    return (
        <Helmet>
            <title> Referral Policy | Augnito Medical Voice AI Solutions </title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Discover Augnito's referral policy for our medical speech recognition technology. Learn about rewards and terms for referring healthcare professionals."/>
            <link rel="canonical" href={Constants.SITE_URL + "/legal/referral-policy"} />
            <meta property="og:url" content={Constants.SITE_URL + "/legal/referral-policy"} />
            <meta property="og:title" content="Referral Policy | Augnito Medical Voice AI Solutions" />
            <meta property="og:description" content="Discover Augnito's referral policy for our medical speech recognition technology. Learn about rewards and terms for referring healthcare professionals." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/legal/referral-policy"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Referral Policy | Augnito Medical Voice AI Solutions" />
            <meta name="twitter:description" content="Discover Augnito's referral policy for our medical speech recognition technology. Learn about rewards and terms for referring healthcare professionals." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default ReferralPolicyMeta;