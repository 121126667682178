import React from 'react';
import patientEngagement from '../../../Assets/images/home/EmpoweringClinicians/patient-engagement.webp';

const PatientEngagementTab = () => {

  return (
    <div className='aug-empowering-clinicians__accordion-img'>
        <img src={patientEngagement} alt="Smiling African American patient with short hair in gray sweater, receiving primary care from a healthcare professional" className="img-fluid" />
    </div>
  );
};

export default PatientEngagementTab;