import React from 'react';
import { ReactComponent as PatientIcone } from '../../../Assets/images/icons/patient-engagement.svg';

const PatientEngagementContent = () => {

    return (

        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <PatientIcone /> Drive Patient Engagement
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
                Experience a more meaningful appointment via doctors equipped with advanced AI for medical dictation and transcription, leading to better care and treatment overall.
            </div>
        </div>

    );
};

export default PatientEngagementContent;