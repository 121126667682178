import React from 'react';
import {ReactComponent as BillingLevelsIcone} from '../../../Assets/images/icons/competitive-compensation.svg';

const BillingLevelsAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <BillingLevelsIcone/>
            </div>
            <h3 className='mb-2'>تحسين مستويات الفوترة</h3>
            <p className='mb-0'>يتيح Omni للأطباء التقاط النطاق الكامل وتعقيدات لقاءات المرضى. ويؤدي هذا إلى مستويات فوترة أكثر ملاءمة، مما يضمن العدالة في التعويض عن الخدمات المقدمة وزيادة الإيرادات الإجمالية.</p>
        </div>
    );
};

export default BillingLevelsAr;