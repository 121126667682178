import React from 'react';
import {ReactComponent as ClaimDenialsIcone} from '../../../Assets/images/icons/claim-denials.svg';

const ClaimDenials = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ClaimDenialsIcone/>
            </div>
            <h3 className='mb-2'>Reduction in Claim Denials</h3>
            <p className='mb-0'>Omni ensures accurate and complete documentation, capturing all relevant clinical information and assigning accurate clinical codes. This precision results in improved reimbursement rates and streamlined revenue cycles.</p>
        </div>
    );
};

export default ClaimDenials;