import React from 'react';
import {ReactComponent as PurposeIcone} from '../../../Assets/images/icons/purpose.svg';

const PurposeAr = () => {
    return (
        <div dir='rtl' className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <PurposeIcone/>
            </div>
            <h3 className='mb-0'>الغاية</h3>
            <p className='mb-0'>نبتكر حلولًا تصنع فرقًا ذا معنى وتأثيرًا دائمًا، مدفوعة برؤية واضحة لمعالجة التحديات الواقعية.</p>
        </div>
    );
};

export default PurposeAr;