import React from 'react';

const AugnitoCostAr = () => {
    return (
        <div dir='rtl' className="accordion-item">
            <h3 className="accordion-header" id="accessFeatures">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAccessFeatures" aria-expanded="true" aria-controls="collapseAccessFeatures">
                كيف يمكنني شراء حلول الذكاء الاصطناعي الصوتية الطبية من Augnito؟
                </button>
            </h3>
            <div id="collapseAccessFeatures" className="accordion-collapse collapse show" aria-labelledby="accessFeatures" data-bs-parent="#faq">
                <div className="accordion-body">
                نحن نقدم خيارات شراء متعددة مصممة خصيصًا لتلبية احتياجاتك الخاصة، سواء للاستخدام الفردي أو الجماعي أو مؤسسة كبيرة. لاستكشاف هذه الخيارات، يرجى الاتصال بفريق المبيعات لدينا عن طريق ملء النموذج أعلاه أو إرسال بريد إلكتروني إلى العناوين المدرجة في صفحة اتصل بنا. سيتواصل معك أحد أعضاء فريق المبيعات لدينا على الفور لإرشادك خلال العملية.
                </div>
            </div>
        </div>
    );
};

export default AugnitoCostAr;