import React from 'react';
import './DetectingLocation.scss'
import {ReactComponent as Location} from '../../../Assets/images/icons/location.svg';

const DetectingLocation = () => {

    return(
        <section className="aug-detecting-location">
            <div className='aug-detecting-location-box mt-5 pt-5'>
                <div className='aug-detecting-location-icon'>
                    <Location/>
                </div>
            </div>
            <p className='mb-0 mt-4'>Detecting your location
                <span className="dot-one">.</span>
                <span className="dot-two">.</span>
                <span className="dot-three">.</span>
            </p>
        </section>
    );
};

export default DetectingLocation;