import React from 'react';
import { ReactComponent as CustomerIcone } from '../../../Assets/images/icons/customer-success.svg';

const CustomerSuccessContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                <CustomerIcone /> Robust Customer Success
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            Driven by deep analytics and measured outcomes, ensuring tangible benefits for enterprise-level clients. Hassle-free adoption, transition, training, and nurture - turning clinicians into our biggest advocates.
            </div>
        </div>
    );
};

export default CustomerSuccessContent;