import React from 'react';
import {ReactComponent as ClinicalSpecialtiesIcone} from '../../../Assets/images/icons/check-ring.svg';

const ClinicalSpecialties = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ClinicalSpecialtiesIcone/>
            </div>
            <h3 className='mb-2'>Supports 55+ Clinical Specialties</h3>
            <p className='mb-0'>Spectra covers specialties such as General Medicine, Oncology, Cardiology, General Surgery, Radiology & lots more. The AI is self-learning from millions of dictations globally and is regularly updated to recognize new drugs and procedures.</p>
        </div>
    );
};

export default ClinicalSpecialties;