import React from 'react';
import { ReactComponent as VoiceIcone } from '../../../Assets/images/icons/voice-check.svg';

const VoiceCommandsContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <VoiceIcone /> أوامر صوتية سهلة
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">             
            التنقل دون استخدام اليدين مع تعليمات شفهية بسيطة.
التحكم في نظامك وبياناتك
باستخدام اللغة الطبيعية بسهولة، مما يسمح لك بإنجاز مهام متعددة والعمل بكفاءة أكبر
. دون لمس لوحة مفاتيح أو فأرة.
            </div>
        </div>
    );
};

export default VoiceCommandsContentAr;