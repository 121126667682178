import React from 'react';
import "./OurStory.scss"

const OurStory = () => {
    return (
       <section className='aug-our-story'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <h2 className='aug-our-story__title'>Our story</h2>
                    </div>
                    <div className='col-lg-7'>
                        <div className=''>
                            <p className='mb-4'>Founded by Rustom Lawyer and Shiraz Austin, Augnito’s genesis is rooted in several years of hands-on experience with clinical workflows and complex healthcare integrations. Over 20 years, the serial entrepreneurs have accumulated unique insights into the intricacies of healthcare operations and governance - developing a deep and holistic understanding of global healthcare systems and the need to reimagine them.</p>
                            <p className='mb-4'>Augnito is a leading solution in Voice AI for Clinical Documentation and Ambient Clinical Intelligence, serving more than 500 hospitals and 15,000 clinicians worldwide. Their SaaS solutions help automate workflows, ensure quality and precision across administrative tasks, and equip clinicians with the latest evidence-based recommendations and insights in real time.</p>
                            <p className='mb-4'>The company's highly intuitive products are the result of extensive collaboration with brilliant minds from MIT, IIT Bombay, and Sheffield University. Co-designed by clinicians and AI scientists, Augnito’s solutions mindfully blend human-centric design principles with cutting-edge technology.</p>
                            <p className='mb-0'>By creating intelligence stacks for clinicians across the care continuum and ensuring that they are highly secure yet extremely accessible, Augnito strives to enhance the human touch in healthcare - making it more efficient, accurate, and patient-focused than ever before. </p>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default OurStory;