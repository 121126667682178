import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../Assets/images/icons/arrow-left.svg';
import {ReactComponent as Quote} from '../../../Assets/images/icons/quote.svg';
import './CustomerSay.scss';
import CustomerSayContent from './CustomerSayContent';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomerSayIN = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 2,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
          <div className='container'>
            <h2 className='aug-customer-say-title'>Hear From Our Patrons</h2>
            <Slider {...settings}>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>Dr Mohan Sunil Kumar</h3>
                      <h4 className='aug-customer-items__designation'>Director and Chief Psychiatrist <br/> Couch: Centre for New Beginning, India </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/mohan-sunil-kumar.webp')} alt="Dr Mohan Sunil Kumar" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="In a world where documentation matters, Augnito’s versatile and robust platform makes documentation a breeze. Saves time by 60%. Better documentation, better care." initWord={35}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>Dr Paresh Doshi</h3>
                      <h4 className='aug-customer-items__designation'>Director of Neurosurgery <br/> Jaslok Hospital and Research Centre, India</h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/paresh-doshi.webp')} alt="Dr Paresh Doshi" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="Let me congratulate and compliment you on this excellent medical dictation, AI-based, software. It has been working seamlessly so far. I have been using it for more than two years. Your service has been excellent and you have surpassed all expectations." initWord={45}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>Dr Jamil Ahmed</h3>
                      <h4 className='aug-customer-items__designation'>Founder and Managing Director <br/> PRIME Healthcare Group, UAE </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/jamil-ahmed.webp')} alt="Dr Jamil Ahmed" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="It is a great honour to have Augnito onboard as our Voice AI partner as we continue to work towards delivering world-class services which positively contributes to the UAE’s vision to become a leading nation in healthcare practices. After reviewing several products, we found the quality of Augnito to be exceptional, plus the engagement experience was smooth for us during the proof-of-concept stage which strengthened our belief in the product and the ability for it to deliver on all counts post onboarding." initWord={40}/>
                  <div className='aug-customer-items__quote'>
                    <Quote/>
                  </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>Dr. Jeannie Kenkare, DO</h3>
                      <h4 className='aug-customer-items__designation'>Co-founder & Chief Medical Officer
                        <br/> PhysicianOne Urgent Care, USA </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/jeannie-kenkare.webp')} alt="Dr. Jeannie Kenkare" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="We pride ourselves in partnering with innovative companies like Augnito that focus on saving a provider’s time and improving patient outcomes for all our stakeholders." initWord={35}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>David Peregrim, PA-C</h3>
                      <h4 className='aug-customer-items__designation'> Emergency Medicine Physician Assistant <br/> Bridgeport Hospital, USA
                      </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/david-peregrim.webp')} alt="David Peregrim" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="It's fast, efficient, comprehensive, and very user friendly. I have used other legacy VR solutions out there and Augnito sits high amongst them. It allowed me to document a lot faster and keep up with the high patient throughput at the clinic" initWord={45}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>Dr. Gerardo Bonilla, MD</h3>
                      <h4 className='aug-customer-items__designation'> CEO and Founder <br/> ChartNote, USA </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/gerardo-bonilla.webp')} alt="Dr. Gerardo Bonilla" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="Since integrating voice-recognition powered by Augnito in ChartNote, physicians have since seen a reduction in the baggage of medical documentation. As a consequence, physician's ability to provide better health care has improved the new additions resulting from our partnership enable our medical software to provide next- generation artificial intelligence with the best-in-class accuracy" initWord={32}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>Sreeraj KR</h3>
                      <h4 className='aug-customer-items__designation'>IT Manager <br/> Zia Medical Center, UAE</h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/sreeraj-KR.webp')} alt="Sreeraj KR" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="Since implementing Augnito, it has positively impacted our processes. Doctors can now dictate their reports directly into the system, with Augnito’s high accuracy capturing every detail, regardless of accent. This has significantly reduced report turnaround times, allowing us to deliver results to physicians and patients much faster. Augnito has not only boosted our efficiency, but it has also freed up valuable time for doctors to focus on what matters most - providing exceptional patient care. I recommend Augnito highly!" initWord={34}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                    <h3 className='aug-customer-items__name mb-1'>Dr Jaymin Patel, BSc, MBBS, FRCR</h3>
                    <h4 className='aug-customer-items__designation'>  Chief Operating Officer <br/> Hexarad, UK </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/jaymin-patel.webp')} alt="Dr Jaymin Patel" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="Augnito has really allowed us to deliver on a significant part of our business objectives. Hexarad was formed by radiologists for radiologists, centered on delivering excellence and efficiency in medical imaging and radiology resources, clinical governance, and customer service. Augnito enables us to do just that." initWord={35}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

            </Slider>
          </div>
       </section>
    );
};

export default CustomerSayIN;
