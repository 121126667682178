import React from 'react';
import {ReactComponent as PurposeIcone} from '../../../Assets/images/icons/purpose.svg';

const Purpose = () => {
    return (
        <div className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <PurposeIcone/>
            </div>
            <h3 className='mb-0'>Purpose</h3>
            <p className='mb-0'>We create solutions that make a meaningful difference and lasting impact, driven by a clear vision to address real-world challenges.</p>
        </div>
    );
};

export default Purpose;