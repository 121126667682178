import React from 'react';
import { ReactComponent as DrivenIcone } from '../../../Assets/images/icons/voice-check.svg';

const AIDrivenContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <DrivenIcone /> التخصيص القائم على الذكاء الاصطناعي
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            يسمح Omni بنقل البيانات دون متاعب إلى السجلات الطبية الإلكترونية المستندة إلى الويب دون الحاجة إلى عمليات دمج معقدة، مما يوفر إمكانية الوصول الفوري إلى سجلات المرضى ضمن سير العمل الحالي.
            </div>
        </div>
    );
};

export default AIDrivenContentAr;