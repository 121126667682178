import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import { ReactComponent as TemplatesIcone } from '../../../Assets/images/icons/google-docs.svg';
import { ReactComponent as VoiceIcone } from '../../../Assets/images/icons/voice-check.svg';
import { ReactComponent as MacrosIcone } from '../../../Assets/images/icons/text-square.svg';
import { ReactComponent as DashboardIcone } from '../../../Assets/images/icons/dashboard-setting.svg';
import { ReactComponent as MacrosIconeIcone } from '../../../Assets/images/icons/book-line.svg';
import DynamicTemplateTab from './DynamicTemplateTab';
import VoiceCommandsTab from './VoiceCommandsTab';
import ProductivityMacrosTab from './ProductivityMacrosTab';
import CustomVocabularyTab from './CustomVocabularyTab';
import EasyIntegrationsTab from './EasyIntegrationsTab';


export default class ClinicianBuiltMobileAr extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (

            <div dir='rtl' className="accordion" id="accordionClinicianBuilt">
                <div className="accordion-item">
                    <div className='accordion-item-content' data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <TemplatesIcone /> قوالب ديناميكية
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            تحميل قوالب محددة مسبقًا لأنواع الملاحظات السريرية المستخدمة على نطاق واسع مثل تقارير التصوير بالأشعة،وملخصات الخروج، وملاحظات المعلومات الذاتية والموضوعية والتقييم والخطة (SOAP)،وغير ذلك الكثير.يمكنك فتحه بسهولة والتنقل فيه إملائه وتحرير التقارير السريرية باستخدام صوتك فقط.
                        </div>
                    </div>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <DynamicTemplateTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <VoiceIcone /> أوامر صوتية سهلة
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            التنقل دون استخدام اليدين مع تعليمات شفهية بسيطة.
                            التحكم في نظامك وبياناتك
                            باستخدام اللغة الطبيعية بسهولة، مما يسمح لك بإنجاز مهام متعددة والعمل بكفاءة أكبر
                            . دون لمس لوحة مفاتيح أو فأرة.
                        </div>
                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <VoiceCommandsTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <MacrosIcone /> زيادة الإنتاجية باستخدام وحدات الماكرو
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            حفظ الجمل أو الفقرات المتكررة على هيئة عبارات بسيطة، مما يوفر الوقت
                            تبسيط سير عملك السريري.
                        </div>
                    </div>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <ProductivityMacrosTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title">
                            <MacrosIconeIcone /> التخصيص باستخدام مفردات مخصصة
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            تحسين النظام وفقًا لاحتياجاتك المحددة عن طريق ملء التفاصيل مثل التهجئات المفضلة
                            ونطق المصطلحات الخاصة بالتخصص، والاختصارات، وأسماء الأدوية، والمزيد، للتأكد من
                            نسخ أكثر دقة وارتباطًا.
                        </div>
                    </div>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <CustomVocabularyTab />
                        </div>
                    </div>
                </div>
                <div className="accordion-item border-0">
                    <div className='accordion-item-content collapsed' data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" type="button">
                        <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                            <DashboardIcone /> عمليات دمج سهلة عبر واجهات برمجة التطبيقات ومجموعات تطوير البرامج
                        </h3>
                        <div className="aug-empowering-clinicians__accordion-box__text">
                            دمج التعرف على الكلام الطبي بسلاسة في تطبيقاتك السريرية الحالية
                            وسير العمل.
                            تتيح واجهات برمجة التطبيقات ومجموعات تطوير البرامج القوية لدينا للمطورين إضافة إمكانيات الذكاء الاصطناعي الصوتي
                            بسهولة إلى أي منصة أو برنامج، مما يعزز تجربة المستخدم والوظائف.
                        </div>
                    </div>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionClinicianBuilt">
                        <div className="mt-1">
                            <EasyIntegrationsTab />
                        </div>
                    </div>
                </div>
            </div>

        );
    };
};