import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import CareerBannerAr from './CareerBanner/CareerBannerAr';
import CareersMetaAr from './Meta/CareersMetaAr';
// import SkillsGoalsAr from './SkillsGoals/SkillsGoalsAr';
import KekaCareerAr from './KekaCareer/KekaCareerAr';
import CollageGalleryAr from './CollageGallery/CollageGalleryAr';
import AugnitoDifferenceAr from './AugnitoDifference/AugnitoDifferenceAr';
import WeBelieveAr from './WeBelieve/WeBelieveAr';
import OurTeamAr from './OurTeam/OurTeamAr';

export default class CareersAr extends BaseComponent {
    render() { 
           
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <CareersMetaAr/>
                <CareerBannerAr/>
                <CollageGalleryAr/>
                <WeBelieveAr/>
                <OurTeamAr/>
                <AugnitoDifferenceAr/>
                <KekaCareerAr/>
                {/* <SkillsGoalsAr/> */}
            </main>
        );
    }
}
