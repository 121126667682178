import React from 'react';
import { ReactComponent as LanguageIcone } from '../../../Assets/images/icons/check-ring.svg';

const ClinicalDocumentationContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <LanguageIcone /> تكامل الوثائق السريرية (CDI)
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            يقدم Omni مراجعة منهجية وتحسينًا للوثائق السريرية للمساعدة في تحسين
 جودة ودقة واكتمال السجلات الخاصة بالمريض من المصدر.
            </div>
        </div>
    );
};

export default ClinicalDocumentationContentAr;