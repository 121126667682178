import React from 'react';
import { ReactComponent as MacrosIconeIcone } from '../../../Assets/images/icons/book-line.svg';

const CustomVocabularyContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <MacrosIconeIcone /> التخصيص باستخدام مفردات مخصصة
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            تحسين النظام وفقًا لاحتياجاتك المحددة عن طريق ملء التفاصيل مثل التهجئات المفضلة
ونطق المصطلحات الخاصة بالتخصص، والاختصارات، وأسماء الأدوية، والمزيد، للتأكد من
نسخ أكثر دقة وارتباطًا. 
            </div>
        </div>
    );
};

export default CustomVocabularyContentAr;