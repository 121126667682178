import React from 'react';
import Banner from './Banner/Banner';
import OurPartner from './OurPartner/OurPartner';
import CustomerSay from './CustomerSay/CustomerSay';
import HomeMeta from './Meta/HomeMeta';
import BaseComponent  from '../../Common/BaseComponent';
import CustomerSayME from './CustomerSay/CustomerSayME';
import CustomerSayIN from './CustomerSay/CustomerSayIN';
import PerfectSolution from './PerfectSolution/PerfectSolution';
import SecureDesign from './SecureDesign/SecureDesign';
import LeadingPlatforms from './LeadingPlatforms/LeadingPlatforms';
import UniversalAccessibility from './UniversalAccessibility/UniversalAccessibility';
import BannerVideo from './BannerVideo/BannerVideo';
import EmpoweringClinicians from './EmpoweringClinicians/EmpoweringClinicians';
import ConversationsClinical from './ConversationsClinical/ConversationsClinical';
import OurSolution from './OurSolution/OurSolution';

export default class Home extends BaseComponent {
    
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <HomeMeta />
                <Banner/>
                <BannerVideo/>
                <OurPartner userRegionData={regionData}/>
                <ConversationsClinical/>
                {
                    regionData === "IN" ? <CustomerSayIN/> :
                    regionData === "ME" ? <CustomerSayME/> :
                    <CustomerSay />
                }
                <EmpoweringClinicians/>
                <OurSolution/>
                <UniversalAccessibility/>
                <LeadingPlatforms userRegionData={regionData}/>
                <SecureDesign/>
                <PerfectSolution/>
            </main>
        );
    }
}
