import React from 'react';
import {ReactComponent as HealthCoverageIcone} from '../../../Assets/images/icons/health-coverage.svg';

const HealthCoverage = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <HealthCoverageIcone/>
            </div>
            <h3 className='mb-2'>Comprehensive Health Coverage</h3>
            <p className='mb-0'>Experience peace of mind with Augnito’s robust medical insurance for you and your family.</p>
        </div>
    );
};

export default HealthCoverage;