import React from 'react';
import { ReactComponent as IntegrationIcone } from '../../../Assets/images/icons/dashboard-setting.svg';

const EasyIntegrationsContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title border-bottom-0">
                <IntegrationIcone /> عمليات دمج سهلة عبر واجهات برمجة التطبيقات ومجموعات تطوير البرامج
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            يتيح Omni للمطورين تحسين تطبيقات البرامج السريرية الحالية لديهم من خلال
 خيارات النشر باستخدام أكواد قليلة ودون أكواد.
            </div>
        </div>
    );
};

export default EasyIntegrationsContentAr;