import React from 'react';
import { ReactComponent as BurnoutIcone } from '../../../Assets/images/icons/physician-burnout.svg';

const PhysicianBurnoutContent = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <BurnoutIcone /> تقليل إرهاق الأطباء
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
            تبسيط سير العمل السريري وإنشاء وثائق منظمة بسلاسة من خلال واجهة صوتية سهلة الاستخدام.

. باستخدام الذكاء الاصطناعي الصوتي الطبي، يصبح المستخدمون أكثر إنتاجية، ويشعرون بإرهاق أقل، ويقضون وقتًا أطول مع المرضى.
            </div>
        </div>
    );
};

export default PhysicianBurnoutContent;