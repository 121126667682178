import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const ThankYouMetaAr = () => {

    return (
        <Helmet>
            <title>شكرًا لك | أوجنيتو: شريك الخليج في تقنية الذكاء الاصطناعي الصوتي الطبي</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="نقدر اهتمامك بنظام التعرف على الصوت المتقدم من Augnito للممارسات الطبية في الخليج. سيتواصل فريقنا معك قريبًا. في حالة الطوارئ، أرسل بريدًا إلكترونيًا إلى support@augnito.ai." />
            <meta charset="UTF-8" />
            <meta name="language" content="ar" />
            <link rel="canonical" href={Constants.SITE_URL + "/ar/thank-you"} />
            <meta property="og:url" content={Constants.SITE_URL + "/ar/thank-you"} />
            <meta property="og:title" content="شكرًا لك | أوجنيتو: شريك الخليج في تقنية الذكاء الاصطناعي الصوتي الطبي" />
            <meta property="og:description" content="نقدر اهتمامك بنظام التعرف على الصوت المتقدم من Augnito للممارسات الطبية في الخليج. سيتواصل فريقنا معك قريبًا. في حالة الطوارئ، أرسل بريدًا إلكترونيًا إلى support@augnito.ai." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/ar/thank-you"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="شكرًا لك | أوجنيتو: شريك الخليج في تقنية الذكاء الاصطناعي الصوتي الطبي" />
            <meta name="twitter:description" content="نقدر اهتمامك بنظام التعرف على الصوت المتقدم من Augnito للممارسات الطبية في الخليج. سيتواصل فريقنا معك قريبًا. في حالة الطوارئ، أرسل بريدًا إلكترونيًا إلى support@augnito.ai." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <link rel="alternate" hreflang="ar" href={Constants.SITE_URL + "/ar/thank-you"} />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default ThankYouMetaAr;

