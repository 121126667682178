import React from 'react';
import { ReactComponent as DeploymentIcone } from '../../../Assets/images/icons/simplified-deployment.svg';

const SimplifiedDeploymentContentAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-box'>
            <h3 className="aug-empowering-clinicians__accordion-box__title">
                <DeploymentIcone /> نشر مبسط
            </h3>
            <div className="aug-empowering-clinicians__accordion-box__text">
                حلول آمنة وبسيطة وجاهزة للاستخدام مباشرة، وسهلة النشر والإدارة على أي نطاق. عدم وجود تكاليف بنية تحتية أو أجهزة. واجهات برمجة تطبيقات ومجموعات تطوير برامج جاهزة للتشغيل الفوري لتسهيل عمليات التكامل.           
            </div>
        </div>
    );
};

export default SimplifiedDeploymentContentAr;