import React from 'react';

const ImplementationCost = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="ImplementationCost">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseImplementationCost" aria-expanded="false" aria-controls="collapseImplementationCost">
                How does Augnito fit into my existing information systems/architecture?
                </button>
            </h3>
            <div id="collapseImplementationCost" className="accordion-collapse collapse" aria-labelledby="ImplementationCost" data-bs-parent="#faq">
                <div className="accordion-body">
                Augnito is designed to be compatible with most global RIS/PACS/LIS/HIS/EMR systems. To confirm compatibility with your current system, please contact us. In the unlikely event that integration is not already established, our professional services team will provide the necessary support to ensure seamless integration into your existing infrastructure through a robust interoperability layer that includes several interface capabilities including APIs, SDKs, FHIR and HL7. We also provide a zero-code integration option for compatible systems and a robust developer portal that includes step-by-step guides, Sandbox environment, SDKs, documentation, and other development resources. 
                </div>
            </div>
        </div>
    );
};

export default ImplementationCost;