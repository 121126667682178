import React from 'react';
import {ReactComponent as SeamlessInteroperabilityIcone} from '../../../Assets/images/icons/dashboard-setting.svg';

const SeamlessInteroperabilityAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <SeamlessInteroperabilityIcone/>
            </div>
            <h3 className='mb-2'>التوافق السلس</h3>
            <p className='mb-0'>يندمج Omni بسهولة مع جميع السجلات الطبية الإلكترونية الرئيسية، مما يضمن تدفقات البيانات بسلاسة وتوافق. تتيح عمليات الدمج العميقة، بالإضافة إلى خيارات الأكواد القليلة ودون أكواد، للأطباء تسجيل ونقل البيانات المباشرة بسلاسة.</p>
        </div>
    );
};

export default SeamlessInteroperabilityAr;