import React from 'react';
import queriesImg from '../../../Assets/images/ContactUs/HelpYou/genral-queries.webp';

const ContactSalesAr = () => {

    return (
        <div dir='rtl' className='aug-card'>
            <img src={queriesImg} alt="Hands typing on laptop with futuristic holographic interface displaying 'Contact us' and chatbot assistance options" className="w-100 img-fluid"/>
            <div className='aug-card-body'>
                <h3 className='mb-0 aug-card-header'>استفسارات عامة</h3>
                <p className='mb-0'>للاستفسار عن أي شيء آخر، اتصل بنا على</p>
                <a href="mailto:sales@augnito.ai">sales@augnito.ai</a>
            </div>
        </div>
    );
};

export default ContactSalesAr;