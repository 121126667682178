import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const RefundPolicyMeta = () => {

    return (
        <Helmet>
            <title> Refund and Cancellation Policy | Augnito Medical Voice AI </title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Learn about Augnito's refund and cancellation policy for our medical speech recognition services. Understand your rights and the process for cancellations."/>
            <link rel="canonical" href={Constants.SITE_URL + "/legal/refund-cancellation-policy"} />
            <meta property="og:url" content={Constants.SITE_URL + "/legal/refund-cancellation-policy"} />
            <meta property="og:title" content="Refund and Cancellation Policy | Augnito Medical Voice AI" />
            <meta property="og:description" content="Learn about Augnito's refund and cancellation policy for our medical speech recognition services. Understand your rights and the process for cancellations." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/legal/refund-cancellation-policy"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Refund and Cancellation Policy | Augnito Medical Voice AI" />
            <meta name="twitter:description" content="Learn about Augnito's refund and cancellation policy for our medical speech recognition services. Understand your rights and the process for cancellations." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default RefundPolicyMeta;