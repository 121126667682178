import React from 'react';
import {ReactComponent as OwnershipSuccessIcone} from '../../../Assets/images/icons/ownership-success.svg';

const OwnershipSuccess = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <OwnershipSuccessIcone/>
            </div>
            <h3 className='mb-2'>Ownership in Success</h3>
            <p className='mb-0'>Align your personal growth aspirations with those of Augnito via our proprietary Employee Stock Ownership Plan (ESOP) scheme.</p>
        </div>
    );
};

export default OwnershipSuccess;