import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import ContactBanner from './ContactBanner/ContactBanner';
import HelpYou from './HelpYou/HelpYou';
import ContactMeta from './Meta/ContactMeta';
import OurOffices from './OurOffices/OurOffices';
import PerfectSolution from '../Home/PerfectSolution/PerfectSolution';

export default class Contact extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ContactMeta />
                <ContactBanner/>
                <HelpYou />
                <OurOffices/>
                <PerfectSolution/>
            </main>
        );
    }
}