import React from 'react';
import SpectraVideo from '../../../Assets/images/Spectra/Banner/spectra-banner.webm';
import SpectraVideoMp from '../../../Assets/images/Spectra/Banner/spectra-banner.mp4';
import {ReactComponent as PattrenIcone} from '../../../Assets/images/icons/pattren-icone.svg';
import './SpectraBanner.scss';
import { RedirectToSignUp } from '../../../Common/Utils';
import { NavLink } from 'react-router-dom';

const SpectraBannerAr = () => {
    return (
        <section dir='rtl' className='aug-spectra-banner'>
            <div className='container'>
                <div className="row">
                    <div className='offset-lg-1 col-md-12 col-lg-5'>
                        <div className='aug-spectra-banner-content'>
                            <h6 className='mb-2'><PattrenIcone className='ms-2'/> Spectra</h6>
                            <h1 className='mb-3'>أفضل الوثائق السريرية المعتمدة على الصوت في فئتها</h1>
                            <p>التعرف على الكلام بسهولة والبرمجة اللغوية العصبية مع إمكانيات القيادة والتحكم.التقاط الملاحظاتالطبية وزيادة الإنتاجية وتحسين رعاية المرضى في ثوانٍ.</p>
                            <button type='button' className='btn btn-primary' onClick={RedirectToSignUp}>
                            جرّب Spectra
                            </button>
                            <NavLink to="/ar/contact-sales" className='btn btn-outline-white me-1 me-sm-3'>طلب عرض توضيحي</NavLink>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-video'>
                            <div className="">
                                <video width="100%" id='videoSpectra' autoPlay="autoPlay" preload="metadata" loading="lazy" playsInline muted loop="loop">
                                    <source src={SpectraVideoMp} type="video/mp4" />
                                    <source src={SpectraVideo} type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SpectraBannerAr;