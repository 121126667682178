import React from 'react';
import {ReactComponent as ClaimDenialsIcone} from '../../../Assets/images/icons/claim-denials.svg';

const ClaimDenialsAr = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <ClaimDenialsIcone/>
            </div>
            <h3 className='mb-2'>انخفاض في رفض المطالبات</h3>
            <p className='mb-0'>يضمن Omni توثيقًا دقيقًا وكاملًا، ويلتقط جميع المعلومات السريرية ذات الصلة ويعين الرموز السريرية الدقيقة. تؤدي هذه الدقة إلى تحسين معدلات السداد وتبسيط دورات الإيرادات.</p>
        </div>
    );
};

export default ClaimDenialsAr;