import React from 'react';
import SpectraMeta from './Meta/SpectraMeta';
import BaseComponent from '../../Common/BaseComponent';
import SpectraBanner from './SpectraBanner/SpectraBanner';
import EfficientDocumentation from './EfficientDocumentation/EfficientDocumentation';
import WhySpectra from './WhySpectra/WhySpectra';
import TrySpectra from './TrySpectra/TrySpectra';
// import CustomerSay from '../Home/CustomerSay/CustomerSay';
// import CustomerSayME from '../Home/CustomerSay/CustomerSayME';
// import CustomerSayIN from '../Home/CustomerSay/CustomerSayIN';
// import TrustedBy from './TrustedBy/TrustedBy';
// import ClinicalPlatforms from './ClinicalPlatforms/ClinicalPlatforms';
import ClinicianBuilt from './ClinicianBuilt/ClinicianBuilt';

export default class Spectra extends BaseComponent {
    
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main className='aug-spectra'>
               <SpectraMeta/> 
               <SpectraBanner/>
               {/* <TrustedBy userRegionData={regionData}/> */}
               <EfficientDocumentation/>
               {/* <ClinicalPlatforms userRegionData={regionData}/> */}
               <ClinicianBuilt/>
               <WhySpectra/>
               {/* {
                    regionData === "IN" ? <CustomerSayIN/> :
                    regionData === "ME" ? <CustomerSayME/> :
                    <CustomerSay/>
                } */}
               <TrySpectra/>
            </main>
        );
    }
}