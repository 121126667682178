import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import TermsOfUseMeta from './TermsOfUseMeta';
import TermsOfUseIN from './TermsOfUseIN';
import TermsOfUseME from './TermsOfUseME';
import TermsOfUseUS from './TermsOfUseUS';

export default class TermsOfUse extends BaseComponent {

    render() {
        if (this.props.isGeoLoading) {
            return;
        }

        if (!this.state.selectedRegion) {
            this.setState({ selectedRegion: this.props.userRegionData })
        }

        const handleRegionChange = (event) => {
            this.setState({ selectedRegion: event.target.value })
        }

        return (
            <>
                <TermsOfUseMeta />
                <section className='aug-legal-page'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 offset-lg-1 col-lg-10'>
                                <div className='aug-legal-page-header'>
                                    <div className='row'>
                                        <div className='col-lg-9'>
                                            <h1 className='aug-legal-page-title mb-3'>Terms of Use (Retail & Authorized Users)</h1>
                                        </div>
                                        <div className='col-lg-3 d-none d-lg-block'>
                                            <select class="form-select">
                                                <option defaultValue>Version 1.0 (current)</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        this.state.selectedRegion === "IN" ?
                                            <>
                                                <p className='mb-3'>By accessing or using the Augnito’s Services, you agree to comply with the following Terms of Use (“Terms”). Please note that the following Terms are only applicable to the Retail Users and the Users engaging through an authorized reseller or distributor (“Distributor/Reseller”). You are requested to read these Terms carefully before using the Services.</p>
                                                <p>By accessing or using the Service, you agree to be bound by these Terms, our Privacy Policy, and any other applicable policies referenced herein. If you do not agree to these Terms, you must not use the Services. These Terms form an electronic record under the provisions of the Information Technology Act, 2000 and rules framed there under, including the Information Technology (Intermediaries Guidelines) Rules, 2011 (as applicable and as amended from time to time). The use of the Services is available only to persons competent to contract under the applicable laws.</p>
                                            </> :
                                            this.state.selectedRegion === "ME" ?
                                            <>
                                                <p className='mb-3'>By accessing or using the Augnito’s Services, you agree to comply with the following Terms of Use (“Terms”). Please note that the following Terms are only applicable to the Retail Users and the Users engaging through an authorized reseller or distributor (“Distributor/Reseller”). You are requested to read these Terms carefully before using the Services. </p>
                                                <p>
                                                    By accessing or using the Service, you agree to be bound by these Terms, our Privacy Policy, and any other applicable policies referenced herein. If you do not agree to these Terms, you must not use the Services. These Terms form an electronic record, and the use of the Services is available only to persons competent to contract under the applicable laws.
                                                </p>
                                            </> :
                                            <>
                                                <p className='mb-3'>By accessing or using the Augnito’s Services, you agree to comply with the following Terms of Use (“Terms”). Please note that the following Terms are only applicable to the Retail Users and the Users engaging through an authorized reseller or distributor (“Distributor/Reseller”). You are requested to read these Terms carefully before using the Services.</p>
                                                <p>By accessing or using the Service, you agree to be bound by these Terms, our Privacy Policy, and any other applicable policies referenced herein. If you do not agree to these Terms, you must not use the Services. These Terms form an electronic record, and the use of the Services is available only to persons competent to contract under the applicable laws.</p>
                                            </>
                                    }

                                    <div className='row'>
                                        <div className='col-lg-3 col-md-4 col-12'>
                                            <select class="form-select" value={this.state.selectedRegion} onChange={handleRegionChange}>
                                                <option value="IN">INDIA</option>
                                                <option value="US">USA</option>
                                                <option value="ME">UAE</option>
                                            </select>
                                        </div>
                                        <div className='col-md-3 col-12 d-block d-lg-none mt-3'>
                                            <select class="form-select">
                                                <option defaultValue>Version 1.0 (current)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.selectedRegion === "IN" ? <TermsOfUseIN /> :
                                    this.state.selectedRegion === "ME" ? <TermsOfUseME /> :
                                    <TermsOfUseUS />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}