import React from 'react';
import productivityVideoAr from '../../../Assets/images/Spectra/ClinicianBuilt/increased-productivity.webm';
import productivityVideoArMp from '../../../Assets/images/Spectra/ClinicianBuilt/increased-productivity.mp4';

const ProductivityMacrosTabAr = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <div className="">
                <video width="100%" id='videoProductivity' playsInline muted autoPlay="autoPlay" loading="lazy" loop="loop">
                    <source src={productivityVideoAr} type="video/webm" />
                    <source src={productivityVideoArMp} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default ProductivityMacrosTabAr;