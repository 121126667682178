import React from 'react';
import ReviewEditVideoAr from '../../../Assets/images/Omni/ClinicalNote/review-edit.webm';
import ReviewEditVideoArMp from '../../../Assets/images/Omni/ClinicalNote/review-edit.mp4';

const ReviewEditAr = () => {
    return (
        <div dir='rtl' className='aug-efficient-documentation__item'>
            <div className="row align-items-center">
                <div className='offset-md-1 col-md-12 col-lg-6'>
                    <div className='aug-efficient-documentation__content'>
                        <h6 className='mb-2'>الخطوة 3</h6>
                        <h3 className='mb-3'>المراجعة والتحرير</h3>
                        <p className='mb-0'>يتيح لك نظام التنقل دون استخدام اليدين إجراء التصحيحات والتحسينات في أي خانة، بسلاسة.تكامل الوثائق السريرية المضمنة (CDI) عبر وكيل CDI وأتمتة ترميز ICD-10 أو SNOMED أو SBS عبر أداة ترميز بالذكاء الاصطناعي يدفعك إلى تحسين الجودة والدقة،واكتمال مذكرتك الطبية.</p>
                    </div>
                </div>
                <div className='col-12 col-md-10 col-lg-5 col-xl-4 mx-auto mx-lg-0'>
                    <div className='aug-efficient-documentation__video'>
                        <div className="mx-xl-4">
                            <video width="100%" id='videoActivate' playsInline muted autoPlay="autoPlay" preload="metadata" loading="lazy" loop="loop">
                                <source src={ReviewEditVideoAr} type="video/webm" />
                                <source src={ReviewEditVideoArMp} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewEditAr;