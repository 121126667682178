import React from 'react';
import {ReactComponent as PrecisionIcone} from '../../../Assets/images/icons/precision.svg';

const PrecisionAr = () => {
    return (
        <div dir='rtl' className='aug-we-believe__item'>
            <div className='aug-we-believe__icone'>
                <PrecisionIcone/>
            </div>
            <h3 className='mb-0'>الدقة</h3>
            <p className='mb-0'>نتولى أي مهمة بكل شمولية ودقة وإتقان، نابعين من الخبرة والقدرة.</p>
        </div>
    );
};

export default PrecisionAr;