import React, {useState} from 'react';

const CustomerSayContent = ({content, initWord}) => {

    let wordArray = content.split(" ");
    let readMoreContent = "";

    for(let i = 0 ; i < wordArray.length; i++) {
        if(i < initWord) {
          readMoreContent += wordArray[i] + " ";
          
        }
    }
    
    const [readMore,  setReadmore] = useState(wordArray.length < initWord ? false : true);

    const onReadMore = () => {
      setReadmore(false);
    }

    // const onReadLess = () => {
    //   setReadmore(true);
    // }

    var pathname = window.location.pathname;
        var isArabicURL = false;
        if (pathname.toLowerCase().includes("/ar/")) {
            isArabicURL = true;
        }
    
    return (
       <>
        { readMore &&  <div className='aug-customer-items__text'>
          {readMoreContent} 
          { isArabicURL ? <span className='aug-customer-items-read' onClick={onReadMore}>اقرأ المزيد..</span> 
          :
          <span className='aug-customer-items-read' onClick={onReadMore}>Read more..</span>
          }
          </div>
        }
        { !readMore &&  
          <div className='aug-customer-items__text'>
          {content} 
          {/* <span className='aug-customer-items-read' onClick={onReadLess}>Read Less..</span> */}
          </div>
        }
       </>
    );
};


export default CustomerSayContent;