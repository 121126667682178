import React from 'react';
import SpectraVideo from '../../../Assets/images/Spectra/Banner/spectra-banner.webm';
import SpectraVideoMp from '../../../Assets/images/Spectra/Banner/spectra-banner.mp4';
import {ReactComponent as PattrenIcone} from '../../../Assets/images/icons/pattren-icone.svg';
import './SpectraBanner.scss';
import { RedirectToSignUp } from '../../../Common/Utils';
import { NavLink } from 'react-router-dom';

const SpectraBanner = () => {
    return (
        <section className='aug-spectra-banner'>
            <div className='container'>
                <div className="row gx-40">
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-banner-content'>
                            <h6 className='mb-2'><PattrenIcone className='me-2'/> Spectra</h6>
                            <h1 className='mb-3'>Best In Class Voice-Driven Clinical Documentation </h1>
                            <p>Intuitive Speech Recognition & NLP with command & control capabilities. Capture medical notes, increase productivity, and improve patient care in seconds.</p>
                            <button type='button' className='btn btn-primary' onClick={RedirectToSignUp}>
                                Try Spectra
                            </button>
                            <NavLink to="/contact-sales" className='btn btn-outline-white ms-1 ms-sm-3'>Request a Demo</NavLink>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-6'>
                        <div className='aug-spectra-video'>
                            <div className="">
                                <video width="100%" id='videoSpectra' autoPlay="autoPlay" loading="lazy" preload="metadata" playsInline muted loop="loop">
                                    <source src={SpectraVideoMp} type="video/mp4" />
                                    <source src={SpectraVideo} type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SpectraBanner;