import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../Assets/images/icons/arrow-left.svg';
import {ReactComponent as Quote} from '../../../Assets/images/icons/quote.svg';
import './CustomerSay.scss';
import CustomerSayContent from './CustomerSayContent';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomerSayAr = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 2,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        rtl:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section dir='rtl' className='aug-customer-say'>
          <div className='container'>
            <h2 className='aug-customer-say-title'>استمع إلى آراء عملائنا </h2>
            <Slider {...settings}>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>د. جميل أحمد </h3>
                      <h4 className='aug-customer-items__designation'> مؤسس ومدير عام <br/> مجموعة برايم للرعاية الصحية، الإمارات العربية المتحدة </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/jamil-ahmed.webp')} alt="Dr Jamil Ahmed" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content=" إنه لشرف عظيم أن يكون Augnito شريكًا لنا في Voice AI بينما نواصل العمل من أجل تقديم خدمات عالمية المستوى تساهم بشكل إيجابي في رؤية دولة الإمارات العربية المتحدة لتصبح دولة رائدة في ممارسات الرعاية الصحية. بعد مراجعة العديد من المنتجات، وجدنا أن جودة Augnito استثنائية، بالإضافة إلى أن تجربة المشاركة كانت سلسة بالنسبة لنا خلال مرحلة إثبات المفهوم مما عزز إيماننا بالمنتج وقدرته على تقديم جميع النواحي بعد الإعداد ." initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>سريراج ك ر </h3>
                      <h4 className='aug-customer-items__designation'> مدير تكنولوجيا المعلومات <br/> مركز ضياء الطبي، الإمارات العربية المتحدة</h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/sreeraj-KR.webp')} alt="Sreeraj KR" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                منذ تطبيق Augnito، أثر بشكل إيجابي على عملياتنا. يستطيع الأطباء الآن إملاء تقاريرهم مباشرة على النظام، مع دقة Augnito العالية في التقاط كل التفاصيل، بغض النظر عن اللهجة. وقد أدى هذا إلى تقليل وقت إعداد التقارير بشكل كبير، مما يسمح لنا بتقديم النتائج للأطباء والمرضى بشكل أسرع بكثير. لم يعزز Augnito كفاءتنا فحسب، بل أتاح أيضًا وقتًا ثمينًا للأطباء للتركيز على الأمور الأكثر أهمية - توفير رعاية استثنائية للمرضى. أوصي بـ Augnito بشدة!" initWord={58}/> 
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'> د. جيني كينكاري، طبيبة </h3>
                      <h4 className='aug-customer-items__designation'> مؤسس مشارك ورئيس طبي  <br/> طبيب بقسم الرعاية العاجلة، الولايات المتحدة الأمريكية </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/jeannie-kenkare.webp')} alt="Dr. Jeannie Kenkare" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                نحن نفخر بالشراكة مع شركات مبتكرة مثل Augnito التي تركز على توفير وقت مقدم الخدمة وتحسين نتائج المرضى لجميع أصحاب المصلحة لدينا." initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>ديفيد بيريجريم، مساعد طبيب معتمد </h3>
                      <h4 className='aug-customer-items__designation'> مساعد طبيب في قسم طب الطوارئ <br/> مستشفى بريدجبورت بالولايات المتحدة الأمريكية     </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/david-peregrim.webp')} alt="David Peregrim" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                إنه سريع وفعال وشامل وسهل الاستخدام للغاية. لقد استخدمت حلول VR قديمة أخرى، ويحتل Augnito مكانة عالية بينها. لقد سمح لي بالتوثيق بشكل أسرع ومواكبة الإنتاجية العالية للمرضى في العيادة." initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'>د. جيراردو بونيلا، دكتوراه في الطب </h3>
                      <h4 className='aug-customer-items__designation'> رئيس تنفيذي ومؤسس <br/> ChartNote ، الولايات المتحدة الأمريكية    </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/gerardo-bonilla.webp')} alt="Dr. Gerardo Bonilla" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                منذ دمج التعرف على الصوت المدعوم من Augnito في ChartNote، شهد الأطباء منذ ذلك الحين انخفاضًا في كمية الوثائق الطبية. ونتيجة لذلك، أدت قدرة الطبيب على توفير رعاية صحية أفضل إلى تحسين الإضافات الجديدة الناتجة عن شراكتنا لتمكين برامجنا الطبية من توفير الجيل التالي من الذكاء الاصطناعي " initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                    <h3 className='aug-customer-items__name mb-1'> اد. جايمين باتل، بكالوريوس طب وجراحة، الزمالة البريطانية لأخصائيي الأشعة  </h3>
                    <h4 className='aug-customer-items__designation'>  الرئيس التنفيذي للعمليات  <br/>   Hexarad، المملكة المتحدة </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/jaymin-patel.webp')} alt="Dr Jaymin Patel" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                لقد سمح لنا Augnito بالفعل بتحقيق جزء كبير من أهداف أعمالنا. تم تشكيل هيكساراد من قبل أخصائيي الأشعة لأخصائيي الأشعة، مع التركيز على تقديم التميز والكفاءة في التصوير الطبي وموارد الأشعة، والحوكمة السريرية، وخدمة العملاء. يمكّننا Augnito من القيام بذلك." initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'> د. موهان سونيل كومار    </h3>
                      <h4 className='aug-customer-items__designation'>المدير ورئيس الأطباء النفسيين  <br/> كوتش: مركز البداية الجديدة، الهند        </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/mohan-sunil-kumar.webp')} alt="Dr Mohan Sunil Kumar" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                في عالم حيث التوثيق مهم، فإن منصة Augnito القوية والمتعددة الاستخدامات تجعل التوثيق أمرًا سهلاً. يوفر الوقت بنسبة 60%. 
توثيق أفضل، رعاية أفضل. " initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

              <div dir='rtl' className='aug-customer-items'>
                <div className='d-flex'>
                  <div className='aug-customer-items__personal'>
                      <h3 className='aug-customer-items__name mb-1'> د. باريش دوشي  </h3>
                      <h4 className='aug-customer-items__designation'> مدير قسم جراحة الأعصاب <br/> مستشفى جاسلوك ومركز الأبحاث، الهند   </h4>
                  </div>
                  <img src={require('../../../Assets/images/HeadShot/paresh-doshi.webp')} alt="Dr Paresh Doshi" height={130} width={130} className='img-fluid' />
                </div>
                <CustomerSayContent content="
                اسمحوا لي أن أهنئكم وأهنئكم على برنامج الإملاء الطبي الممتاز القائم على الذكاء الاصطناعي. لقد كان يعمل بسلاسة حتى الآن. لقد كنت أستخدمه منذ أكثر من عامين. خدمتكم كانت ممتازة وتجاوزت كل التوقعات." initWord={55}/>
                <div className='aug-customer-items__quote'>
                  <Quote/>
                </div>
              </div>

            </Slider>
          </div>
       </section>
    );
};

export default CustomerSayAr;
