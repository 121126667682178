import React from 'react';
import './AugnitoDifference.scss';
import AcceleratedGrowth from './AcceleratedGrowth';
import CompetitiveCompensation from './CompetitiveCompensation';
import OwnershipSuccess from './OwnershipSuccess';
import HealthCoverage from './HealthCoverage';
import FlexibleTime from './FlexibleTime';
import ConnectCollaborate from './ConnectCollaborate';

const AugnitoDifference = () => {
    return (
        <section className='aug-augnito-difference'>
            <div className='container'>
                <h2 className='aug-career__title'>The Augnito Difference</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <CompetitiveCompensation/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <OwnershipSuccess/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <HealthCoverage/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <FlexibleTime/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <AcceleratedGrowth/>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <ConnectCollaborate/>
                    </div>
                </div>
                
            </div>
        </section>
    );
};

export default AugnitoDifference;
