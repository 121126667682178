import React from 'react';
import Pattren from '../../../Assets/images/Pattrens/footer.webp';
import { NavLink } from 'react-router-dom';

const WorkUsAr = () => {

    return (
       <section dir='rtl' className='aug-perfec-solution'>
            <div className='container'>
                <div className='aug-perfec-solution-wrap'>
                    <div className='row align-items-center'>
                        <div className='col-lg-8 col-md-12 col-12'>
                            <div className='aug-perfec-solution-box'>
                                <h2 className='mb-3'>العمل معنا</h2>
                                <p>
                                كن جزءًا من مهمتنا لتعزيز قدرات الأطباء باستخدام الذكاء الاصطناعي الأمثل
                                </p>
                                <NavLink to="/ar/careers" className="btn btn-primary">استكشاف الوظائف</NavLink>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-12'>
                            <div className='aug-perfec-solution__img'>
                                <img src={Pattren} alt="pattern" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default WorkUsAr;