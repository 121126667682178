import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants';

const SpectraMeta = () => {

    return (
        <Helmet>
            <title>Augnito Spectra | HIPAA Compliant Cloud-Based Medical Dictation Software</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Medical speech to text with best-in-class accuracy. Cloud-based, HIPAA-compliant medical dictation. Integrates with leading EMRs. Use on any device, anywhere." />
            <link rel="canonical" href={Constants.SITE_URL + "/spectra"} />
            <meta property="og:url" content={Constants.SITE_URL + "/spectra"} />
            <meta property="og:title" content="Augnito Spectra | HIPAA Compliant Cloud-Based Medical Dictation Software " />
            <meta property="og:description" content="Medical speech to text with best-in-class accuracy. Cloud-based, HIPAA-compliant medical dictation. Integrates with leading EMRs. Use on any device, anywhere." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" /> 
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "/spectra"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Augnito Spectra | HIPAA Compliant Cloud-Based Medical Dictation Software" />
            <meta name="twitter:description" content="Medical speech to text with best-in-class accuracy. Cloud-based, HIPAA-compliant medical dictation. Integrates with leading EMRs. Use on any device, anywhere." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default SpectraMeta;
