import React from 'react';
import codeRetrieval from '../../../Assets/images/Omni/ClinicianDesigned/code-retrieval.webp';

const CodeRetrievalTab = () => {

    return (
        <div className='aug-empowering-clinicians__accordion-img'>
            <img src={codeRetrieval} loading="lazy" alt="Laptops displaying colorful spreadsheets with medical data, hands typing, emphasizing efficient healthcare information management" className="img-fluid" />
        </div>
    );
};

export default CodeRetrievalTab;