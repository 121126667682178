import React from 'react';
import ActivateOmniAr from './ActivateOmniAr';
import GenerateOutputAr from './GenerateOutputAr';
import ReviewEditAr from './ReviewEditAr';
import IntelligentInteroperabilityAr from './IntelligentInteroperabilityAr';


const ClinicalNoteAr = () => {
    return (
        <section dir='rtl' className='aug-clinical-note aug-efficient-documentation'>
            <div className='container'>
                <h2 className='aug-efficient-documentation__title'>من المحادثة إلى الملاحظة السريرية، دون أي جهد</h2>
                <ActivateOmniAr/>
                <GenerateOutputAr/>
                <ReviewEditAr/>
                <IntelligentInteroperabilityAr/>
            </div>
        </section>
    );
};

export default ClinicalNoteAr;