import React from 'react';
import {ReactComponent as HealthCoverageIcone} from '../../../Assets/images/icons/health-coverage.svg';

const HealthCoverageAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <HealthCoverageIcone/>
            </div>
            <h3 className='mb-2'>التغطية الصحية الشاملة</h3>
            <p className='mb-0'>استمتع براحة البال مع التأمين الطبي القوي الذي تقدمه Augnito لك ولعائلتك.</p>
        </div>
    );
};

export default HealthCoverageAr;