import React from 'react';
import {ReactComponent as DocumentationIcone} from '../../../Assets/images/icons/voice-check.svg';


const EMRDocumentation = () => {
    return (
        <div className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <DocumentationIcone/>
            </div>
            <h3 className='mb-2'>Effortless EMR Documentation</h3>
            <p className='mb-0'>Omni’s ambient AI for healthcare seamlessly captures and transcribes patient-clinician conversations in real-time, while also enabling the automatic generation of comprehensive clinical notes directly within the EMR interface.</p>
        </div>
    );
};

export default EMRDocumentation;