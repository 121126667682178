import React from 'react';
import {ReactComponent as OfficeUSA} from '../../../Assets/images/ContactUs/our-offices/usa.svg';

const USA = () => {
    return (
        <div className='aug-our-offices__place'>
            <div className='aug-our-offices__icone'>
                <OfficeUSA/>
            </div>
            <h3 className='aug-our-offices__name'>USA</h3>
            <p className='mb-3'>PO Box 332 Sioux Falls, Minnehaha South Dakota 57101
            </p>
            <a href="tel:+18003907163">+ 1-800-390-7163</a>
        </div>
    );
};

export default USA;