import React from 'react';
import {ReactComponent as CompetitiveCompensationIcone} from '../../../Assets/images/icons/competitive-compensation.svg';

const CompetitiveCompensationAr = () => {
    return (
        <div dir='rtl' className='aug-augnito-difference__item'>
            <div className='aug-augnito-difference__icone'>
                <CompetitiveCompensationIcone/>
            </div>
            <h3 className='mb-2'>عائد تنافسي</h3>
            <p className='mb-0'>تقدم شركة Augnito مجموعات رواتب جذابة تتناسب مع مهاراتك ومساهماتك.</p>
        </div>
    );
};

export default CompetitiveCompensationAr;