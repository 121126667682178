import React from 'react';
import './LegalBanner.scss';

const LegalBanner = () => {
    return (
       <section className='aug-legal-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h6 className='mb-2'>Legal</h6>
                        <h1 className='mb-3'>The Fine Print</h1>
                        <p className='mb-0'>Policies and principles that ensure we operate with integrity and transparency</p>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default LegalBanner;