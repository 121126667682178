import React from 'react';
import './ClinicalNote.scss';
import ActivateOmni from './ActivateOmni';
import GenerateOutput from './GenerateOutput';
import ReviewEdit from './ReviewEdit';
import IntelligentInteroperability from './IntelligentInteroperability';


const ClinicalNote = () => {
    return (
        <section className='aug-clinical-note aug-efficient-documentation'>
            <div className='container'>
                <h2 className='aug-efficient-documentation__title'>From Conversation to Clinical Note, Effortlessly</h2>
                <ActivateOmni/>
                <GenerateOutput/>
                <ReviewEdit/>
                <IntelligentInteroperability/>
            </div>
        </section>
    );
};

export default ClinicalNote;